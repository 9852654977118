import { useCallback } from 'react';

import {
  useClipsIdsSelection,
  useGetClipsList,
} from '../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { Clip } from '../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

export const useGetSelectedClips = () => {
  const getClips = useGetClipsList();
  const selectedClipsIds = useClipsIdsSelection();

  const getMergedClips = useCallback(() => {
    const clips = getClips(selectedClipsIds);

    const clipIdsWithParentClips = clips.reduce<string[]>((acc, clip) => {
      if (clip.parentClipId) {
        acc.push(clip.parentClipId);
      }

      if (!clip.parentClipId) {
        acc.push(clip.id);
      }

      return acc;
    }, []);

    return getClips(Array.from(new Set(clipIdsWithParentClips)));
  }, [getClips, selectedClipsIds]);

  const getUnMergedClips = useCallback(() => {
    return getClips(selectedClipsIds);
  }, [getClips, selectedClipsIds]);

  return useCallback(
    (merge?: boolean): Clip[] => {
      return merge ? getMergedClips() : getUnMergedClips();
    },
    [getMergedClips, getUnMergedClips],
  );
};
