import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Episode } from 'shared/types';

import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { useTacticalAnalysisEpisodes } from '../../../../../hooks/use-tactical-analysis-episodes';
import { useTeamUtils, useTimelineTeamIdFocus } from '../../../../../hooks/use-timeline-team-id-focus';

export const useGeneratePlaylistItemName = (recordingId: string) => {
  const { isHomeTeam } = useTeamUtils(recordingId);
  const { isHomeTeamSelected } = useTimelineTeamIdFocus(recordingId);
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const { t } = useTranslation();

  return useCallback(
    (clip: Clip) => {
      if (clip.type === 'scenario') {
        return `EP.${
          episodes.findIndex((ep: Episode) => clip.startTime >= ep.startTime && clip.startTime <= ep.endTime) + 1
        } ${t(
          `${clip.title}${
            (isHomeTeamSelected && isHomeTeam(clip.teamId ?? '')) ||
            (!isHomeTeamSelected && !isHomeTeam(clip.teamId ?? ''))
              ? ''
              : '-no-possession'
          }`,
        )}`;
      }

      if (clip.type === 'episode') {
        return t(clip.title, { name: clip.name });
      }

      return `EP.${
        episodes.findIndex((ep: Episode) => clip.startTime >= ep.startTime && clip.startTime <= ep.endTime) + 1
      } ${t(clip.titleForPlaylist ?? clip.title)}`;
    },
    [t, episodes, isHomeTeamSelected, isHomeTeam],
  );
};
