import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { PlayingMode } from 'shared/components/video-player/types';

import { timelineMatchAtoms } from '../../atoms';
import { generatePlaylistItemFromEpisodes } from '../../components/tactical-analysis/utils/generate-timeline-playlist';
import { useTacticalAnalysisEpisodes } from '../use-tactical-analysis-episodes';
import { useTacticalAnalysisMatch } from '../use-tactical-analysis-match';
import { useTimelineTactics } from '../use-timeline-tactics';

export const useGenerateTimelinePlaylist = (recordingId: string) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const match = useTacticalAnalysisMatch(recordingId);
  const timelineVideoSource = useRecoilValue(timelineMatchAtoms.matchVideoSource(recordingId));
  const { selectedTactics: fundamentalsSelected } = useTimelineTactics(recordingId);
  const currentPlaylistItem = useCurrentPlaylistItem();

  return useCallback(
    (playingMode: PlayingMode) =>
      generatePlaylistItemFromEpisodes({
        episodes,
        fullVideoSourceDuration: match.defaultVideoSource.duration,
        tacticalCameraVideo: timelineVideoSource,
        playingMode,
        recordingId,
        fundamentalsSelected,
        hasHomographies: currentPlaylistItem.hasHomographies,
      }),
    [timelineVideoSource, currentPlaylistItem.hasHomographies, match, episodes, recordingId, fundamentalsSelected],
  );
};
