import { Box, styled } from '@mui/material';
import { useEffect } from 'react';
import { Switch } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { ProtectedRoute } from 'shared/components/protected-route';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

import { BackofficeProtectedRoute } from './components/backoffice-protected-route';
import { BackofficeSidebarLayout } from './components/backoffice-sidebar-layout';
import { AddUserToEnvironmentContainer } from './pages/add-user-to-environment/AddUserToEnvironmentContainer';
import { Annotation } from './pages/annotation/Annotation';
import { SeasonClients } from './pages/associate-season-with-clients';
import { Coaches } from './pages/coaches';
import { Competitions } from './pages/competitions';
import { CreateEnvironmentPage } from './pages/create-environment/CreateEnvironment.page';
import { CreateEnvironmentBranding } from './pages/create-environment-branding/CreateEnvironmentBranding.page';
import { CreateUserContainer } from './pages/create-user/CreateUserContainer';
import { EditEnvironment } from './pages/edit-environment/EditEnvironment.page';
import { EditEnvironmentBranding } from './pages/edit-environment-branding/EditEnvironmentBranding.page';
import { EnvironmentDetail } from './pages/environment-detail/EnvironmentDetail.page';
import { Environments } from './pages/environments/Environments.page';
import { Games } from './pages/games';
import { EditGame } from './pages/games/edit-game';
import { GameSummary } from './pages/games/game-form/steps/game-summary';
import { NewGame } from './pages/games/new-game';
import { Players } from './pages/players';
import { ProcessGamePage } from './pages/process-game';
import { SeasonCompetitions } from './pages/season-competitions';
import { Stages } from './pages/stages';
import { Teams } from './pages/teams';
import { Venues } from './pages/venues';

const BackofficeContent = styled(Box)(() => ({
  flex: 1,
}));

export const BackofficeContainer = () => {
  const branding = useBranding();

  useEffect(() => {
    document.title = `Backoffice | ${branding.displayName}`;
  }, [branding.displayName]);

  return (
    <SidebarLayout>
      <BackofficeSidebarLayout>
        <BackofficeContent>
          <Switch>
            <ProtectedRoute
              component={CreateUserContainer}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_CREATE_USER}
            />
            <ProtectedRoute
              component={AddUserToEnvironmentContainer}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_ADD_USER_TO_ENVIRONMENT}
            />
            <ProtectedRoute component={Environments} onlyAdminAccess exact path={routes.BACKOFFICE_ENVIRONMENTS} />
            <ProtectedRoute
              component={EnvironmentDetail}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_ENVIRONMENT_DETAIL}
            />
            <ProtectedRoute
              component={CreateEnvironmentPage}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_CREATE_ENVIRONMENT}
            />
            <ProtectedRoute
              component={EditEnvironment}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_EDIT_ENVIRONMENT}
            />
            <ProtectedRoute
              component={CreateEnvironmentBranding}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_CREATE_ENVIRONMENT_BRANDING}
            />
            <ProtectedRoute
              component={EditEnvironmentBranding}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_EDIT_ENVIRONMENT_BRANDING}
            />
            <ProtectedRoute
              component={SeasonClients}
              onlyAdminAccess
              exact
              path={routes.BACKOFFICE_ASSOCIATE_COMPETITION_WITH_CLIENTS}
            />
            <BackofficeProtectedRoute component={Competitions} path={routes.BACKOFFICE_COMPETITIONS} />
            <BackofficeProtectedRoute component={Coaches} path={routes.BACKOFFICE_COACHES} />
            <BackofficeProtectedRoute component={EditGame} path={routes.BACKOFFICE_EDIT_GAME_FORM} />
            <BackofficeProtectedRoute component={NewGame} path={routes.BACKOFFICE_GAME_FORM} />
            <BackofficeProtectedRoute component={GameSummary} path={routes.BACKOFFICE_GAME_SUMMARY} />
            <BackofficeProtectedRoute component={SeasonCompetitions} path={routes.BACKOFFICE_SEASON_COMPETITIONS} />
            <BackofficeProtectedRoute component={Venues} path={routes.BACKOFFICE_VENUES} />
            <BackofficeProtectedRoute component={Stages} path={routes.BACKOFFICE_STAGES} />
            <BackofficeProtectedRoute component={Players} path={routes.BACKOFFICE_PLAYERS} />
            <BackofficeProtectedRoute component={Teams} path={routes.BACKOFFICE_TEAMS} />
            <BackofficeProtectedRoute component={ProcessGamePage} path={routes.PROCESS_GAME} />
            <BackofficeProtectedRoute component={Annotation} path={routes.BACKOFFICE_ANNOTATION} />
            <BackofficeProtectedRoute component={Games} path={[routes.BACKOFFICE_GAMES, routes.BACKOFFICE]} />
          </Switch>
        </BackofficeContent>
      </BackofficeSidebarLayout>
    </SidebarLayout>
  );
};
