import { Stack, styled } from '@mui/material';
import { boxShadows, Colors } from 'kognia-ui';

export const playlistItemHeight = 70;

interface PlaylistItemWrapperProps {
  isCurrent: boolean;
  isDeleting: boolean;
  isEditing: boolean;
  isDisabled: boolean;
}

export const ItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== 'isCurrent' && prop !== 'isEditing' && prop !== 'isDisabled' && prop !== 'isDeleting',
})<PlaylistItemWrapperProps>(({ theme, isCurrent, isDeleting, isEditing, isDisabled }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid transparent`,
  backgroundColor: theme.palette.common.white,
  alignItems: 'center',
  height: playlistItemHeight,
  position: 'relative',
  overflow: 'hidden',
  cursor: 'pointer',
  transition: theme.transitions.easing.easeOut,

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },

  ...(isCurrent && {
    borderColor: 'transparent',
    backgroundColor: Colors.lavender,
    boxShadow: boxShadows[2],

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: theme.palette.common.white,
    },

    '[data-element-name="counter"]': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },

    svg: {
      fill: theme.palette.common.white,
    },
  }),

  ...(isDisabled && {
    backdropFilter: 'grayscale(100%)',
    pointerEvents: 'none',
    borderColor: Colors.storm,
    backgroundColor: Colors.background,
    opacity: 0.5,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: Colors.storm,
    },

    '[data-element-name="counter"]': {
      backgroundColor: Colors.storm,
      color: theme.palette.common.white,
    },

    svg: {
      fill: Colors.storm,
    },
  }),

  ...(isEditing && {
    pointerEvents: 'none',
    borderColor: Colors.orange,
    backgroundColor: Colors.dawn,
    opacity: 0.5,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: Colors.orange,
    },

    '[data-element-name="counter"]': {
      backgroundColor: Colors.orange,
      color: theme.palette.common.white,
    },

    svg: {
      fill: Colors.orange,
    },
  }),

  ...(isDeleting && {
    boxShadows: boxShadows[1],
    borderColor: Colors.red,
    opacity: 0.3,
    backgroundColor: Colors.red,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: theme.palette.common.white,
    },

    '[data-element-name="counter"]': {
      backgroundColor: theme.palette.common.white,
      color: Colors.red,
    },

    svg: {
      fill: Colors.red,
    },
  }),
}));
