import { Box, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useTranslation } from 'react-i18next';

import IconDelete from 'shared/components/icons/icon-delete';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { Playlist } from 'shared/types';

import styles from './CurrentPlaylistMenu.module.scss';
import { PlaylistItemsList } from './styled';

interface Props {
  selectedPlaylist: Playlist;
  onDeletePlaylistItem: (playlistItemId: string) => void;
}

export const CurrentPlaylistMenu = ({ selectedPlaylist, onDeletePlaylistItem }: Props) => {
  const { t } = useTranslation();

  const playlistItems = selectedPlaylist.playlistItems;

  return (
    <Box sx={{ flex: '1 1 50%' }}>
      <Box display='flex' alignItems='center' bgcolor='secondary.main'>
        <Typography
          py={0.5}
          px={1}
          color='common.white'
          fontSize={fontSizes.small}
          fontWeight='fontWeightMedium'
          overflow='hidden'
          whiteSpace='nowrap'
        >
          {`${t('timeline:current-playlist-menu.title')} (${playlistItems.length})`}
        </Typography>
      </Box>
      <PlaylistItemsList>
        {playlistItems.length > 0 ? (
          <>
            {playlistItems.map((item) => (
              <Box display='flex' alignItems='center' key={`current-playlist-${selectedPlaylist.id}-${item.id}`}>
                <Typography fontSize={fontSizes.small} fontWeight='fontWeightRegular'>
                  {item.name}
                </Typography>
                <IconDelete
                  // TODO replace with sx prop after changing SvgIcon component
                  className={styles.deleteIcon}
                  size={IconSizes.small}
                  color={IconColors.storm}
                  onClick={() => onDeletePlaylistItem(item.id)}
                />
              </Box>
            ))}
          </>
        ) : (
          <Typography fontSize={fontSizes.xxSmall} fontWeight='fontWeightMedium'>
            {t('timeline:current-playlist-menu.no-clips')}
          </Typography>
        )}
      </PlaylistItemsList>
    </Box>
  );
};
