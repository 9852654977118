import { Stack, Typography } from '@mui/material';
import { INITIAL_QUALITY, Quality, useOverlayGeneratorById } from 'overlay-generator';
import { ChangeEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCurrentPlaylistItem } from '../../../hooks';

export const OverlaysQualitySelector = () => {
  const { t } = useTranslation();
  const playlistItem = useCurrentPlaylistItem();
  const [overlayGenerator] = useOverlayGeneratorById(playlistItem.recordingId);
  const [quality, setQuality] = useState(INITIAL_QUALITY);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      overlayGenerator.setQuality(event.target.value as Quality);
      setQuality(event.target.value as Quality);
    },
    [overlayGenerator],
  );

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <Typography variant={'caption'}>{t('video-player:overlays-quality.title')}</Typography>
      <select id='quality-select' value={quality} onChange={handleChange}>
        <option value={Quality.LOW}>{t('video-player:overlays-quality.low')}</option>
        <option value={Quality.MEDIUM}>{t('video-player:overlays-quality.medium')}</option>
        <option value={Quality.HIGH}>{t('video-player:overlays-quality.high')}</option>
        <option value={Quality.VERY_HIGH}>{t('video-player:overlays-quality.very-high')}</option>
      </select>
    </Stack>
  );
};
