import React, { forwardRef, PropsWithChildren, ReactNode } from 'react';

import { RowsContentHeader } from './RowsContentContainer.styled';
import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { usePinScenarios } from '../../../../../hooks/use-pin-scenarios';
import { RowGroups } from '../row-groups';

export const RowsContentContainer = forwardRef(
  (
    {
      scenariosRow,
      stickComponents,
      contentRowGroups,
      recordingId,
    }: PropsWithChildren<{
      scenariosRow: React.ReactNode;
      recordingId: string;
      contentRowGroups: RowGroup[];
      stickComponents: ReactNode;
    }>,
    ref: any,
  ) => {
    const pinScenarios = usePinScenarios();

    return (
      <div>
        <RowsContentHeader isShadow={pinScenarios} ref={ref}>
          {stickComponents}
          {pinScenarios ? scenariosRow : null}
        </RowsContentHeader>
        <div>
          {!pinScenarios ? scenariosRow : null}
          <RowGroups rowGroups={contentRowGroups} recordingId={recordingId} />
        </div>
      </div>
    );
  },
);

RowsContentContainer.displayName = 'RowsContentHeader';
