import { styled, Box } from '@mui/material';

import {
  CSS_EFFECTIVE_TIME_SEPARATOR,
  CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME,
} from '../timeline-table/components/timeline-css-variables';

export const ColumnEmptyContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHalfTime',
})<{ isHalfTime: boolean }>(({ isHalfTime }) => ({
  overflow: 'hidden',
  padding: 0,
  height: '100%',
  minWidth: `var(${CSS_EFFECTIVE_TIME_SEPARATOR})`,
  maxWidth: `var(${CSS_EFFECTIVE_TIME_SEPARATOR})`,
  background: 'transparent',

  ...(isHalfTime && {
    minWidth: `var(${CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME})`,
    maxWidth: `var(${CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME})`,
  }),
}));

export const FilterHighlightsWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: theme.spacing(0, 3),
  userSelect: 'none',
}));
