import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bulkMode } from '../state/EditablePlaylist.state';

export const useIsBulkModeActive = (id: string) => {
  return useRecoilValue(bulkMode(id));
};

export const useSetIsBulkModeActive = (id: string) => {
  return useSetRecoilState(bulkMode(id));
};
