import { Episode } from 'shared/types';
import { MatchSegmentTypes } from 'shared/types/segment/types';
import { ZoomLevelsType } from 'shared/types/zoom-range/zoomLevels';

import { adjustTimeSpaceByZoomLevel } from '../../components/tactical-analysis/utils/adjust-time-by-zoom-level';
import { TIMELINE_CONFIG } from '../../components/timeline/config';
import {
  SEPARATOR_DOUBLE_WIDTH,
  SEPARATOR_WIDTH,
} from '../../components/timeline/timeline-table/components/timeline-css-variables';

const findPlayHeadPositionWithEpisodes = (time: number, episodes: Episode[], zoomLevel: ZoomLevelsType) => {
  let episodeLeftTime = 0;

  for (let episodeNumber = 0; episodeNumber < episodes.length; episodeNumber++) {
    const currentEpisode = episodes[episodeNumber];

    if (time >= currentEpisode.startTime && time <= currentEpisode.endTime) {
      episodeLeftTime += adjustTimeSpaceByZoomLevel(time - currentEpisode.startTime, zoomLevel);
      break;
    }

    const nextEpisode = episodes[episodeNumber + 1];

    const isHalfTime =
      nextEpisode &&
      nextEpisode.matchSegment === MatchSegmentTypes.SECOND &&
      currentEpisode.matchSegment === MatchSegmentTypes.FIRST;

    const separatorWidth = isHalfTime ? SEPARATOR_DOUBLE_WIDTH : SEPARATOR_WIDTH;

    episodeLeftTime +=
      adjustTimeSpaceByZoomLevel(currentEpisode.endTime - currentEpisode.startTime, zoomLevel) +
      (nextEpisode ? separatorWidth : 0);
  }

  return episodeLeftTime;
};

export const getPlayHeadPositionByTime = (
  time: number,
  isPositionByEpisode: boolean,
  episodes: Episode[],
  zoomLevel: ZoomLevelsType,
) => {
  if (isPositionByEpisode) {
    return (
      findPlayHeadPositionWithEpisodes(time, episodes, zoomLevel) + TIMELINE_CONFIG.LIMIT_SPACING + SEPARATOR_WIDTH
    );
  }

  return adjustTimeSpaceByZoomLevel(time, zoomLevel) + TIMELINE_CONFIG.LIMIT_SPACING;
};
