import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PlaylistsData } from 'api/playlist/useFetchPlaylists';
import { queryParams, routes } from 'kognia/router/routes';
import Container from 'shared/components/container';
import EmptyList from 'shared/components/empty-page';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { useQueryParams } from 'shared/hooks/use-query-params';

import { CopyPlaylist } from './components/copy-playlist';
import { PlaylistsList } from './components/playlists-list';
import { usePlaylistsWithQueryStringState } from './components/playlists-list/hooks/usePlaylistsWithQueryStringState';
import PlaylistsSkeleton from './components/playlists-skeleton';
import { INITIAL_PLAYLISTS_FILTERS, useSetPlaylistsFilters } from '../../api/playlist/useFetchPlaylists/atoms';

export const PlaylistsContainer = () => {
  const { t } = useTranslation();
  const query = useQueryParams();
  const branding = useBranding();
  const setPlaylistsFilters = useSetPlaylistsFilters(routes.PLAYLISTS);

  const { listen } = useHistory();
  const copyPlaylistQueryParam: string | null = query.get(queryParams.copyPlaylist);

  const { isPageLoading, isPageMissingData, isPageError, isPageReady } = usePageStateMachine<PlaylistsData>(() =>
    usePlaylistsWithQueryStringState(),
  );

  useEffect(() => {
    document.title = t('common:metas.title.playlists', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  useEffect(() => {
    return listen((change) => {
      if (change.search === '') setPlaylistsFilters({ ...INITIAL_PLAYLISTS_FILTERS });
    });
  }, [listen, setPlaylistsFilters]);

  return (
    <SidebarLayout>
      <Container>
        {isPageLoading && <PlaylistsSkeleton />}
        {(isPageMissingData || isPageError) && (
          <EmptyList header={t('playlists:not-found.header')} description={t('playlists:not-found.description')} />
        )}
        {isPageReady && (
          <>
            <PlaylistsList />
            {copyPlaylistQueryParam ? <CopyPlaylist playlistId={copyPlaylistQueryParam} /> : null}
          </>
        )}
      </Container>
    </SidebarLayout>
  );
};
