import { Box, Divider, IconButton } from '@mui/material';
import { ErrorBoundary } from '@sentry/react';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useLogOutAccount } from 'api/user/use-logout-account';
import { routes } from 'kognia/router/routes';
import { getIsFormTag } from 'shared/utils/is-form-tag';

import { ClientDropdown } from './client-dropdown';
import { SidebarLogo } from './sidebar-logo';
import { MenuItem, MenuItemType, SidebarMenu } from './sidebar-menu';
import {
  SidebarHeader,
  SidebarLayoutContent,
  SidebarLayoutSidebar,
  SidebarLayoutWrapper,
  SidebarLogoWrapper,
  UserAvatar,
} from './SideBarLayout.styled';
import { useUser } from '../../contexts/app-state';
import { useSidebar } from '../../hooks/use-sidebar';
import { ErrorContainer } from '../error-boundary/error-container';
import IconBall from '../icons/icon-ball';
import { IconDoubleArrowLeft } from '../icons/icon-double-arrow-left';
import { IconDoubleArrowRight } from '../icons/icon-double-arrow-right';
import { IconHome } from '../icons/icon-home';
import { IconInfo } from '../icons/icon-info';
import { IconKeypadEmpty } from '../icons/icon-keypad-empty';
import IconPlaylist from '../icons/icon-playlist';
import IconTag from '../icons/icon-tag';
import { IconTutorial } from '../icons/icon-tutorial';
import { IconUser } from '../icons/icon-user';
import { IconSizes } from '../icons/svg-icon';
import { KeyboardShortcutsModal } from '../keyboard-shortcuts-modal';

interface Props {
  children: ReactNode;
}

export const SidebarLayoutComponent = ({ children }: Props) => {
  const history = useHistory();
  const [isKeyboardShortcutsModalOpen, setIsKeyboardShortcutsModalOpen] = useState(false);

  const { t } = useTranslation();
  const { isSidebarOpen, toggleSidebar } = useSidebar();
  const { firstName, lastName, avatarUrl } = useUser();
  const { logOutUser } = useLogOutAccount();

  const handleDrawerChange = useCallback(() => toggleSidebar(), [toggleSidebar]);
  const handleKeyboardShortcutsModalOpen = useCallback(() => setIsKeyboardShortcutsModalOpen(true), []);
  const handleKeyboardShortcutsModalClose = useCallback(() => setIsKeyboardShortcutsModalOpen(false), []);
  const toggleKeyboardShortcutsModal = useCallback(() => setIsKeyboardShortcutsModalOpen((prev) => !prev), []);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isFormTag = getIsFormTag((event.target as HTMLElement).tagName);
      if (isFormTag) return;
      if (event.key === '?') {
        toggleKeyboardShortcutsModal();
      }
    },
    [toggleKeyboardShortcutsModal],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const primaryMenuItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('common:navigation.home'),
        link: routes.HOME_PAGE,
        icon: <IconHome size={IconSizes.small} />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.tactical-analysis'),
        link: routes.RECORDING_LIST,
        icon: <IconBall size={IconSizes.small} />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.tagging'),
        link: routes.TAGGING_TOOL,
        icon: <IconTag size={IconSizes.small} />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.playlists'),
        link: routes.PLAYLISTS,
        icon: <IconPlaylist size={IconSizes.small} />,
        type: MenuItemType.LINK,
      },
      {
        label: t('common:navigation.keypads'),
        link: routes.KEYPADS,
        icon: <IconKeypadEmpty size={IconSizes.small} />,
        type: MenuItemType.LINK,
      },
      {
        label: t('Backoffice'),
        isAdminAccess: true,
        icon: <IconInfo size={IconSizes.small} />,
        onClick: () => history.push(routes.BACKOFFICE_GAMES),
        type: MenuItemType.CUSTOM_CLICK,
      },
    ],
    [history, t],
  );

  const footerMenuItems: MenuItem[] = useMemo(
    () => [
      {
        label: t('common:navigation.help'),
        icon: <IconTutorial size={IconSizes.small} />,
        type: MenuItemType.PARENT,
        items: [
          {
            label: t('common:navigation.feedback'),
            link: routes.SUBMIT_FEEDBACK,
            type: MenuItemType.LINK,
            bottomDivider: true,
          },
          {
            label: t('common:navigation.keyboard-shortcuts'),
            type: MenuItemType.CUSTOM_CLICK,
            onClick: handleKeyboardShortcutsModalOpen,
          },
        ],
      },
    ],
    [t, handleKeyboardShortcutsModalOpen],
  );

  const accountMenuItems: MenuItem[] = [
    {
      label: `${firstName} ${lastName}`,
      type: MenuItemType.PARENT,
      icon: (
        <UserAvatar>
          {avatarUrl ? <img src={avatarUrl} alt={`${firstName} ${lastName}`} /> : <IconUser size={IconSizes.small} />}
        </UserAvatar>
      ),
      items: [
        {
          label: t('common:navigation.user-profile'),
          link: routes.ACCOUNT,
          type: MenuItemType.LINK,
        },
        {
          label: t('common:actions.logout'),
          onClick: logOutUser,
          type: MenuItemType.CUSTOM_CLICK,
        },
      ],
    },
  ];

  return (
    <SidebarLayoutWrapper>
      <SidebarLayoutSidebar variant='permanent' open={isSidebarOpen}>
        <Box display='flex' flexDirection='column' justifyContent='space-between' height='100%'>
          <div>
            <SidebarHeader open={isSidebarOpen}>
              <SidebarLogoWrapper spacingBottom={!isSidebarOpen}>
                <SidebarLogo isSmall={!isSidebarOpen} />
              </SidebarLogoWrapper>
              <Box>
                <IconButton onClick={handleDrawerChange}>
                  {isSidebarOpen ? (
                    <IconDoubleArrowLeft size={IconSizes.small} />
                  ) : (
                    <IconDoubleArrowRight size={IconSizes.small} />
                  )}
                </IconButton>
              </Box>
            </SidebarHeader>

            <SidebarMenu menuItems={primaryMenuItems} isSidebarOpen={isSidebarOpen} />
          </div>
          <Box>
            <Box mb={1}>
              <SidebarMenu menuItems={accountMenuItems} isSidebarOpen={isSidebarOpen} />
            </Box>
            <ClientDropdown isSidebarOpen={isSidebarOpen} />
            <Divider sx={{ margin: ({ spacing }) => spacing(2, 0) }} />
            <SidebarMenu menuItems={footerMenuItems} isSidebarOpen={isSidebarOpen} />
          </Box>
        </Box>
      </SidebarLayoutSidebar>
      <SidebarLayoutContent component='main' open={isSidebarOpen}>
        {children}
      </SidebarLayoutContent>
      <KeyboardShortcutsModal isOpen={isKeyboardShortcutsModalOpen} onClose={handleKeyboardShortcutsModalClose} />
    </SidebarLayoutWrapper>
  );
};

const SidebarLayoutWithErrorBoundary = (props: JSX.IntrinsicAttributes & Props) => {
  return (
    <ErrorBoundary fallback={<ErrorContainer />}>
      <SidebarLayoutComponent {...props} />
    </ErrorBoundary>
  );
};

export { SidebarLayoutWithErrorBoundary as SidebarLayout };
