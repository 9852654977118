import React from 'react';

import { DownloadPlaylistItemsModal } from 'domain/playlist/components/download-modal/DownloadPlaylistItemsModal';
import { useCurrentPlaylistItem } from 'shared/components/video-player';

interface Props {
  playlistId: string;
  isOpen: boolean;
  onClose: () => void;
}

export const DownloadCurrentPlaylistItem = ({ playlistId, isOpen, onClose }: Props) => {
  const playlistItem = useCurrentPlaylistItem();

  return isOpen ? (
    <DownloadPlaylistItemsModal
      playlistId={playlistId}
      isPlaylistDownload={false}
      itemsToDownload={[playlistItem.id]}
      onClose={onClose}
    />
  ) : null;
};
