import { Box } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';

import { useTacticalAnalysisAppliedFilters } from '../../../../../hooks/use-tactical-analysis-applied-filters';
import { useTimelineTableData } from '../../../../../hooks/use-timeline-table-data';

export const RowsHeaders = forwardRef(
  (
    {
      children,
      recordingId,
      ...rest
    }: PropsWithChildren<
      React.HTMLAttributes<HTMLDivElement> & {
        recordingId: string;
      }
    >,
    ref: any,
  ) => {
    const timelineTableData = useTimelineTableData(recordingId);
    const { areFiltersApplied } = useTacticalAnalysisAppliedFilters(recordingId);

    const showHeaders =
      timelineTableData.rowGroups.filter((r) => r.totalClips > 0).length > 0 ||
      (areFiltersApplied && timelineTableData?.filtersRow && timelineTableData.filtersRow.clips.length > 0);

    if (!showHeaders) return null;

    return (
      <Box ref={ref} position='relative' zIndex={1} pb={4} bgcolor='common.white' overflow='hidden' {...rest}>
        {children}
      </Box>
    );
  },
);

RowsHeaders.displayName = 'RowsHeaders';
