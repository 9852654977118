import { Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { IconChevronRight } from 'shared/components/icons/icon-chevron-right';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { useRedirectTo } from 'shared/components/sidebar-layout/sidebar-menu/hooks/use-redirect-to';
import Spinner from 'shared/components/spinner';

import { useClients } from '../../../../api/backoffice/client/hooks/use-clients/useClients';
import { useEditClient } from '../../../../api/backoffice/client/hooks/use-edit-client/useEditClient';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';
import { EnvironmentForm } from '../../features/environment-form/EnvironmentForm.feature';
import { EnvironmentFormDefaultValues } from '../../features/environment-form/types/environmentFormDefaultValues';
import { EnvironmentOnSubmit } from '../../features/environment-form/types/environmentOnSubmit';

export const EditEnvironment = () => {
  const { id } = useParams<{ id: string }>();
  const { editClient, isLoading } = useEditClient();
  const redirectTo = useRedirectTo();
  const { items, isLoading: isLoadingClient } = useClients({ initialFilters: { clientIds: [id] } });

  const defaultValues: EnvironmentFormDefaultValues | undefined = items?.[0]
    ? {
        id: items[0].id,
        name: items[0].name,
        city: items[0].city,
        country: items[0].country,
      }
    : undefined;

  const handleCreateClient = useCallback(
    ({ data }: EnvironmentOnSubmit) => {
      editClient({
        data,
        id,
        onSuccess: () => redirectTo(generatePath(routes.BACKOFFICE_ENVIRONMENT_DETAIL, { id })),
      });
    },
    [editClient, id, redirectTo],
  );

  return (
    <BackofficeContentContainer>
      <Container fixed maxWidth='sm'>
        <Stack direction={'column'} spacing={2}>
          <Typography
            variant={'h6'}
            sx={{ fontWeight: fontWeight['400'] }}
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <span>{items?.[0]?.name}</span>
            <IconChevronRight size={IconSizes.small} color={IconColors.storm} />
            <span>Edit environment</span>
          </Typography>
          <Card>
            <CardContent>
              {isLoadingClient ? (
                <Spinner isFullPage />
              ) : (
                <EnvironmentForm isLoading={isLoading} onSubmit={handleCreateClient} defaultValues={defaultValues} />
              )}
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </BackofficeContentContainer>
  );
};
