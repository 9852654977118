import { SeasonClients } from 'shared/types/user/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

import { RecordingCompetitionsApiResponse, SeasonClient, SeasonClientsApiResponse } from './types';
import { InfinityQueryDataResult } from '../../../../api/hooks/useInfinityQuery/types';

export const transformSeasonClients = (seasonClient: SeasonClient): SeasonClients => ({
  id: seasonClient.uuid,
  originalClient: seasonClient.original_client,
  seasonName: seasonClient.season_name,
  clients: seasonClient.clients,
  updatedAt: new Date(seasonClient.updated_at),
});

export const transformSeasonClientsList = (
  response: SeasonClientsApiResponse,
): InfinityQueryDataResult<SeasonClients> => {
  return {
    data: {
      items: response.data.map(transformSeasonClients),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};

export const transformRecordingCompetitions = (
  response: RecordingCompetitionsApiResponse,
): InfinityQueryDataResult<string> => {
  return {
    data: {
      items: response.content.map((recordingCompetition) => recordingCompetition.name),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
