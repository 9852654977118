import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import { EditablePlaylistFeature } from 'features/editable-playlist/EditablePlaylist.feature';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { SwitchEnvironment } from 'shared/components/switch-environment';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { Playlist } from 'shared/types';

import PlaylistDetailSkeleton from './components/playlists-detail-skeleton';
import { usePlaylistPage } from './hooks/use-playlist-page';

type RouteParams = { id: string; playlistId: string };

type PlaylistDetailPageProps = {
  playlistId: string;
};

const PlaylistDetailContainer = ({ playlistId }: PlaylistDetailPageProps) => {
  const branding = useBranding();
  const { t } = useTranslation();
  const { isPageLoading, isPageReady, isInvalidClient, data } = usePageStateMachine<Playlist>(() =>
    usePlaylistPage({ playlistId }),
  );

  useEffect(() => {
    document.title = t('common:metas.title.playlist', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  if (isInvalidClient && data?.clientId) {
    return <SwitchEnvironment resourceClientsIds={[data.clientId]} />;
  }

  return (
    <SidebarLayout>
      <Container fullScreen>
        {isPageLoading && <PlaylistDetailSkeleton />}

        {isPageReady && data && <EditablePlaylistFeature playlist={data} />}
      </Container>
    </SidebarLayout>
  );
};

const PlaylistDetail = (props: RouteComponentProps<RouteParams>) => {
  const {
    match: {
      params: { playlistId },
    },
  } = props;

  return <PlaylistDetailContainer key={playlistId} playlistId={playlistId} />;
};

export default PlaylistDetail;
