import SvgIcon, { SvgIconProps } from '../svg-icon';

const IconChevronUp = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M16.59 15.705L12 11.125l-4.59 4.58L6 14.295l6-6 6 6-1.41 1.41z' />
    </SvgIcon>
  );
};

export default IconChevronUp;
