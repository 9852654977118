import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { clientBrandingUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Client } from 'shared/types';

import { BrandingApiResponse } from '../../types/brandingRequestData';
import { transformClient } from '../../utils/transformClient';
import { invalidateClients } from '../use-clients/useClients';

export const useRemoveBranding = () => {
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<BrandingApiResponse, Client>({
    type: HTTPMethod.DELETE,
    errorMessage: 'Error deleting a branding',
    successMessage: 'Branding deleted successfully',
    transformer: transformClient,
  });

  const remove = ({ onSuccess, clientId }: { onSuccess?: (res: Client) => void; clientId: string }) => {
    mutate(
      { url: clientBrandingUrl(clientId) },
      {
        onSuccess: async (res) => {
          await invalidateClients();
          onSuccess && onSuccess(res);
        },
      },
    );
  };

  return { remove, isLoading, isError, isSuccess };
};
