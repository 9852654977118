import { List } from 'shared/components/list';
import { Client } from 'shared/types/user/types';

import { EnvironmentItem } from '../environment-item/EnvironmentItem';

interface EnvironmentsListProps {
  environments: Client[];
}

export const EnvironmentsList = ({ environments }: EnvironmentsListProps) => {
  const environmentItems = environments.map((environment) => (
    <EnvironmentItem key={environment.id} environment={environment} />
  ));

  return <List>{environmentItems}</List>;
};
