import reduce from 'lodash/reduce';
import queryString from 'query-string';
import { useCallback, useState } from 'react';

import browserHistory from '../../../browserHistory';

export type QueryStringState = { [key in string]: string | string[] | null | undefined };

const getQueryString = () => {
  return reduce(
    queryString.parse(browserHistory.location.search),
    (acc, value, key) => {
      return { ...acc, [key]: value };
    },
    {} as QueryStringState,
  );
};

const filterAllowedFields = (state: QueryStringState, allowedFields: string[]) => {
  return reduce(
    state,
    (acc, value, key) => {
      if (allowedFields.includes(key)) {
        return { ...acc, [key]: value };
      }

      return acc;
    },
    {} as QueryStringState,
  );
};

const updateQueryString = (state: QueryStringState) => {
  const currentQueryString = getQueryString();

  const search = reduce(
    state,
    (acc, value, key) => {
      (!Array.isArray(value) && value) || (Array.isArray(value) && value.length > 0)
        ? (acc[key] = state[key])
        : delete acc[key];

      return acc;
    },
    currentQueryString as QueryStringState,
  );

  browserHistory.replace({
    search: queryString.stringify(search),
  });
};

export const useQueryStringState = (listOfFieldsToUpdate: string[]) => {
  const [value, setValue] = useState<QueryStringState>(() => {
    return filterAllowedFields(getQueryString(), listOfFieldsToUpdate);
  });

  const updateValues = useCallback(
    (state: QueryStringState) => {
      updateQueryString(state);
      setValue(filterAllowedFields(state, listOfFieldsToUpdate));
    },
    [listOfFieldsToUpdate],
  );

  return { value, updateValues };
};
