import { round } from 'lodash';

import { PlayingMode, PlayingModes, VideoSourceWithTimes } from 'shared/components/video-player/types';

import {
  findVideoSourceForClip,
  getClipLength,
  getClipsWithoutDuplicatedTime,
  getFirstClipInRange,
  roundClipTimes,
} from './utils';
import { Clip } from '../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

export interface CreateVideoSourcesFromClips {
  videoSources: VideoSourceWithTimes[];
  duration: number;
  cutClip?: Clip;
}

export const createVideoSourcesFromClips = (
  clips: Clip[],
  videoSources: VideoSourceWithTimes[],
  playingMode: PlayingMode,
): CreateVideoSourcesFromClips => {
  return getClipsWithoutDuplicatedTime(clips)
    .sort((a, b) => (a.startTime >= b.startTime ? 1 : -1))
    .reduce(
      (acc, clipItem, currentIndex, allClips) => {
        const clipLength = getClipLength(clipItem);
        const cutClipLength = acc.cutClip && getClipLength(acc.cutClip);
        const isFulltimeNotEffectiveTime =
          playingMode.mode === PlayingModes.TACTICAL_CAMERA && !playingMode.useEffectiveTime;
        const isEpisodeMode = playingMode.mode === PlayingModes.EPISODES;
        const clip =
          acc.cutClip && cutClipLength !== undefined && cutClipLength >= clipLength
            ? roundClipTimes(acc.cutClip)
            : roundClipTimes(clipItem);
        const currentVideoSource = findVideoSourceForClip(clip, videoSources, isEpisodeMode);
        let videoSourceFromCut = <VideoSourceWithTimes | undefined>undefined;
        let clipLeftFromCut = <Clip | undefined>undefined;

        if (
          !currentVideoSource ||
          !currentVideoSource?.src ||
          (currentVideoSource?.startTimeInMatch === undefined && currentVideoSource?.endTimeInMatch === undefined)
        ) {
          return acc;
        }

        const firstClipInRange = getFirstClipInRange(clip, allClips);

        if (firstClipInRange) {
          const videoSourceFromCutEndTime = firstClipInRange.startTime - clip.startTime + clip.startTime;

          videoSourceFromCut =
            isEpisodeMode && currentVideoSource.startTimeInMatch && currentVideoSource.endTimeInMatch
              ? {
                  id: `${clip.id}-cut-${currentIndex}`,
                  startTime: round(clip.startTime - currentVideoSource.startTimeInMatch, 2),
                  startTimeInMatch: clip.startTime - (clip.startTime - currentVideoSource.startTimeInMatch),
                  endTimeInMatch: clip.endTime - (clip.endTime - currentVideoSource.endTimeInMatch),
                  endTime: round(videoSourceFromCutEndTime - currentVideoSource.startTimeInMatch, 2),
                  src: currentVideoSource.src,
                  srcDownload: currentVideoSource?.srcDownload,
                }
              : {
                  id: `${clip.id}-cut-${currentIndex}`,
                  startTime: clip.startTime,
                  startTimeInMatch: clip.startTime,
                  endTimeInMatch: videoSourceFromCutEndTime,
                  endTime: videoSourceFromCutEndTime,
                  src: currentVideoSource.src,
                  srcDownload: currentVideoSource?.srcDownload,
                };

          clipLeftFromCut = {
            ...clip,
            id: firstClipInRange.id,
            startTime: videoSourceFromCutEndTime,
            endTime: videoSourceFromCutEndTime > clip.endTime ? videoSourceFromCutEndTime : clip.endTime,
          };
        }

        const videoSource: VideoSourceWithTimes =
          isEpisodeMode && currentVideoSource.startTimeInMatch && currentVideoSource.endTimeInMatch
            ? {
                startTime: round(clip.startTime - currentVideoSource.startTimeInMatch, 2),
                endTime: round(
                  clip.startTime - currentVideoSource.startTimeInMatch + (clip.endTime - clip.startTime),
                  2,
                ),
                startTimeInMatch: clip.startTime - (clip.startTime - currentVideoSource.startTimeInMatch),
                endTimeInMatch: clip.endTime - (clip.endTime - currentVideoSource.endTimeInMatch),
                src: currentVideoSource.src,
                srcDownload: currentVideoSource?.srcDownload,
                id: clip.id,
              }
            : {
                startTime: clip.startTime,
                endTime: clip.endTime,
                startTimeInMatch: clip.startTime,
                endTimeInMatch: clip.endTime,
                src: currentVideoSource.src,
                srcDownload: currentVideoSource?.srcDownload,
                id: clip.id,
              };

        return {
          cutClip: clipLeftFromCut ? clipLeftFromCut : undefined,
          duration:
            isFulltimeNotEffectiveTime && videoSources[0]?.endTimeInMatch
              ? videoSources[0].endTimeInMatch
              : acc.duration + (videoSource.endTime - videoSource.startTime),
          videoSources: [...acc.videoSources, ...(videoSourceFromCut ? [videoSourceFromCut] : [videoSource])].flat(),
        };
      },
      <CreateVideoSourcesFromClips>{ videoSources: [], duration: 0, cutClip: undefined },
    );
};
