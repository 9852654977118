import KogniaLogoSmall from 'shared/assets/kognia-sports-logo-small.svg';
import KogniaLogotype from 'shared/assets/kognia-sports-logotype.svg';
import { ClientBranding } from 'shared/types/user/types';

import KogniaFavicon from '../../../../public/favicon.ico';
import { useCurrentClient } from '../use-current-client/useCurrentClient';

export const useBranding = (): ClientBranding => {
  const currentClient = useCurrentClient();

  return currentClient?.branding
    ? currentClient.branding
    : {
        displayName: 'Kognia Sports',
        navbarCollapsedLogoUrl: KogniaLogoSmall,
        navbarLogoUrl: KogniaLogotype,
        emailLogoUrl: KogniaLogotype,
        faviconUrl: KogniaFavicon,
      };
};
