import { useCallback } from 'react';

import { usePinScenarios, useSetPinScenarios } from '../../../../../hooks/use-pin-scenarios';
import { RowsHeadersGroup } from '../row-headers-group';
import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';

type Props = {
  rowGroup?: RowGroup;
  recordingId: string;
};
export const ScenariosRowGroupHeader = ({ rowGroup, recordingId }: Props) => {
  const pinScenarios = usePinScenarios();
  const setPinScenarios = useSetPinScenarios();

  if (!rowGroup) return null;

  const handleOnPin = useCallback(() => {
    setPinScenarios(!pinScenarios);
  }, [setPinScenarios, pinScenarios]);

  return (
    <RowsHeadersGroup
      key={rowGroup.type}
      recordingId={recordingId}
      level={0}
      onPin={handleOnPin}
      isPinned={pinScenarios}
      rowsGroup={rowGroup}
    />
  );
};
