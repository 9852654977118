import { Button, PopoverActions } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import React, { useRef, useState } from 'react';

import styles from './Filterv2.module.scss';

type FilterProps = {
  children: JSX.Element;
  displayName: string;
  hasSelectedOptions?: boolean;
  isOpen: boolean;
  onClickOpen: () => void;
  onClose: () => void;
};

export type FilterOption = {
  id: string;
  title: string;
  isSelected: boolean;
};

const Filter = ({ isOpen, onClickOpen, displayName, onClose, hasSelectedOptions = false, children }: FilterProps) => {
  const actionRef = useRef<PopoverActions | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const filterId = 'filter-menu';

  const updateMenuPosition = () => {
    actionRef.current?.updatePosition();
  };

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    onClickOpen();
  };

  const handleClose = () => {
    if (!anchorEl) return;

    setAnchorEl(undefined);
    onClose();
  };

  return (
    <>
      <Button
        aria-controls={filterId}
        color={isOpen || hasSelectedOptions ? 'primary' : 'inherit'}
        onClick={handleButtonClick}
        variant='underlined'
      >
        {displayName}
      </Button>
      {isOpen && anchorEl && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            action={actionRef}
            id={filterId}
            anchorEl={anchorEl}
            open
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            className={styles.menu}
          >
            <div className={styles.contentContainer}>{React.cloneElement(children, { updateMenuPosition })}</div>
          </Menu>
        </ClickAwayListener>
      )}
    </>
  );
};

export default Filter;
