import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

import { StagesApiResponse } from './types';
import { Stage, StageSummary } from '../../../types/stages';
import { StageApi, StageSummaryApi } from '../types';

export const transformStageSummaries = (items: StageSummaryApi[]): StageSummary[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    orderIndex: item.order_index,
    ...(item.parent_stage
      ? {
          parentStage: {
            name: item.parent_stage.name,
            orderIndex: item.parent_stage.order_index,
            id: item.parent_stage.uuid,
          },
        }
      : {}),
    childStages: item.child_stages.map((childStage) => ({
      name: childStage.name,
      orderIndex: childStage.order_index,
      id: childStage.uuid,
    })),
  }));
};

export const transformStages = (items: StageApi[]): Stage[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    orderIndex: item.order_index,
    season: {
      year: item.season.year,
      crossesYear: item.season.crosses_year,
      id: item.season.uuid,
    },
    childStages: item.child_stages.map((childStage) => ({
      name: childStage.name,
      orderIndex: childStage.order_index,
      id: childStage.uuid,
    })),
    parentStage: item.parent_stage
      ? {
          name: item.parent_stage.name,
          orderIndex: item.parent_stage.order_index,
          id: item.parent_stage.uuid,
        }
      : undefined,
  }));
};

export const transformStagesResponse = (response: StagesApiResponse): InfinityQueryDataResult<Stage> => {
  return {
    data: {
      items: transformStages(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
