import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import Spinner from 'shared/components/spinner';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { EditFormRecording } from 'shared/types/recording/types';

import styles from './RecordingEditPage.module.scss';
import { useFetchRecording } from '../../hooks/useGetRecording';
import RecordingEditForm from '../recording-edit-form';

type RouteParams = { id: string };

const RecordingEditContainer = (props: RouteComponentProps<RouteParams>) => {
  const {
    match: {
      params: { id },
    },
  } = props;
  const { t } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.recording-edit', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return <RecordingEditPage recordingId={id} />;
};

type RecordingEditPageProps = {
  recordingId: string;
};

const RecordingEditPage = ({ recordingId }: RecordingEditPageProps) => {
  const { current, data, PAGE_STATES } = usePageStateMachine<EditFormRecording>(() => useFetchRecording(recordingId));

  const { t } = useTranslation();

  return (
    <>
      {current.matches(PAGE_STATES.LOADING) && (
        <SidebarLayout>
          <Container>
            <Spinner isFullPage />
          </Container>
        </SidebarLayout>
      )}
      {current.matches(PAGE_STATES.ERROR) && (
        <SidebarLayout>
          <Container>
            <div className={styles.notFound}>
              <h4 className={styles.error}>{t('recording-edit:error')}</h4>
            </div>
          </Container>
        </SidebarLayout>
      )}
      {current.matches(PAGE_STATES.READY) && data && (
        <SidebarLayout>
          <Container>
            <RecordingEditForm recordingId={recordingId} initialData={data} />
          </Container>
        </SidebarLayout>
      )}
      )
    </>
  );
};

export default RecordingEditContainer;
