import { ButtonBase, styled, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import React, { ComponentType } from 'react';

import { IconColors, IconSizes, SvgIconProps } from 'shared/components/icons/svg-icon';

export const PlaylistBulkButtonBase = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.5, 1),
  transition: theme.transitions.create(['color']),
  ':disabled': { opacity: 0.8 },
  '&:hover': {
    color: theme.palette.primary.light,

    '& svg': {
      fill: theme.palette.primary.light,
    },
  },
}));

interface Props {
  onClick: () => void;
  IconComponent: ComponentType<SvgIconProps>;
  iconColor?: IconColors;
  iconSize?: IconSizes;
  label?: string;
  disabled?: boolean;
}

export const PlaylistBulkButton = ({
  onClick,
  IconComponent,
  label,
  iconColor = IconColors.white,
  iconSize = IconSizes.small,
  disabled = false,
}: Props) => {
  return (
    <PlaylistBulkButtonBase onClick={onClick} disabled={disabled}>
      <IconComponent color={iconColor} size={iconSize} />
      {label ? (
        <Typography component='span' fontSize={fontSizes.xxSmall} fontWeight='fontWeightMedium'>
          {label}
        </Typography>
      ) : null}
    </PlaylistBulkButtonBase>
  );
};
