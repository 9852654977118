import { Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  PlaylistItemsSelectorMenuNoItemsText,
  PlaylistItemsSelectorMenuTitle,
} from './PlaylistItemsSelectorMenu.styled';
import SelectedPlaylistItem from './selected-playslist-item';
import { usePlaylistMenuItems } from '../add-to-playlist-menu-state/hooks';

interface Props {
  onSave: () => void;
  playlistId?: string;
}

export const PlaylistItemsSelectorMenu = ({ onSave, playlistId }: Props) => {
  const { t } = useTranslation();
  const selectedPlaylistItems = usePlaylistMenuItems();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 50%', gap: ({ spacing }) => spacing(0.5) }}>
      {selectedPlaylistItems.length > 0 && (
        <>
          <Box flex={0} py={0.5} px={2} bgcolor='common.white'>
            <PlaylistItemsSelectorMenuTitle component='span'>{`${t('timeline:playlist-items-selector.title')} (${
              selectedPlaylistItems.length
            })`}</PlaylistItemsSelectorMenuTitle>
          </Box>
          <Box display='flex' flexDirection='column' gap={0.5} pt={1}>
            {selectedPlaylistItems.map((item) => (
              <SelectedPlaylistItem key={item.id} item={item} />
            ))}
          </Box>
          <Box display='flex' justifyContent='center' textAlign='center' mb={2} pt={1}>
            <Button variant='outlined' color='secondary' onClick={onSave} disabled={!playlistId}>
              {t('timeline:playlist-items-selector.button')}
            </Button>
          </Box>
        </>
      )}
      {selectedPlaylistItems.length === 0 && playlistId && (
        <PlaylistItemsSelectorMenuNoItemsText>
          {t('timeline:playlist-items-selector.no-items')}
        </PlaylistItemsSelectorMenuNoItemsText>
      )}
      {selectedPlaylistItems.length === 0 && !playlistId && (
        <PlaylistItemsSelectorMenuNoItemsText>
          {t('timeline:playlist-items-selector.select-playlist')}
        </PlaylistItemsSelectorMenuNoItemsText>
      )}
    </Box>
  );
};
