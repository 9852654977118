import { ErrorBoundary } from '@sentry/react';
import { Router } from 'react-router-dom';

import { AppTheme } from 'kognia/theme';
import { ErrorBoundaryPage } from 'shared/components/error-boundary';
import AppContext from 'shared/contexts/app-context';

import browserHistory from './browserHistory';
import 'kognia/i18n';
// eslint-disable-next-line import/order
import Routes from './Routes';

import './App.scss';

import { useSubscribeEvents } from './shared/hooks/use-subscribe-events';

function App(): JSX.Element {
  useSubscribeEvents();

  return (
    <AppTheme>
      <Router history={browserHistory}>
        <AppContext>
          <ErrorBoundary fallback={(props) => <ErrorBoundaryPage {...props} />}>
            <Routes />
          </ErrorBoundary>
        </AppContext>
      </Router>
    </AppTheme>
  );
}

export default App;
