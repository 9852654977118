import { useCallback, useEffect } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import { useDuration, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import { useCurrentPlaylistItemId } from 'shared/components/video-player/hooks';
import { ZoomLevelsType } from 'shared/types/zoom-range/zoomLevels';

import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { useTacticalAnalysisEpisodes } from '../../../../../hooks/use-tactical-analysis-episodes';
import { useTimelineTableData } from '../../../../../hooks/use-timeline-table-data';
import { useTimelineZoomLevel } from '../../../../../hooks/use-timeline-zoom-level';
import { adjustTimeSpaceByZoomLevel } from '../../../../tactical-analysis/utils/adjust-time-by-zoom-level';
import { TIMELINE_CONFIG } from '../../../config';
import { SEPARATOR_DOUBLE_WIDTH, SEPARATOR_WIDTH } from '../../components/timeline-css-variables';

const calculateWidthFromEpisodeClips = (clips: Clip[], isEffectiveTime: boolean, zoomLevel: ZoomLevelsType) => {
  if (!isEffectiveTime) {
    return clips.reduce((width, clip) => {
      return width + adjustTimeSpaceByZoomLevel(clip.endTime - clip.startTime, zoomLevel);
    }, 0);
  }

  if (clips.length === 0) return 0;

  return clips.reduce((width, clip) => {
    if (clip.type === 'not-effective-time') {
      const isHalfTime = clip.title;
      width += isHalfTime ? SEPARATOR_DOUBLE_WIDTH : SEPARATOR_WIDTH;
    } else {
      width += adjustTimeSpaceByZoomLevel(clip.endTime - clip.startTime, zoomLevel);
    }

    return width;
  }, SEPARATOR_WIDTH);
};

const timelineInnerWidth = atomFamily<number, string>({
  key: 'timeline-inner-width',
  default: 0,
});

export const useTimelineInnerWidth = (recordingId: string) => useRecoilValue(timelineInnerWidth(recordingId));
export const useSetTimelineInnerWidth = (recordingId: string) => {
  const setTimelineInnerWidth = useSetRecoilState(timelineInnerWidth(recordingId));

  return useCallback(
    (width: number) => {
      setTimelineInnerWidth(width ? width + TIMELINE_CONFIG.LIMIT_SPACING * 2 : 0);
    },
    [setTimelineInnerWidth],
  );
};

export const useGenerateTimelineWidth = (recordingId: string) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const innerWidth = useTimelineInnerWidth(recordingId);
  const playlistItemId = useCurrentPlaylistItemId();
  const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();
  const { zoomLevel } = useTimelineZoomLevel();
  const setTimelineInnerWidth = useSetTimelineInnerWidth(recordingId);
  const timelineData = useTimelineTableData(recordingId);
  const duration = useDuration();

  useEffect(() => {
    if (episodes.length === 0 && duration > 0) {
      return setTimelineInnerWidth(adjustTimeSpaceByZoomLevel(duration, zoomLevel));
    }

    const newInnerWidth = calculateWidthFromEpisodeClips(timelineData.episodesRow.clips, effectiveTime, zoomLevel);

    if ((newInnerWidth !== innerWidth || innerWidth === 0) && duration > 0 && playlistItemId !== '') {
      return setTimelineInnerWidth(
        effectiveTime
          ? calculateWidthFromEpisodeClips(timelineData.episodesRow.clips, effectiveTime, zoomLevel)
          : adjustTimeSpaceByZoomLevel(duration, zoomLevel),
      );
    }
    // TODO fix array of dependencies
  }, [duration, episodes, timelineData.episodesRow.clips, effectiveTime, zoomLevel, playlistItemId]);
};
