import { Box, Checkbox, Grid, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

import { getTeamLastGameDate, getTeamName } from 'pages/backoffice/pages/games/game-form/components/selected-players';
import { PlayerPhoto } from 'pages/backoffice/pages/players/components/player-photo/player-photo';
import { PlayerPhotoSkeleton } from 'pages/backoffice/pages/players/components/player-photo-skeleton';
import { MetadataPlayerSummary } from 'pages/backoffice/types/players';

import { useDates } from '../../../../hooks/use-dates';

interface Props {
  player: MetadataPlayerSummary;
  isChecked: boolean;
  autocompleteWidth: number;
}

const Details = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(),
  justifyContent: 'space-between',
  lineHeight: 1,
  flex: 1,
  width: '100%',
}));

interface NameProps {
  maxWidth: number;
}

const Name = styled(Box, { shouldForwardProp: (prop) => prop !== 'maxWidth' })<NameProps>(({ maxWidth }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: `${maxWidth - 56}px`,
}));

const Team = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  textAlign: 'right',
  flex: 1,
  paddingRight: theme.spacing(1),
}));

const Date = styled(Box)(() => ({
  fontSize: fontSizes.small,
  color: Colors.storm,
}));

const Result = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0.5),
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    background: Colors.background,
  },
}));

export const AutocompletePlayerResult = ({ player, isChecked, autocompleteWidth }: Props) => {
  const { dateToString } = useDates();
  const teamName = getTeamName(player);
  const lastGameDate = getTeamLastGameDate(player);
  const formattedLastGameDate = lastGameDate ? dateToString(lastGameDate) : undefined;

  return (
    <Result container sx={{ width: `${autocompleteWidth}px` }}>
      <Grid container spacing={1} alignItems='center'>
        <Grid item>
          <Checkbox checked={isChecked} />
        </Grid>
        <Grid item sx={{ flex: 1 }}>
          <Details>
            {player.photoUrl ? <PlayerPhoto src={player.photoUrl} /> : <PlayerPhotoSkeleton small />}
            <Name title={player.name} maxWidth={176}>
              {player.name}
            </Name>
            {teamName && formattedLastGameDate ? (
              <Team title={teamName}>
                <div>{teamName}</div>
                <Date>{formattedLastGameDate}</Date>
              </Team>
            ) : null}
          </Details>
        </Grid>
      </Grid>
    </Result>
  );
};
