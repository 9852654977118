import { useCallback, useState } from 'react';

import { FundamentalsSelection } from '../../../../shared/types/playlist/types';
import { Clip } from '../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { generateClipsWithOverlappingTime } from '../../api/use-tactical-analysis-data/generate-timeline-rows/utils/generateClips';

export const useAddToPlaylistValidator = () => {
  const [isValidationModalOpen, setIsValidationModalOpen] = useState(false);
  const [clipsToSave, setClipsToSave] = useState<Clip[]>([]);
  const [playlistId, setPlaylistId] = useState<string>('');
  const [fundamentalsSelected, setFundamentalsSelected] = useState<FundamentalsSelection>();

  const onModalOpen = useCallback(() => setIsValidationModalOpen(true), []);
  const onModalClose = useCallback(() => setIsValidationModalOpen(false), []);

  const checkForParentClips = useCallback(
    ({ playlistItems, callback }: { playlistItems: Clip[]; callback?: (callbackClips: Clip[]) => void }) => {
      const generatedClipsWithOverlappingTime = generateClipsWithOverlappingTime({ clips: playlistItems });

      const isAnyClipOverlapping = generatedClipsWithOverlappingTime.some((clip) => clip.type === 'parent-clip');

      if (isAnyClipOverlapping) {
        return onModalOpen();
      }

      return callback && callback(playlistItems);
    },
    [onModalOpen],
  );

  const setData = useCallback(
    ({
      clips,
      playlistId,
      fundamentalsSelected,
    }: {
      clips: Clip[];
      playlistId: string;
      fundamentalsSelected: FundamentalsSelection;
    }) => {
      setClipsToSave(clips);
      setPlaylistId(playlistId);
      setFundamentalsSelected(fundamentalsSelected);
    },
    [],
  );

  const resetData = useCallback(() => {
    setClipsToSave([]);
    setPlaylistId('');
    setFundamentalsSelected(undefined);
  }, []);

  return {
    onModalClose,
    onModalOpen,
    isValidationModalOpen,
    checkForParentClips,
    resetData,
    setData,
    data: {
      clipsToSave,
      playlistId,
      fundamentalsSelected,
    },
  };
};
