import { TypeOf } from 'zod';

import {
  annotationsTaskFormSchema,
  createTaskFormSchema,
  deleteTaskFormSchema,
  labelsTaskFormSchema,
  validationTaskFormSchema,
  reprocessSyncFormSchema,
  reprocessThirdPartyEventsFormSchema,
} from './index';
import { BackofficeAnnotationToolType, BackofficePipelineType, BackofficeTaskType } from '../../../../api/types';

export enum AnnotationFormFieldsNames {
  RECORDING_ID = 'recordingId',
  TASK_TYPE = 'taskType',
  START_IX = 'startIx',
  END_IX = 'endIx',
  LOAD_ALL = 'load',
  PIPELINE_TYPE = 'pipelineType',
  DRY_RUN = 'dryRun',
  EPISODE_INDEX = 'episodeIx',
  ANNOTATION_TOOL = 'annotationTool',
  TACTICAL_ANALYSIS_ID = 'tacticalAnalysisId',
  GIT_COMMIT = 'gitCommit',
}

export interface AnnotationFormFields {
  [AnnotationFormFieldsNames.RECORDING_ID]: string;
  [AnnotationFormFieldsNames.TASK_TYPE]: BackofficeTaskType;
  [AnnotationFormFieldsNames.START_IX]: string;
  [AnnotationFormFieldsNames.END_IX]: string;
  [AnnotationFormFieldsNames.LOAD_ALL]: boolean;
  [AnnotationFormFieldsNames.PIPELINE_TYPE]: BackofficePipelineType;
  [AnnotationFormFieldsNames.DRY_RUN]: boolean;
  [AnnotationFormFieldsNames.EPISODE_INDEX]: string;
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: BackofficeAnnotationToolType;
  [AnnotationFormFieldsNames.TACTICAL_ANALYSIS_ID]: string;
  [AnnotationFormFieldsNames.GIT_COMMIT]: string;
}

export interface CreateTaskForm {
  [AnnotationFormFieldsNames.RECORDING_ID]: AnnotationFormFields[AnnotationFormFieldsNames.RECORDING_ID];
  [AnnotationFormFieldsNames.TASK_TYPE]: AnnotationFormFields[AnnotationFormFieldsNames.TASK_TYPE];
  [AnnotationFormFieldsNames.START_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.START_IX];
  [AnnotationFormFieldsNames.END_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.END_IX];
  [AnnotationFormFieldsNames.LOAD_ALL]: AnnotationFormFields[AnnotationFormFieldsNames.LOAD_ALL];
  [AnnotationFormFieldsNames.PIPELINE_TYPE]?: AnnotationFormFields[AnnotationFormFieldsNames.PIPELINE_TYPE];
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: AnnotationFormFields[AnnotationFormFieldsNames.ANNOTATION_TOOL];
}

export interface DeleteTaskForm {
  [AnnotationFormFieldsNames.RECORDING_ID]: AnnotationFormFields[AnnotationFormFieldsNames.RECORDING_ID];
  [AnnotationFormFieldsNames.TASK_TYPE]: AnnotationFormFields[AnnotationFormFieldsNames.TASK_TYPE];
  [AnnotationFormFieldsNames.START_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.START_IX];
  [AnnotationFormFieldsNames.END_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.END_IX];
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: AnnotationFormFields[AnnotationFormFieldsNames.ANNOTATION_TOOL];
}

export interface ValidateTaskForm {
  [AnnotationFormFieldsNames.RECORDING_ID]: AnnotationFormFields[AnnotationFormFieldsNames.RECORDING_ID];
  [AnnotationFormFieldsNames.PIPELINE_TYPE]?: AnnotationFormFields[AnnotationFormFieldsNames.PIPELINE_TYPE];
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: AnnotationFormFields[AnnotationFormFieldsNames.ANNOTATION_TOOL];
  [AnnotationFormFieldsNames.DRY_RUN]: AnnotationFormFields[AnnotationFormFieldsNames.DRY_RUN];
}

export interface AnnotationsForm {
  [AnnotationFormFieldsNames.RECORDING_ID]: AnnotationFormFields[AnnotationFormFieldsNames.RECORDING_ID];
  [AnnotationFormFieldsNames.TASK_TYPE]: AnnotationFormFields[AnnotationFormFieldsNames.TASK_TYPE];
  [AnnotationFormFieldsNames.START_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.START_IX];
  [AnnotationFormFieldsNames.END_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.END_IX];
  [AnnotationFormFieldsNames.PIPELINE_TYPE]?: AnnotationFormFields[AnnotationFormFieldsNames.PIPELINE_TYPE];
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: AnnotationFormFields[AnnotationFormFieldsNames.ANNOTATION_TOOL];
}

export interface LabelsForm {
  [AnnotationFormFieldsNames.RECORDING_ID]: AnnotationFormFields[AnnotationFormFieldsNames.RECORDING_ID];
  [AnnotationFormFieldsNames.TASK_TYPE]: AnnotationFormFields[AnnotationFormFieldsNames.TASK_TYPE];
  [AnnotationFormFieldsNames.START_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.START_IX];
  [AnnotationFormFieldsNames.END_IX]?: AnnotationFormFields[AnnotationFormFieldsNames.END_IX];
  [AnnotationFormFieldsNames.ANNOTATION_TOOL]: AnnotationFormFields[AnnotationFormFieldsNames.ANNOTATION_TOOL];
}

export interface ReprocessSyncForm {
  [AnnotationFormFieldsNames.RECORDING_ID]: AnnotationFormFields[AnnotationFormFieldsNames.RECORDING_ID];
}

export interface ReprocessThirdPartyEventsForm {
  [AnnotationFormFieldsNames.RECORDING_ID]: AnnotationFormFields[AnnotationFormFieldsNames.RECORDING_ID];
}

export type CreateTaskFormSchema = TypeOf<typeof createTaskFormSchema>;
export type DeleteTaskFormSchema = TypeOf<typeof deleteTaskFormSchema>;
export type AnnotationsTaskFormSchema = TypeOf<typeof annotationsTaskFormSchema>;
export type LabelsTaskFormSchema = TypeOf<typeof labelsTaskFormSchema>;
export type ValidationTaskFormSchema = TypeOf<typeof validationTaskFormSchema>;
export type ReprocessSyncFormSchema = TypeOf<typeof reprocessSyncFormSchema>;
export type ReprocessThirdPartyEventsFormSchema = TypeOf<typeof reprocessThirdPartyEventsFormSchema>;
