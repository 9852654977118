import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccountForm from 'pages/account/components/account-form';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useUser } from 'shared/contexts/app-state';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

const AccountPageContainer = () => {
  const user = useUser();
  const { t } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.account', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <SidebarLayout>
      <Container>
        <AccountForm user={user} />
      </Container>
    </SidebarLayout>
  );
};

export default AccountPageContainer;
