import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

import { CompetitionsApiResponse } from './types';
import { Competition } from '../../../types/competitions';
import { CompetitionApi } from '../types';

const transformData = (items: CompetitionApi[]): Competition[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    country: item.country,
  }));
};

export const transformCompetitions = (response: CompetitionsApiResponse): InfinityQueryDataResult<Competition> => {
  return {
    data: {
      items: transformData(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
