import { round } from 'lodash';

import { VideoSourceWithTimes } from 'shared/components/video-player/types';


import { Clip } from '../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

const roundVideoSourceTimes = (videoSource: VideoSourceWithTimes) => {
  return {
    ...videoSource,
    startTime: round(videoSource.startTime, 2),
    endTime: round(videoSource.endTime, 2),
    ...(videoSource.startTimeInMatch && { startTimeInMatch: round(videoSource.startTimeInMatch, 2) }),
    ...(videoSource.endTimeInMatch && { endTimeInMatch: round(videoSource.endTimeInMatch, 2) }),
  };
};

const roundClipTimes = (clip: Clip) => {
  return {
    ...clip,
    startTime: round(clip.startTime, 2),
    endTime: round(clip.endTime, 2),
  };
};

const getFirstClipInRange = (clip: Clip, clips: Clip[]) => {
  const clipWithRoundedTimes = roundClipTimes(clip);
  return clips
    .filter((item) => item.id !== clip.id)
    .map((item) => roundClipTimes(item))
    .find((otherClip) => {
      return (
        (clipWithRoundedTimes.startTime <= otherClip.startTime &&
          !(
            clipWithRoundedTimes.startTime === otherClip.startTime && clipWithRoundedTimes.endTime === otherClip.endTime
          ) &&
          clipWithRoundedTimes.startTime > otherClip.startTime &&
          clipWithRoundedTimes.startTime < otherClip.endTime) ||
        (clipWithRoundedTimes.endTime < otherClip.endTime && clipWithRoundedTimes.endTime > otherClip.startTime) ||
        (otherClip.startTime >= clipWithRoundedTimes.startTime && otherClip.endTime <= clipWithRoundedTimes.endTime)
      );
    });
};

const getClipsWithoutDuplicatedTime = (clips: Clip[]) => {
  return clips.reduce(
    (acc, item, index, array) => {
      const clipWithRoundedTime = roundClipTimes(item);
      const isClipWithSameTime = array.find((item) => {
        const itemWithRoundedTime = roundClipTimes(item);
        return (
          itemWithRoundedTime.startTime === clipWithRoundedTime.startTime &&
          itemWithRoundedTime.endTime === clipWithRoundedTime.endTime &&
          itemWithRoundedTime.id !== clipWithRoundedTime.id
        );
      });
      const isClipWithSameTimeInAcc = acc.find((item) => {
        const itemWithRoundedTime = roundClipTimes(item);
        return (
          itemWithRoundedTime.startTime === clipWithRoundedTime.startTime &&
          itemWithRoundedTime.endTime === clipWithRoundedTime.endTime &&
          itemWithRoundedTime.id !== clipWithRoundedTime.id
        );
      });

      if (isClipWithSameTime && isClipWithSameTimeInAcc) return acc;

      const clipsWithSameStartTime = array.filter((item) => round(item.startTime, 2) === clipWithRoundedTime.startTime);
      const longestClipWithSameStartTime = clipsWithSameStartTime.reduce((acc, item) => {
        const itemClipWithRoundedTime = roundClipTimes(item);
        return acc.endTime - acc.startTime > itemClipWithRoundedTime.endTime - itemClipWithRoundedTime.startTime
          ? acc
          : itemClipWithRoundedTime;
      }, clipsWithSameStartTime[0]);
      const isClipWithSameStartTimeInAcc = acc.find((item) => item.startTime === clipWithRoundedTime.startTime);

      if (longestClipWithSameStartTime && !isClipWithSameStartTimeInAcc) return [...acc, longestClipWithSameStartTime];
      if (isClipWithSameStartTimeInAcc) return acc;

      return [...acc, clipWithRoundedTime];
    },
    <Clip[]>[],
  );
};

const getClipLength = (clip: Clip | VideoSourceWithTimes) => {
  return clip.endTime - clip.startTime;
};

const findVideoSourceForClip = (clip: Clip, videoSources: VideoSourceWithTimes[], isEpisodeMode: boolean) => {
  const videoSourceWithRoundedTimes = videoSources.map(roundVideoSourceTimes);
  return videoSourceWithRoundedTimes.find((videoSource) =>
    isEpisodeMode && videoSource.startTimeInMatch && videoSource.endTimeInMatch
      ? clip.startTime >= videoSource.startTimeInMatch && clip.endTime <= videoSource.endTimeInMatch
      : clip.startTime >= videoSource.startTime && clip.endTime <= videoSource.endTime,
  );
};

export {
  findVideoSourceForClip,
  roundVideoSourceTimes,
  roundClipTimes,
  getFirstClipInRange,
  getClipsWithoutDuplicatedTime,
  getClipLength,
};
