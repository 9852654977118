import find from 'lodash/find';
import reduce from 'lodash/reduce';

import { Episode, isEventStarting, TacticalScenario } from 'shared/types/index';

import { generateBlockContainerClip } from './generateBlockContainerClip';
import { sortByTitle } from './sortByTitle';
import { Row, RowGroup, RowList } from '../types/row';

const findLastValidScenarioInEpisode = (episode: Episode): TacticalScenario | undefined => {
  let highest = Number.NEGATIVE_INFINITY;
  let lastScenarioIndex = -1;
  let tmp;
  for (let i = episode.tacticalScenarios.length - 1; i >= 0; i--) {
    tmp = episode.tacticalScenarios[i].endTime;
    if (tmp > highest && !isEventStarting(episode.tacticalScenarios[i].tacticalScenarioType)) {
      highest = tmp;
      lastScenarioIndex = i;
    }
  }

  return lastScenarioIndex !== -1 ? episode.tacticalScenarios[lastScenarioIndex] : undefined;
};

export const generateEventsRows = (episodes: Episode[], recordingId: string): RowGroup => {
  const endingEventsRowList: RowList = {};
  const startingEventsRowList: RowList = {};

  episodes.forEach((episode) => {
    const firstValidScenario = episode.tacticalScenarios[0];

    if (firstValidScenario) {
      const scenario = firstValidScenario;
      const id = `${recordingId}-${episode.startAction.state}`;

      if (!startingEventsRowList[episode.startAction.state]) {
        startingEventsRowList[episode.startAction.state] = generateBlockContainerClip({
          id: id,
          rowType: 'events',
          timelineTableBlocks: episodes,
          title: episode.startAction.name,
          rowId: id,
          clipIdPrefix: id,
        });
      }

      const episodeClip = find(
        startingEventsRowList[episode.startAction.state].clips,
        (row) => row.id === `${id}-${episode.id}`,
      );

      episodeClip?.clips?.push({
        id: `${episode.id}-${scenario.teamId}-${scenario.startTime}-${scenario.endTime}`,
        startTime: scenario.startTime,
        endTime: scenario.endTime,
        type: 'event',
        elementId: scenario.tacticalScenarioType,
        rowId: id,
        teamId: scenario.teamId,
        title: episode.startAction.name,
      });
    }

    const latestValidScenario = findLastValidScenarioInEpisode(episode);

    if (latestValidScenario) {
      const scenario = latestValidScenario;
      const id = `${recordingId}-${episode.endAction.state}`;

      if (!endingEventsRowList[episode.endAction.state]) {
        endingEventsRowList[episode.endAction.state] = generateBlockContainerClip({
          id: id,
          rowType: 'events',
          timelineTableBlocks: episodes,
          title: episode.endAction.name,
          rowId: id,
          clipIdPrefix: id,
        });
      }

      const episodeClip = find(
        endingEventsRowList[episode.endAction.state].clips,
        (row) => row.id === `${id}-${episode.id}`,
      );

      episodeClip?.clips?.push({
        id: `${episode.id}-${scenario.teamId}-${scenario.startTime}-${scenario.endTime}`,
        startTime: scenario.startTime,
        endTime: scenario.endTime,
        type: 'event',
        elementId: scenario.tacticalScenarioType,
        rowId: id,
        teamId: scenario.teamId,
        title: episode.endAction.name,
      });
    }
  });

  const startingEventsRows: Row[] = reduce(
    startingEventsRowList,
    (acc, row) => {
      acc.push(row);
      return acc;
    },
    [] as Row[],
  );

  const startingEventsRowGroup: RowGroup = {
    id: `${recordingId}-starting-events`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:events-starting',
    rows: startingEventsRows.sort(sortByTitle),
    totalClips: reduce(startingEventsRows, (acc, row) => acc + row.clips.length, 0),
    type: 'events',
  };

  const endingEventsRows: Row[] = reduce(
    endingEventsRowList,
    (acc, row) => {
      acc.push(row);
      return acc;
    },
    [] as Row[],
  );

  const endingEventsRowsGroup: RowGroup = {
    id: `${recordingId}-ending-events`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:events-ending',
    totalClips: reduce(endingEventsRows, (acc, row) => acc + row.clips.length, 0),
    rows: endingEventsRows.sort(sortByTitle),
    type: 'events',
  };

  const eventsRowGroups = [];
  startingEventsRowGroup.totalClips > 0 && eventsRowGroups.push(startingEventsRowGroup);
  endingEventsRowsGroup.totalClips > 0 && eventsRowGroups.push(endingEventsRowsGroup);

  return {
    id: `${recordingId}-events`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:events',
    rows: [],
    totalClips: startingEventsRowGroup.totalClips + endingEventsRowsGroup.totalClips,
    rowGroups: eventsRowGroups,
    type: 'events',
  };
};
