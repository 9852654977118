import React, { PropsWithChildren, RefObject, useCallback, useEffect, useState } from 'react';

import { ResizeBarWrapper } from './ResizeBar.styled';
import { applyResizeLimits } from './utils/apply-resize-limits';
import resizer from './utils/resizer';
import resizerTouch from './utils/resizer-touch';

export type ResizeDirection = 'horizontal' | 'vertical';

interface Props {
  mainPanelElement: RefObject<HTMLDivElement>;
  secondaryPanelElement: RefObject<HTMLDivElement>;
  mainPanelMinSize: number;
  secondaryPanelMinSize: number;
  direction: ResizeDirection;
  onChange: (value: number) => void;
}

interface BarProps extends React.HTMLAttributes<HTMLDivElement> {
  isDragging: boolean;
  direction: 'vertical' | 'horizontal';
}

const Bar = ({ children, direction, isDragging, ...props }: PropsWithChildren<BarProps>) => (
  <ResizeBarWrapper isDragging={isDragging} isHorizontal={direction === 'horizontal'} {...props}>
    {children}
  </ResizeBarWrapper>
);

const ResizeBar = ({
  mainPanelElement,
  secondaryPanelElement,
  mainPanelMinSize,
  secondaryPanelMinSize,
  direction,
  onChange,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false);

  const handleResize = useCallback(
    (value: number) => {
      onChange(value);
    },
    [onChange],
  );

  const handleScreenResize = useCallback(() => {
    if (!mainPanelElement.current || !secondaryPanelElement.current) return;

    const updateProperty = direction === 'horizontal' ? 'width' : 'height';

    const mainPanel = mainPanelElement.current.getBoundingClientRect();
    const secondaryPanel = secondaryPanelElement.current.getBoundingClientRect();
    const changeValue = applyResizeLimits(
      mainPanel[updateProperty],
      secondaryPanel[updateProperty],
      mainPanelMinSize,
      secondaryPanelMinSize,
    );

    handleResize(changeValue);

    mainPanelElement.current.style[updateProperty] = changeValue + 'px';
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);

    return () => window.removeEventListener('resize', handleScreenResize);
  }, []);

  const handleStartDrag = useCallback(() => {
    setIsDragging(true);
  }, []);

  const handleEndDrag = useCallback(() => {
    setIsDragging(false);
  }, []);

  return (
    <Bar
      direction={direction}
      isDragging={isDragging}
      onMouseDown={(e) =>
        resizer(
          e,
          // @ts-ignore
          mainPanelElement.current,
          secondaryPanelElement.current,
          mainPanelMinSize,
          secondaryPanelMinSize,
          direction,
          handleResize,
          handleStartDrag,
          handleEndDrag,
        )
      }
      onTouchStart={(e) =>
        resizerTouch(
          e,
          // @ts-ignore
          mainPanelElement.current,
          secondaryPanelElement.current,
          mainPanelMinSize,
          secondaryPanelMinSize,
          direction,
          handleResize,
          handleStartDrag,
          handleEndDrag,
        )
      }
    >
      <div />
      <div />
      <div />
      <div />
    </Bar>
  );
};

export default ResizeBar;
