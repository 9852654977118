import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from '../../hooks/useFetchRequest';
import { tacticalAnalysesFilters } from '../../routes';
import { TacticalAnalysesFilters } from '../useEpisodesWithFilters/types';
import { transformFilters } from '../utils';

const generateQueryRef = (recordingIds: string[]) => [`fetchTacticalAnalysesFilters:${recordingIds.join(',')}`];

export const useTacticalAnalysesFilters = (recordingIds: string[]): UseQueryResult<TacticalAnalysesFilters> => {
  const fetchQueryRef = generateQueryRef(recordingIds);

  return useFetchRequest<TacticalAnalysesFilters>({
    queryRef: fetchQueryRef,
    url: tacticalAnalysesFilters(recordingIds),
    transformer: transformFilters,
    options: {
      enabled: recordingIds.length > 0,
    },
  });
};
