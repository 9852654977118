import { omit } from 'lodash';
import queryString from 'query-string';

import { SharedQueryOptions } from 'api/hooks/useInfinityQuery/types';
import { generateQueryParamsFromOptionsAndFilters } from 'api/utils';

import { CompetitionsFilters } from './competitions/use-competitions/types';
import { SeasonsApiFilters } from './seasons/use-seasons/types';
import { StagesApiFilters } from './stages/use-stages/types';
import { VenuesFilters } from './venues/use-venues/types';
import { MetadataPlayersApiFilters } from '../types/players';
import { MetadataTeamsFilters } from '../types/teams';

export const ALLOWED_BACKOFFICE_ORIGINS = [
  'https://dev.app.kogniasports.com',
  'https://app.st.kogniasports.com',
  'https://app.kogniasports.com',
  'http://localhost:3000',
];
export const backofficeDomainUrl = window.location.origin;
const backofficeApiPrefix = '/backoffice/api';
const backofficeApiVersionPrefix = '/v1';
const backofficeApiWithVersionPrefix = `${backofficeApiPrefix}${backofficeApiVersionPrefix}`;
const cvatPrefix = '/cvat-client';
const fbvarsPrefix = '/fbvars';
const watcherPrefix = '/watcher';
const jobManagerPrefix = '/job-manager';

export const backofficeApiUrl = `${backofficeDomainUrl}${backofficeApiPrefix}`;

export const validateBackofficeOrigin = (url: string) =>
  ALLOWED_BACKOFFICE_ORIGINS.some((allowedOrigin) => url.startsWith(allowedOrigin));

export const annotationApiUrls = {
  CREATE_TASK: `${backofficeApiUrl}${cvatPrefix}/create-tasks`,
  DELETE_TASK: `${backofficeApiUrl}${cvatPrefix}/delete-tasks`,
  VALIDATE_TASK: `${backofficeApiUrl}${cvatPrefix}/validate-tasks`,
  ANNOTATIONS_TASK: `${backofficeApiUrl}${cvatPrefix}/load-annotations`,
  LABELS_TASK: `${backofficeApiUrl}${cvatPrefix}/update-labels`,
  PROCESS_EPISODE: `${backofficeApiUrl}${fbvarsPrefix}/process-episodes`,
  PROCESS_GAME: `${backofficeApiUrl}${watcherPrefix}/intake`,
  DELETE_EPISODE_OVERLAYS: `${backofficeApiUrl}${fbvarsPrefix}/delete-overlays`,
  THIRD_PARTY_SYNC: `${backofficeApiUrl}${jobManagerPrefix}/third-party-tracking`,
  THIRD_PARTY_EVENTS: `${backofficeApiUrl}${jobManagerPrefix}/third-party-events`,
  PLAYERS: `${backofficeApiWithVersionPrefix}/players`,
  TEAMS: `${backofficeApiWithVersionPrefix}/teams`,
  COMPETITIONS: `${backofficeApiWithVersionPrefix}/competitions`,
  SEASON_CLIENTS: `${backofficeApiWithVersionPrefix}/season-clients`,
  COACHES: `${backofficeApiWithVersionPrefix}/coaches`,
  VENUES: `${backofficeApiWithVersionPrefix}/venues`,
  SEASONS: `${backofficeApiWithVersionPrefix}/season-competitions`,
  MAPPINGS: `${backofficeApiWithVersionPrefix}/mappings`,
  GAMES: `${backofficeApiWithVersionPrefix}/fixtures`,
  GAME_PLAYERS: (gameId: string) => `${backofficeApiWithVersionPrefix}/fixtures/${gameId}/players`,
  RECORDINGS: `${backofficeApiWithVersionPrefix}/recordings`,
  STAGES: `${backofficeApiWithVersionPrefix}/stages`,
  SEASON_COMPETITIONS_STAGES: (seasonId: string) =>
    `${backofficeApiWithVersionPrefix}/season-competitions/${seasonId}/stages`,
};

export const addUpdatePlayersToGamesUrl = (gameId: string) => `${annotationApiUrls.GAMES}/${gameId}/players`;

const omitSortOptions = (options: SharedQueryOptions) => {
  // Omitting filters that are not defined in the API to avoid sending them to the API.
  // Remove when params are available.
  return omit(options, 'sort', 'sortDirection');
};

export const playersWithFiltersUrl = (options: SharedQueryOptions, filters: MetadataPlayersApiFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.PLAYERS,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const getCompetitionsUrl = (options: SharedQueryOptions, filters: CompetitionsFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.COMPETITIONS,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const getSeasonClientsUrl = (options: SharedQueryOptions) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.SEASON_CLIENTS,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), {}),
  });
};

export const getCoachesUrl = (options: SharedQueryOptions, filters: CompetitionsFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.COACHES,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const getVenuesUrl = (options: SharedQueryOptions, filters: VenuesFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.VENUES,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const getSeasonsUrl = (options: SharedQueryOptions, filters: SeasonsApiFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.SEASONS,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const getStagesUrl = (options: SharedQueryOptions, filters: StagesApiFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.STAGES,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const teamsWithFiltersUrl = (options: SharedQueryOptions, filters: MetadataTeamsFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.TEAMS,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const getGamesUrl = (options: SharedQueryOptions, filters: StagesApiFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.GAMES,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

export const getRecordingsUrl = (options: SharedQueryOptions, filters: StagesApiFilters) => {
  return queryString.stringifyUrl({
    url: annotationApiUrls.RECORDINGS,
    query: generateQueryParamsFromOptionsAndFilters(omitSortOptions(options), filters),
  });
};

// Google Auth
export const googleAuthUrl = `${backofficeDomainUrl}/oauth2/auth`;

export const getGoogleOauth2LoginUrl = ({
  callbackUrl,
}: {
  callbackUrl?: string;
} = {}) => {
  return `${backofficeDomainUrl}/oauth2/start?rd=${callbackUrl}`;
};
