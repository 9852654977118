import { Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

const FilterBlockClear = ({ onClick }: Props) => {
  const { t } = useTranslation();
  return (
    <Stack direction='row' justifyContent='flex-end'>
      <Button color='secondary' onClick={onClick}>
        {t('recordings:filters.clear-filter')}
      </Button>
    </Stack>
  );
};

export default FilterBlockClear;
