import { Box } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecordings } from 'api/recording/useFetchRecordings';
import Container from 'shared/components/container';
import Pagination from 'shared/components/pagination';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { FiltersList } from 'shared/types/filters/types';

import AnnotationTypeFilter from './filters-bar/annotation-type-filter';
import useRecordingsFilters from './filters-bar/hooks/useRecordingsFilters';
import { RecordingListWithFilters } from './recording-list-with-filters';
import { RecordingTypesTabsAndFilters, selectInitialCompetitionIndex } from './recording-types-tabs-and-filters';
import styles from './RecordingsListPage.module.scss';
import { getFiltersFromUrl } from '../../../../api/recording/useFetchRecordings/util/get-initial-filters';
import { TabType } from '../../RecordingsListPageContainer';
import { RecordingFilters } from '../../types';

interface Options {
  [RecordingFilters.ANNOTATION_TYPE]: string[];
  [RecordingFilters.COMPETITION]: string[];
  [RecordingFilters.DATE]: string[];
  [RecordingFilters.MATCHDAY]: string[];
  [RecordingFilters.TEAM]: string[];
  [RecordingFilters.TYPE]: string[];
}

export const generateAppliedFilters = (options: Options, recordingsFilters: FiltersList): FiltersList => {
  const customRecordingFilters = { ...recordingsFilters };
  Object.keys(options).forEach((recordingFilter) => {
    customRecordingFilters[recordingFilter] = {
      ...customRecordingFilters[recordingFilter],
      options: reduce(
        customRecordingFilters[recordingFilter].options,
        (acc, option, key) => {
          const isApplied =
            Array.isArray(options[recordingFilter as RecordingFilters]) &&
            options[recordingFilter as RecordingFilters].includes(key);
          return {
            ...acc,
            [key]: { ...option, isApplied },
          };
        },
        {},
      ),
    };
  });

  return customRecordingFilters;
};

interface Props {
  defaultFilters: FiltersList;
  competitionsTabs: TabType[];
}

// TODO get the rest of the filters for initial call
const generateInitialFilters = (competitionsTabs: TabType[]): FiltersList => {
  const initialFilters = getFiltersFromUrl();
  const initialCompetition = selectInitialCompetitionIndex(competitionsTabs);

  // Get initial competition
  const competition = initialFilters.competition
    ? initialFilters.competition
    : competitionsTabs[initialCompetition]?.value[0];

  return competition
    ? {
        [RecordingFilters.COMPETITION]: {
          title: RecordingFilters.COMPETITION,
          options: {
            [competition]: {
              isApplied: true,
              title: competition,
            },
          },
        },
      }
    : {};
};

export const RecordingsListPage = ({ defaultFilters, competitionsTabs }: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState<FiltersList>({});

  const {
    data,
    totalElements,
    setRecordingsFilters,
    recordingsFilters,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    RecordingsStateContext,
  } = useRecordings({ initialFilters: generateInitialFilters(competitionsTabs) });

  const { filtersApplied, applyFilters, removeFilter } = useRecordingsFilters(recordingsFilters);

  const handleSetRecordingsFilters = useCallback(
    (options: Options, recordingsFilters: FiltersList) => {
      const customRecordingFilters = generateAppliedFilters(options, recordingsFilters);

      setRecordingsFilters(customRecordingFilters);
    },
    [setRecordingsFilters],
  );

  useEffect(() => {
    if (isEmpty(filters) && !isEmpty(recordingsFilters)) {
      setFilters(recordingsFilters);
    }
  }, [filters, recordingsFilters]);

  useEffect(() => {
    if (!isEmpty(defaultFilters) && !isEmpty(filtersApplied)) {
      handleSetRecordingsFilters(
        {
          [RecordingFilters.ANNOTATION_TYPE]: filtersApplied[RecordingFilters.ANNOTATION_TYPE],
          [RecordingFilters.COMPETITION]: filtersApplied[RecordingFilters.COMPETITION],
          [RecordingFilters.DATE]: filtersApplied[RecordingFilters.DATE],
          [RecordingFilters.MATCHDAY]: filtersApplied[RecordingFilters.MATCHDAY],
          [RecordingFilters.TEAM]: filtersApplied[RecordingFilters.TEAM],
          [RecordingFilters.TYPE]: filtersApplied[RecordingFilters.TYPE],
        },
        defaultFilters,
      );
    }
  }, [filtersApplied]);

  return (
    <SidebarLayout>
      <Container>
        <div className={styles['recordings-list-page']}>
          <div className={styles['container recordings-list-page__content']}>
            <Box sx={{ minHeight: 165 }}>
              <Box sx={{ marginBottom: 3 }}>
                <AnnotationTypeFilter
                  annotationFilter={
                    isEmpty(recordingsFilters[RecordingFilters.ANNOTATION_TYPE])
                      ? defaultFilters[RecordingFilters.ANNOTATION_TYPE]
                      : recordingsFilters[RecordingFilters.ANNOTATION_TYPE]
                  }
                  applyFilters={applyFilters}
                />

                {competitionsTabs.length > 0 && (
                  <RecordingTypesTabsAndFilters
                    appliedFilters={recordingsFilters}
                    applyFilters={applyFilters}
                    competitionsTabs={competitionsTabs}
                    filters={defaultFilters}
                    removeFilter={removeFilter}
                  />
                )}
              </Box>
            </Box>
            <RecordingsStateContext>
              <RecordingListWithFilters isLoading={data.length === 0 && isFetching} recordings={data} />
            </RecordingsStateContext>
            {data.length ? (
              <Pagination
                total={totalElements}
                displayed={data.length}
                getStatsText={(displayed, total) =>
                  t('recordings-list:pagination.total', { displayed, total, count: total })
                }
                onShowMore={fetchNextPage}
                loading={isFetchingNextPage || Boolean(data.length && isFetching)}
              />
            ) : null}
          </div>
        </div>
      </Container>
    </SidebarLayout>
  );
};
