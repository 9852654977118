import { Grid, styled } from '@mui/material';

import { playlistTimelineHeight } from '../config/Playlist.config';

export const PlaylistTimelineContainer = styled(Grid)({
  height: playlistTimelineHeight,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
});
