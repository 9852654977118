import { styled } from '@mui/material';

export const LogoImg = styled('img')({
  width: '100%',
  maxWidth: '100%',
});

export const LogoImgSmall = styled('img')({
  width: '100%',
  maxWidth: '40px',
});
