import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { VerticalSeparator } from 'shared/components/vertical-separator';

import { Row } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { useSelectedPlayingRow } from '../../../../../hooks/use-selection-playing';
import { useTeamUtils, useTimelineTeamIdFocus } from '../../../../../hooks/use-timeline-team-id-focus';
import { TIMELINE_CONFIG } from '../../../config';
import { useRowTeam } from '../../hooks/use-row-team';
import { useRowIsHighlighted, useSetRowIsHighlighted } from '../../store/atoms';
import timelineStyles from '../../TimelineTable.module.scss';
import { RowHeaderContainer } from '../row-header-container';
import { RowHeaderContent } from '../row-header-content';
import { RowHeaderTotal } from '../row-header-total';
import { getScenarioClips } from '../row-headers-group/util';
import { RowScenariosContent } from '../row-scenarios-content';
import styles from '../Rows.module.scss';
import { TeamRowHighlight } from '../team-row-highlight';

type Props = {
  recordingId: string;
  row: Row;
};

export const RowHeaderScenario = ({ recordingId, row }: Props) => {
  const { t } = useTranslation();
  const { isHomeTeam } = useTeamUtils(recordingId);
  const { isHomeTeamSelected } = useTimelineTeamIdFocus(recordingId);
  const team = useRowTeam(recordingId, row.teamId);
  const isRowHighlighted = useRowIsHighlighted(row.id);
  const setRowHighlighted = useSetRowIsHighlighted(row.id);
  const playingRow = useSelectedPlayingRow(recordingId);

  const homeTeamClips = useMemo(
    () => getScenarioClips(row.clips.map((clip) => (clip.clips ? clip.clips : [])).flat(), isHomeTeam),
    [isHomeTeam, row.clips],
  );
  const opponentTeamClips = useMemo(
    () =>
      getScenarioClips(row.clips.map((clip) => (clip.clips ? clip.clips : [])).flat(), (teamId) => !isHomeTeam(teamId)),
    [isHomeTeam, row.clips],
  );

  const handleMouseEnter = useCallback(() => {
    setRowHighlighted(true);
  }, [setRowHighlighted]);

  const handleMouseLeave = useCallback(() => {
    setRowHighlighted(false);
  }, [setRowHighlighted]);

  if (row.isHidden) return null;

  const getIsEnabled = (team: 'home' | 'opponent') => {
    return playingRow?.rowId === row.id && playingRow?.team === team;
  };

  const homeRowIsSelected = getIsEnabled('home');
  const opponentRowIsSelected = getIsEnabled('opponent');

  return (
    <RowHeaderContainer
      team={team}
      borderTop={row.id === 'filters'}
      isHighlighted={isRowHighlighted}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      isSelected={homeRowIsSelected || opponentRowIsSelected}
    >
      <RowScenariosContent style={{ flexDirection: isHomeTeamSelected ? 'row' : 'row-reverse' }}>
        <div
          className={classNames(styles.rowScenariosColumn, {
            [timelineStyles.isNotSelectedForPlaying]: opponentRowIsSelected,
          })}
          data-row-id={row.id}
          data-is-selected={homeRowIsSelected}
          data-row-type='home-team'
        >
          <TeamRowHighlight team={'home'} />
          <RowHeaderTotal>{homeTeamClips.length}</RowHeaderTotal>
          <RowHeaderContent title={t(row.title)}>
            {t(`${row.title}${isHomeTeamSelected ? '' : '-no-possession'}`)}
          </RowHeaderContent>
        </div>
        <VerticalSeparator height={`${TIMELINE_CONFIG.ROW_HEIGHT - 8}px`} />
        <div
          className={classNames(styles.rowScenariosColumn, {
            [timelineStyles.isNotSelectedForPlaying]: homeRowIsSelected,
          })}
          data-row-id={row.id}
          data-is-disabled={opponentRowIsSelected}
          data-row-type='opponent-team'
        >
          <TeamRowHighlight team={'opponent'} />
          <RowHeaderTotal>{opponentTeamClips.length}</RowHeaderTotal>
          <RowHeaderContent title={t(`${row.title}-no-possession`)}>
            {t(`${row.title}${!isHomeTeamSelected ? '' : '-no-possession'}`)}
          </RowHeaderContent>
        </div>
      </RowScenariosContent>
    </RowHeaderContainer>
  );
};
