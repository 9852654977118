import { Checkbox, Button, Grid, PopoverActions } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationCircle } from 'shared/components/notification-circle';

import styles from './FilterSelect.module.scss';
import { ButtonDropdown } from '../button-dropdown';

type FilterSelectOption = {
  disabled?: boolean;
  displayName: string;
  value: string;
};

export type FilterProps = {
  disabled?: boolean;
  displayName: string;
  invalid?: boolean;
  onUpdate: (values: string[]) => void;
  options: FilterSelectOption[];
  selectedValues?: string[];
};

export const FilterMultiSelect = ({
  disabled = false,
  displayName,
  invalid = false,
  onUpdate,
  options,
  selectedValues = [],
}: FilterProps) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(selectedValues);
  const [isOpen, setIsOpen] = useState(false);
  const actionRef = useRef<PopoverActions | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  useEffect(() => {
    setValues(selectedValues);
  }, [selectedValues]);

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setIsOpen(true);
    },
    [setAnchorEl, setIsOpen],
  );

  const handleClose = useCallback(() => {
    if (!anchorEl) return;

    onUpdate(values);
    setAnchorEl(undefined);
    setIsOpen(false);
  }, [setIsOpen, setAnchorEl, anchorEl, values, onUpdate]);

  const handleAddValue = useCallback(
    (option: string) => {
      setValues((values) => [...values, option]);
    },
    [setValues],
  );

  const handleRemoveValue = useCallback(
    (option: string) => {
      setValues((values) => values.filter((value) => value !== option));
    },
    [setValues],
  );

  const currentOptions = options.filter((option) => values.includes(option.value));
  const title =
    currentOptions && values?.length > 0 ? currentOptions.map((option) => option.displayName).join(', ') : displayName;

  return (
    <span className={styles.container} title={title}>
      <NotificationCircle variant='error' visible={invalid} />
      <ButtonDropdown
        onClick={handleButtonClick}
        title={title}
        isSelected={values?.length > 0}
        isOpen={isOpen}
        disabled={Boolean(disabled)}
      />

      {isOpen && anchorEl && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            action={actionRef}
            anchorEl={anchorEl}
            open
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={styles.menu}
          >
            <Grid container direction={'column'} spacing={1} className={styles.contentContainer}>
              <Grid item>
                {options.map((option) => (
                  <div
                    key={option.value}
                    className={classNames(styles.option, {
                      [styles.selected]: values.includes(option.value),
                      [styles.unselected]: !option.value,
                      [styles.disabled]: option.disabled || !option.value,
                      [styles.invalid]: option.disabled && values.includes(option.value),
                    })}
                    onClick={() => {
                      !values?.includes(option.value) ? handleAddValue(option.value) : handleRemoveValue(option.value);
                    }}
                  >
                    {option.value && <Checkbox checked={values.includes(option.value)} />}
                    {option.displayName}
                  </div>
                ))}
              </Grid>
              <Grid item container justifyContent={'flex-end'} spacing={1}>
                <Grid item>
                  <Button onClick={handleClose} variant='outlined' color='secondary'>
                    {t('common:actions.apply')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Menu>
        </ClickAwayListener>
      )}
    </span>
  );
};
