import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

import { KogniaUser, UsersApiResponse } from './use-kognia-users/types';
import { InfinityQueryDataResult } from '../../hooks/useInfinityQuery/types';

export const transformUsers = (response: UsersApiResponse): InfinityQueryDataResult<KogniaUser> => {
  return {
    data: {
      items: response.content,
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
