import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

import { KeypadEdit } from './components/keypad-edit';

// TODO: [refactor]: implement page state machine

export const KeypadEditPage = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.keypads', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <SidebarLayout>
      <Container>
        <KeypadEdit id={id} key={id} />
      </Container>
    </SidebarLayout>
  );
};
