import { Scenarios } from '../../../../../../shared/types/episodes/types';
import { Row } from '../types/row';

const SCENARIOS_ORDER: { [key in string]: number } = {
  [Scenarios.POSSESION_A]: 0,
  [Scenarios.POSSESION_BC]: 1,
  [Scenarios.POSSESION_D]: 2,
  [Scenarios.CHANGE_OF_POSSESSION]: 3,
};
export const sortScenarios = (prefix: string) => (a: Row, b: Row) => {
  const ida = a.id.replace(prefix, '');
  const idb = b.id.replace(prefix, '');

  const first = ida in SCENARIOS_ORDER ? SCENARIOS_ORDER[ida] : Number.MAX_SAFE_INTEGER;
  const second = idb in SCENARIOS_ORDER ? SCENARIOS_ORDER[idb] : Number.MAX_SAFE_INTEGER;

  let result = 0;
  if (first < second) result = -1;
  else if (first > second) result = 1;
  return result;
};
