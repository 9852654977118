import { useEffect, useRef } from 'react';

import { useVideoPlayerPlayingMode } from 'shared/components/video-player';

import { usePlayHeadShadowTime, usePlayHeadShadowVisibility } from './atoms';
import { useTacticalAnalysisEpisodes } from '../../../../hooks/use-tactical-analysis-episodes';
import { useTacticalAnalysisMatchSegments } from '../../../../hooks/use-tactical-analysis-match-segments';
import { useTimelineZoomLevel } from '../../../../hooks/use-timeline-zoom-level';
import { formattedTime } from '../../../../utils/formatted-time';
import { getPlayHeadPositionByTime } from '../../../../utils/get-play-head-position-by-time';
import { PlayHeadArrowDown, PlayHeadContent, PlayHeadTime, PlayHeadHeader } from '../PlayHead.styled';

type Props = {
  recordingId: string;
};

export const PlayHeadShadow = ({ recordingId }: Props) => {
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  const playHeadShadowContentDiv = useRef<HTMLDivElement>(null);
  const playHeadShadowVisibility = usePlayHeadShadowVisibility(recordingId);
  const shadowTime = usePlayHeadShadowTime(recordingId);
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const { zoomLevel } = useTimelineZoomLevel();
  const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();

  useEffect(() => {
    const leftPosition = getPlayHeadPositionByTime(shadowTime, effectiveTime, episodes, zoomLevel);

    requestAnimationFrame(() => {
      if (!playHeadShadowContentDiv.current) return;

      playHeadShadowContentDiv.current.style.transform = `translateX(${leftPosition}px)`;
    });
  }, [shadowTime]);

  return (
    <PlayHeadContent style={{ opacity: playHeadShadowVisibility ? 1 : 0 }} isShadow ref={playHeadShadowContentDiv}>
      <PlayHeadHeader>
        <PlayHeadArrowDown isShadow />
        <PlayHeadTime isShadow>{formattedTime(shadowTime, matchSegments)}</PlayHeadTime>
      </PlayHeadHeader>
    </PlayHeadContent>
  );
};
