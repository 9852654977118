import { ListItemText, Stack, Tooltip } from '@mui/material';

import { MenuItemTitle } from '../index';
import { MenuIcon } from '../styled';
import { ListItemTextStyles } from '../utils';

interface Props {
  item: MenuItemTitle;
  isSidebarOpen: boolean;
}

export const SidebarMenuItemTitle = ({ item, isSidebarOpen }: Props) => {
  return (
    <Stack
      direction='row'
      sx={{
        p: 1,
        maxWidth: '100%',
        width: '100%',
        overflow: 'hidden',
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Tooltip placement={'right'} title={isSidebarOpen ? '' : item.label}>
        <MenuIcon>{item.icon}</MenuIcon>
      </Tooltip>
      <ListItemText
        primary={item.label}
        sx={{ margin: 0 }}
        primaryTypographyProps={{ sx: { ...ListItemTextStyles, fontWeight: 'fontWeightMedium' } }}
      />
    </Stack>
  );
};
