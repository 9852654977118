import { Accordion, AccordionSummary } from '@mui/material';

import IconChevronDown from 'shared/components/icons/icon-chevron-down';
import { IconSizes } from 'shared/components/icons/svg-icon';

import { AnnotationsTask } from './components/annotations-task';
import { CreateTask } from './components/create-task';
import { DeleteEpisodeOverlays } from './components/delete-episode-overlays';
import { DeleteTask } from './components/delete-task';
import { LabelsTask } from './components/labels-task';
import { ProcessEpisode } from './components/process-episode';
import { ReprocessSync } from './components/reprocess-sync';
import { ReprocessThirdPartyEvents } from './components/reprocess-third-party-events';
import { ValidateTask } from './components/validate-task';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';

export const Annotation = () => {
  return (
    <BackofficeContentContainer>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>Create task</AccordionSummary>
        <CreateTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>Delete task</AccordionSummary>
        <DeleteTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>Validate Recording</AccordionSummary>
        <ValidateTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>Load annotations</AccordionSummary>
        <AnnotationsTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>Update Labels</AccordionSummary>
        <LabelsTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>Process Episode</AccordionSummary>
        <ProcessEpisode />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>
          Delete Episode Overlays
        </AccordionSummary>
        <DeleteEpisodeOverlays />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>
          Reprocess Third Party Tracking Sync
        </AccordionSummary>
        <ReprocessSync />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size={IconSizes.small} />}>
          Reprocess Third Party Events
        </AccordionSummary>
        <ReprocessThirdPartyEvents />
      </Accordion>
    </BackofficeContentContainer>
  );
};
