import { Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useTranslation } from 'react-i18next';

import IconTime from 'shared/components/icons/icon-time';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { Playlist } from 'shared/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

import { BadgeText } from './BadgetText';

type Props = {
  playlist: Playlist;
};

export const PlaylistDetails = ({ playlist }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack direction='row' spacing={1} alignItems={'center'}>
      <Typography fontSize={fontSizes.small}>{t('playlist-detail:all-playlist')}</Typography>
      <BadgeText>{t('playlist-detail:clips', { count: playlist.playlistItems.length })}</BadgeText>
      <BadgeText sx={{ paddingLeft: 0 }}>
        <IconTime size={IconSizes.small} color={IconColors.storm} /> {secondsAsDuration(playlist.duration, false)}
      </BadgeText>
    </Stack>
  );
};
