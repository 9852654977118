import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { clientBrandingUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Client } from 'shared/types';

import { BrandingApiResponse, BrandingRequestData } from '../../types/brandingRequestData';
import { transformClient } from '../../utils/transformClient';

export const useCreateBranding = () => {
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<BrandingApiResponse, Client>({
    type: HTTPMethod.POST,
    errorMessage: 'Error creating a branding',
    successMessage: 'Branding created successfully',
    transformer: transformClient,
  });

  const create = ({
    data,
    onSuccess,
    clientId,
  }: {
    data: BrandingRequestData;
    onSuccess?: (res: Client) => void;
    clientId: string;
  }) => {
    const formData = new FormData();
    formData.append('displayName', new Blob([data.displayName], { type: 'application/json' }));
    formData.append('favicon', data.favicon[0]);
    formData.append('emailLogo', data.emailLogo[0]);
    formData.append('navbarLogo', data.navbarLogo[0]);
    formData.append('navbarCollapsedLogo', data.navbarCollapsedLogo[0]);

    mutate({ url: clientBrandingUrl(clientId), data: formData }, { onSuccess: (res) => onSuccess && onSuccess(res) });
  };

  return { create, isLoading, isError, isSuccess };
};
