import { useCallback } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import {
  TimelineShowBallPossession,
  TimelineShowNoBallPossession,
} from 'shared/constants/user-presets/userPresetsClasses';

import { timelineMatchAtoms } from '../../atoms';

export const useSetTimelineShowBallPossession = (recordingId: string) => {
  const setShowBallPossession = useSetRecoilState(timelineMatchAtoms.showBallPossession(recordingId));
  const { savePreset } = useSaveUserPreset();

  return useCallback(
    (show: boolean, updatePreset = true) => {
      updatePreset && savePreset({ data: new TimelineShowBallPossession(show, recordingId) });
      setShowBallPossession(show);
    },
    [savePreset, setShowBallPossession, recordingId],
  );
};

export const useTimelineShowBallPossession = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.showBallPossession(recordingId));
};

export const useSetTimelineNoShowBallPossession = (recordingId: string) => {
  const setShowNoBallPossession = useSetRecoilState(timelineMatchAtoms.showNoBallPossession(recordingId));
  const { savePreset } = useSaveUserPreset();

  return useCallback(
    (show: boolean, updatePreset = true) => {
      updatePreset && savePreset({ data: new TimelineShowNoBallPossession(show, recordingId) });
      setShowNoBallPossession(show);
    },
    [savePreset, setShowNoBallPossession, recordingId],
  );
};

export const useTimelineShowNoBallPossession = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.showNoBallPossession(recordingId));
};
