import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useReprocessThirdPartyEvents } from '../../../../api/operations/use-reprocess-third-party-events';
import { reprocessThirdPartyEventsFormSchema } from '../../utils/form-fields-validation';
import {
  ReprocessThirdPartyEventsForm,
  ReprocessThirdPartyEventsFormSchema,
} from '../../utils/form-fields-validation/types';
import { AnnotationFormActions } from '../annotation-form-actions';
import { RecordingIdField } from '../form-fields/recording-id-field';

export const ReprocessThirdPartyEvents = () => {
  const { reprocessThirdPartyEvents } = useReprocessThirdPartyEvents();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<ReprocessThirdPartyEventsFormSchema>({
    resolver: zodResolver(reprocessThirdPartyEventsFormSchema),
  });

  const handleSubmit = useCallback(
    (data: ReprocessThirdPartyEventsForm) => {
      reprocessThirdPartyEvents({ data });
    },
    [reprocessThirdPartyEvents],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
