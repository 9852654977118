import find from 'lodash/find';

import { Episode } from 'shared/types/index';
import { RecordingsFilters } from 'shared/types/recording/types';
import { generateRandomUUID } from 'shared/utils/generateRandomUUID';

import { generateBlockContainerClip } from './generateBlockContainerClip';
import { getClipsForFilters } from './getClipsForFilters';
import { Row } from '../types/row';

export const generateFiltersRow = (
  episodes: Episode[],
  filterEpisodes: Episode[],
  appliedFilters: RecordingsFilters,
  recordingId: string,
): Row => {
  const rowId = `${recordingId}-filters`;
  const row: Row = generateBlockContainerClip({
    id: rowId,
    rowType: 'filters',
    timelineTableBlocks: episodes,
    title: 'timeline:filter-results',
    rowId: rowId,
    clipIdPrefix: rowId,
  });

  const filterUniqueId = generateRandomUUID();
  const title = `timeline:filter-result`;

  const totalOffensiveTactics = appliedFilters.scenariosOrTacticsInside?.tactics.offensive.filter(
    (t) => t.tacticalFundamentalType,
  ).length;
  const totalDefensiveTactics = appliedFilters.scenariosOrTacticsInside?.tactics.defensive.filter(
    (t) => t.tacticalFundamentalType,
  ).length;

  const areFundamentalsFiltered = Boolean((totalOffensiveTactics ?? 0) + (totalDefensiveTactics ?? 0));

  filterEpisodes.forEach((episode) => {
    const unfilteredEpisode = episodes.find((anEpisode) => anEpisode.id === episode.id);

    if (areFundamentalsFiltered) {
      const filterClips = getClipsForFilters({
        clips: episode.tacticalFundamentals,
        title,
        rowId,
      });

      const episodeClip =
        unfilteredEpisode &&
        find(
          row.clips,
          (clip) => unfilteredEpisode.startTime < clip.endTime && unfilteredEpisode.endTime > clip.startTime,
        );

      episodeClip?.clips?.push(...filterClips);
    }

    if (!areFundamentalsFiltered && episode.tacticalScenarios) {
      const startTime = Math.min(...episode.tacticalScenarios.map((scenario) => scenario.startTime));
      const endTime = Math.max(...episode.tacticalScenarios.map((scenario) => scenario.endTime));

      const episodeClip = find(row.clips, (clip) => {
        return clip.type !== 'not-effective-time' && startTime >= clip.startTime && startTime <= clip.endTime;
      });

      episodeClip?.clips?.push({
        id: `scenario-${startTime}-${endTime}-${filterUniqueId}`,
        startTime,
        endTime,
        type: 'filter',
        elementId: '',
        rowId: rowId,
        title,
      });
    }
  });

  return row;
};
