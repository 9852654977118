import { Box, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { SolutionCardVariants } from 'pages/home/types/solutionCardVariants';

import IconMatchAnalysis from './icons/icon-match-analysis';
import { IconPlaylists } from './icons/icon-playlists';
import IconTaggingTool from './icons/icon-tagging-tool';
import SolutionCard from './solution-card/SolutionCard';

const SolutionActionDescription = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  minHeight: '48px',
  padding: theme.spacing(1),
  borderRadius: '2px',
  backgroundColor: theme.palette.common.white,
  opacity: 0.6,
  lineHeight: '24px',
  textAlign: 'center',
  textTransform: 'uppercase',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
}));

const SolutionCardContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2),
}));

const Solutions = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent='center'>
      <Grid item justifyContent='center' display='flex' flexWrap='wrap'>
        <SolutionCardContainer>
          <SolutionCard variant={SolutionCardVariants.RECORDINGS} to={generatePath(routes.RECORDING_LIST)}>
            <h3>{t('home:solutions.tactical-analysis.title')}</h3>
            <IconMatchAnalysis />
            <SolutionActionDescription>{t('home:solutions.tactical-analysis.cta')}</SolutionActionDescription>
          </SolutionCard>
        </SolutionCardContainer>
      </Grid>

      <Grid item justifyContent='center' display='flex' flexWrap='wrap'>
        <SolutionCardContainer>
          <SolutionCard variant={SolutionCardVariants.TAGGING_TOOL} to={routes.TAGGING_TOOL}>
            <h3>{t('home:solutions.tagging.title')}</h3>
            <IconTaggingTool />
            <SolutionActionDescription>{t('home:solutions.tagging.cta.start-tagging')}</SolutionActionDescription>
          </SolutionCard>
        </SolutionCardContainer>

        <SolutionCardContainer>
          <SolutionCard variant={SolutionCardVariants.PLAYLISTS} to={routes.PLAYLISTS}>
            <h3>{t('home:solutions.playlists.title')}</h3>
            <IconPlaylists />
            <SolutionActionDescription>{t('home:solutions.playlists.cta')}</SolutionActionDescription>
          </SolutionCard>
        </SolutionCardContainer>
      </Grid>
    </Grid>
  );
};

export default Solutions;
