import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CardActions, IconButton, Stack, Tooltip } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { forwardRef, Ref, useCallback, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { FormInputField } from 'shared/components/form/form-input-field';
import IconDelete from 'shared/components/icons/icon-delete';
import { IconInfo } from 'shared/components/icons/icon-info';
import { IconSizes } from 'shared/components/icons/svg-icon';
import { ClientBranding } from 'shared/types/user/types';
import { getErrorByFieldName } from 'shared/utils/get-error-by-field-name/getErrorByFieldName';

import { BrandingPreviewImg } from './BrandingForm.styled';
import { BrandingFormDefaultValues } from './types/brandingFormDefaultValues';
import { BrandingFormField } from './types/brandingFormField';
import { BrandingFormRef } from './types/brandingFormRef';
import { BrandingFormValues } from './types/brandingFormValues';
import { BrandingOnSubmit } from './types/brandingOnSubmit';
import { BrandingFormLabels } from './utils/brandingFormLabels';
import { brandingFormRequiredSchema, brandingFormOptionalSchema } from './utils/brandingFormOptionalSchema';
import { generateBrandingFormFields } from './utils/generateBrandingFormFields';

interface BrandingFormProps {
  isLoading: boolean;
  onSubmit: (params: BrandingOnSubmit) => void;
  defaultValues?: BrandingFormDefaultValues;
  brandingAssetsUrls?: Partial<ClientBranding> | null;
}

export const BrandingForm = forwardRef(
  ({ isLoading, onSubmit, defaultValues, brandingAssetsUrls }: BrandingFormProps, ref?: Ref<BrandingFormRef>) => {
    const {
      reset,
      resetField,
      register,
      handleSubmit: handleFormSubmit,
      formState: { errors, isDirty },
    } = useForm<BrandingFormValues>({
      resolver: zodResolver(defaultValues ? brandingFormOptionalSchema : brandingFormRequiredSchema),
      defaultValues,
    });

    useImperativeHandle(
      ref,
      () => ({
        reset,
      }),
      [reset],
    );

    const handleOnFormSubmit = useCallback(
      (data: BrandingFormValues) => {
        onSubmit({
          data,
          formState: {
            isBrandingDirty: isDirty,
            isBrandingEmpty: isEmpty(Object.values(data).filter((item) => !isEmpty(item))),
          },
        });
      },
      [isDirty, onSubmit],
    );

    const formFieldRender = (field: BrandingFormField) => (
      <FormInputField
        id={field.id}
        label={BrandingFormLabels[field.id]}
        error={!!getErrorByFieldName(errors, field.id)}
        fullWidth={field.fullWidth}
        placeholder={field.placeholder}
        type={field.type}
        helperText={<ErrorMessage errors={errors} name={field.id} />}
        {...register(field.id)}
      />
    );

    const formFieldsRender = generateBrandingFormFields({ previewUrls: brandingAssetsUrls }).map((field) => {
      return (
        <Stack key={field.id} direction='row' alignItems='center' spacing={1} justifyContent='space-between'>
          <Box sx={{ flexGrow: 1 }}>{formFieldRender(field)}</Box>
          {field.showClearButton ? (
            <IconButton onClick={() => resetField(field.id)}>
              <IconDelete size={IconSizes.small} />
            </IconButton>
          ) : null}

          {field.previewUrl ? (
            <Tooltip placement={'left'} title={<BrandingPreviewImg src={field.previewUrl} />}>
              <Box>
                <IconInfo size={IconSizes.small} />
              </Box>
            </Tooltip>
          ) : null}
        </Stack>
      );
    });

    return (
      <form onSubmit={handleFormSubmit(handleOnFormSubmit)}>
        <Stack direction='column'>
          {formFieldsRender}

          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={() => reset()}>Reset</Button>
            <LoadingButton
              color={'primary'}
              loading={isLoading}
              size={'large'}
              type={'submit'}
              variant={'contained'}
              disabled={!isDirty}
            >
              Send
            </LoadingButton>
          </CardActions>
        </Stack>
      </form>
    );
  },
);

BrandingForm.displayName = 'BrandingForm';
