import { Box, Stack } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import Toggle from 'shared/components/toggle';
import { useCurrentPlaylistItem, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import {
  EPISODES_PLAYING_MODE,
  getPlayingMode,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlayingModes } from 'shared/components/video-player/types';

import { useTimelinePlayingMode } from '../../../../hooks/use-timeline-playing-mode';
import { TIMELINE_CONFIG } from '../../config';

export const PlayingModeToggle = (): JSX.Element => {
  const { t } = useTranslation();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const playingMode = useVideoPlayerPlayingMode();
  const { setPlayingMode } = useTimelinePlayingMode(currentPlaylistItem.recordingId);
  const handleToggle = useCallback(() => {
    if (currentPlaylistItem.hasHomographies) {
      return setPlayingMode(
        getPlayingMode({
          playingMode,
          useEffectiveTime: !playingMode.useEffectiveTime,
        }),
      );
    }

    return setPlayingMode(
      playingMode.mode === PlayingModes.EPISODES
        ? TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE
        : EPISODES_PLAYING_MODE,
    );
  }, [currentPlaylistItem.hasHomographies, setPlayingMode, playingMode]);

  return (
    <Box
      sx={({ palette, spacing }) => ({
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: `${TIMELINE_CONFIG.STICKY_HEADER_HEIGHT / 2}px`,
        padding: spacing(0, 1),
        color: 'text.primary',
        borderBottom: `1px solid ${palette.secondary.main}`,
        fontSize: fontSizes.small,
        // TODO get from theme
        lineHeight: 1.67,
      })}
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <span>{t('timeline:playing-mode-toggle.title')}</span>
        <Toggle checked={playingMode.useEffectiveTime} onClick={handleToggle} />
      </Stack>
    </Box>
  );
};
