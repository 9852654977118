import { Box, Tab, Tabs } from '@mui/material';
import pickBy from 'lodash/pickBy';
import React, { useCallback, useMemo, useState } from 'react';

import { getFiltersFromUrl } from 'api/recording/useFetchRecordings/util/get-initial-filters';
import { FiltersList } from 'shared/types/filters/types';

import { TabType } from '../../../RecordingsListPageContainer';
import { RecordingFilters } from '../../../types';
import Filters from '../filters-bar/filters';
import { ApplyFilterPayloadType } from '../filters-bar/hooks/useRecordingsFilters/reducer';

interface Props {
  appliedFilters: FiltersList;
  applyFilters: (payload: ApplyFilterPayloadType) => void;
  competitionsTabs: TabType[];
  filters: FiltersList;
  removeFilter: (payload: string) => void;
}

export const selectInitialCompetitionIndex = (competitionsTabs: TabType[]) => {
  const urlFilters = getFiltersFromUrl();
  if (urlFilters.competition) {
    const competitionTabIndex = competitionsTabs.findIndex(
      (competitionsTab) => competitionsTab.value[0] === urlFilters.competition,
    );

    if (competitionTabIndex >= 0) return competitionTabIndex;
  }

  if (competitionsTabs.length === 1) {
    return 0;
  }

  return 0;
};

export const RecordingTypesTabsAndFilters = ({
  appliedFilters,
  applyFilters,
  competitionsTabs,
  filters,
  removeFilter,
}: Props) => {
  const [tab, setTab] = useState<number>(selectInitialCompetitionIndex(competitionsTabs));

  const selectedCompetition = useMemo(() => competitionsTabs[tab], [tab, competitionsTabs]);

  const generateFiltersList = useCallback(
    () =>
      pickBy(!appliedFilters ? filters : appliedFilters, (_filter, key) => {
        if (selectedCompetition.type === RecordingFilters.TYPE) {
          return key === RecordingFilters.DATE;
        }

        return (
          key !== RecordingFilters.ANNOTATION_TYPE &&
          key !== RecordingFilters.COMPETITION &&
          key !== RecordingFilters.TYPE
        );
      }),
    [filters, selectedCompetition, appliedFilters],
  );

  const handleTabChange = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue);
      const selectedTab = competitionsTabs[newValue];

      if (selectedTab.type === RecordingFilters.TYPE) {
        removeFilter(RecordingFilters.COMPETITION);
      }

      if (selectedTab.type === RecordingFilters.COMPETITION) {
        removeFilter(RecordingFilters.TYPE);
      }

      removeFilter(RecordingFilters.DATE);
      removeFilter(RecordingFilters.MATCHDAY);
      removeFilter(RecordingFilters.COMPETITION);
      removeFilter(RecordingFilters.TEAM);

      applyFilters({ key: selectedTab.type, selectedFilters: selectedTab.value });
    },
    [competitionsTabs, applyFilters, removeFilter],
  );

  return (
    <>
      <Box>
        <Tabs
          value={tab}
          variant='scrollable'
          onChange={handleTabChange}
          sx={{
            marginTop: 4,
            marginBottom: 4,
            borderBottom: '1px solid #CECED9',
          }}
          scrollButtons
          aria-label='scrollable tabs'
        >
          {competitionsTabs.map((type, idx) => (
            <Tab sx={{ textTransform: 'none' }} key={type.label} label={type.label} value={idx} />
          ))}
        </Tabs>
      </Box>
      <Filters applyFilters={applyFilters} filtersList={generateFiltersList()} />
    </>
  );
};
