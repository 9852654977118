import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useLiveSessionsWithFilters } from 'api/tagging-tool/use-live-sessions-with-filters';
import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

import { TaggingRecordingList } from './components/tagging-recording-list';
import { RecordingListPageSkeleton } from '../recordings-list/components/recording-list-page-skeleton';

export const TaggingToolRecordingList = () => {
  const { t } = useTranslation();
  const { isLoading, isSuccess } = useLiveSessionsWithFilters();

  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.tagging', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  if (isLoading) return <RecordingListPageSkeleton />;

  return (
    <SidebarLayout>
      <Container>{isSuccess && <TaggingRecordingList />}</Container>
    </SidebarLayout>
  );
};
