import { Button, Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { AddMultipleClips } from 'pages/playlist-detail/components/add-multiple-clips';
import { usePlaylistItems, useVideoPlayerActions } from 'shared/components/video-player';

import { useIsBulkModeActive, useSetIsBulkModeActive } from '../hooks/useIsBulkModeActive';

type Props = {
  playlistId: string;
};
export const PlaylistActions = ({ playlistId }: Props) => {
  const playingItems = usePlaylistItems();
  const actions = useVideoPlayerActions();
  const enabledBulkMode = useIsBulkModeActive(playlistId);
  const setEnabledBulkMode = useSetIsBulkModeActive(playlistId);
  const { t } = useTranslation();

  const handleSelectClick = () => {
    setEnabledBulkMode(!enabledBulkMode);

    actions.pause();
  };

  return (
    <Stack direction='row' spacing={1}>
      {!isEmpty(playingItems) ? (
        <Button size='small' variant='outlined' color='secondary' onClick={handleSelectClick}>
          {t('common:actions.select')}
        </Button>
      ) : null}

      <AddMultipleClips key={playlistId} playlistId={playlistId} />
    </Stack>
  );
};
