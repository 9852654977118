import { Box, Button, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FiltersBarSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-bar-summary';
import FiltersModal from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-modal';
import IconClose from 'shared/components/icons/icon-close';
import IconFilter from 'shared/components/icons/icon-filter';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { useVideoPlayerActions } from 'shared/components/video-player/hooks';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { Episode } from 'shared/types';
import { MetricsNames } from 'shared/types/metrics';
import { RecordingsFilters } from 'shared/types/recording/types';
import { MatchTeams } from 'shared/types/teams/types';

import { useSelectionPlaying } from '../../../hooks/use-selection-playing';
import {
  useSetTacticalAnalysisAppliedFilters,
  useTacticalAnalysisAppliedFilters,
} from '../../../hooks/use-tactical-analysis-applied-filters';
import {
  useSetTacticalAnalysisFiltersResults,
  useTacticalAnalysisClearFilters,
} from '../../../hooks/use-tactical-analysis-filters-results';
import { useTacticalAnalysisMode } from '../../../hooks/use-tactical-analysis-mode';
import { TacticalAnalysisPlayingMode } from '../../../types/tactical-analysis-playing-mode';

export const defaultFilters = {
  recordingIds: [],
};

interface Props {
  episodeCount: number;
  episodes: Episode[];
  recordingId: string;
  teams: MatchTeams;
  hasTeamFocus?: boolean;
}

const FiltersContainer = styled(Box)(() => ({
  fontSize: fontSizes.small,
  marginRight: '16px',
  paddingRight: '16px',
  position: 'relative',
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
}));

const Filters = ({ episodes, teams, recordingId, episodeCount }: Props) => {
  const [summaryAnchor, setFiltersSummaryAnchor] = useState<HTMLElement | null>(null);
  const { t } = useTranslation();
  const actions = useVideoPlayerActions();
  const [areFiltersOpen, setAreFiltersOpen] = useState(false);
  const { appliedFilters } = useTacticalAnalysisAppliedFilters(recordingId);
  const setAppliedFilters = useSetTacticalAnalysisAppliedFilters(recordingId);
  const clearFilters = useTacticalAnalysisClearFilters(recordingId);
  const setTacticalAnalysisFiltersResults = useSetTacticalAnalysisFiltersResults(recordingId);

  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);
  const { playAll } = useSelectionPlaying(recordingId);

  const toggleOpenFilters = useCallback(() => {
    if (!areFiltersOpen) {
      publishEvent({
        type: ActionTypes.OPEN_TACTICAL_ANALYSIS_FILTER,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_OPEN_FILTER },
      });
      actions.handleStandBy();
    } else {
      actions.resumeStandBy();
    }
    setAreFiltersOpen(!areFiltersOpen);
  }, [areFiltersOpen, actions]);

  const handleCloseFilters = useCallback(() => {
    actions.resumeStandBy();
    setAreFiltersOpen(false);
  }, [actions, setAreFiltersOpen]);

  const handleClearFilters = useCallback(() => {
    if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection) playAll();
    actions.resumeStandBy();
    actions.pause();
    clearFilters();
  }, [actions, clearFilters, playAll, tacticalAnalysisMode]);

  const handleApplyFilters = useCallback(
    (appliedFilters: RecordingsFilters, episodes: Episode[]) => {
      // Order is important as applied filters need to be set before the results
      const filtersWithRecordingId = { ...appliedFilters, recordingIds: [recordingId] };

      setAppliedFilters(filtersWithRecordingId);
      setTacticalAnalysisFiltersResults(episodes, filtersWithRecordingId);
      handleCloseFilters();
    },
    [recordingId, handleCloseFilters, setAppliedFilters, setTacticalAnalysisFiltersResults],
  );

  const handleOpenSummary = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setFiltersSummaryAnchor(event.currentTarget);
  }, []);

  const handleCloseSummary = useCallback(() => {
    setFiltersSummaryAnchor(null);
  }, []);

  const showFiltersApplied = Boolean(
    appliedFilters.eventsEnding || appliedFilters.eventsStarting || appliedFilters.scenariosOrTacticsInside,
  );

  return (
    <FiltersContainer>
      <Button
        startIcon={<IconFilter color={IconColors.night} size={IconSizes.small} />}
        onMouseOver={handleOpenSummary}
        onMouseLeave={handleCloseSummary}
        onClick={toggleOpenFilters}
        color='inherit'
      >
        {t('timeline:filter-by')}
      </Button>
      <FiltersBarSummary
        hasTeamFocus={true}
        recordingId={recordingId}
        filters={appliedFilters}
        showFiltersApplied={showFiltersApplied}
        summaryAnchor={summaryAnchor}
        teams={teams}
      />

      {showFiltersApplied && (
        <Button
          variant='outlined'
          size='small'
          startIcon={<IconClose size={IconSizes.small} color={IconColors.primary} />}
          onClick={handleClearFilters}
          sx={{ ml: 1, whiteSpace: 'nowrap', borderRadius: 4 }}
        >
          {t('recordings:filters.clear-filters')}
        </Button>
      )}
      {areFiltersOpen ? (
        <FiltersModal
          appliedFilters={appliedFilters}
          episodeCount={episodeCount}
          episodes={episodes}
          isOpen={areFiltersOpen}
          onApply={handleApplyFilters}
          onClose={handleCloseFilters}
          recordingId={recordingId}
          teams={teams}
        />
      ) : null}
    </FiltersContainer>
  );
};

export default Filters;
