import { Homographies, TimeSeries } from "../main";

export const transformTimeSeries = (timeSeries: TimeSeries) => {
  const homographies: Homographies = {};

  for (const [frame, series] of Object.entries(timeSeries)) {
    homographies[Number(frame)] = [
      [series[1], series[2], series[3]],
      [series[4], series[6], series[7]],
      [series[8], series[9], series[10]],
    ];
  }

  return homographies;
};
