import React from 'react';

import { useTimelineTableData } from '../../../../../hooks/use-timeline-table-data';
import { hasFiltersResults } from '../../../../../utils/has-filters-results';
import { RowContent } from '../row-content';

export const FiltersContentRow = ({ recordingId }: { recordingId: string }) => {
  const timelineTableData = useTimelineTableData(recordingId);

  if (!timelineTableData.filtersRow || !hasFiltersResults(timelineTableData.filtersRow)) return null;

  return (
    <RowContent
      rowId={timelineTableData.filtersRow.id}
      clips={timelineTableData.filtersRow.clips}
      recordingId={recordingId}
    />
  );
};
