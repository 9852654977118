import React from 'react';

import { useTimelineTableData } from '../../../../../hooks/use-timeline-table-data';
import { hasFiltersResults } from '../../../../../utils/has-filters-results';
import { RowHeader } from '../row-header';

export const FiltersHeaderRow = ({ recordingId }: { recordingId: string }) => {
  const timelineTableData = useTimelineTableData(recordingId);

  if (!timelineTableData.filtersRow || !hasFiltersResults(timelineTableData.filtersRow)) return null;

  return <RowHeader recordingId={recordingId} row={timelineTableData.filtersRow} />;
};
