import classNames from 'classnames';
import { CSSProperties, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import styles from 'pages/tactical-analysis/components/timeline/timeline-table/TimelineTable.module.scss';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

import { clipAtomFamily } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { useTeamUtils } from '../../../../../hooks/use-timeline-team-id-focus';
import { TacticActionType } from '../../../../../types';
import { CSS_CLIP_ZOOM_WIDTH } from '../timeline-css-variables';

type DefaultClipProps = {
  clip: Clip;
  recordingId: string;
  parentClip: Clip;
  isSelected?: boolean;
  borderStyle?: 'dotted' | 'solid';
};

export const SelectableClip = ({
  clip,
  recordingId,
  parentClip,
  borderStyle = 'solid',
  isSelected,
}: DefaultClipProps) => {
  const clipWithSelection = useRecoilValue(clipAtomFamily(clip.id));
  const { isHomeTeam, isOpponentTeam } = useTeamUtils(recordingId);

  const clipStyle: CSSProperties = useMemo(
    () => ({
      left: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.startTime - parentClip.startTime})`,
      width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
    }),
    [clip, parentClip],
  );

  const className = useMemo(() => {
    const isHome = isHomeTeam(clip.teamId ?? '');
    const isOpponent = isOpponentTeam(clip.teamId ?? '');
    const shouldReverseHomeTeam =
      isOpponent &&
      (clip.type === 'tactic' || clip.type === 'parent-clip') &&
      clip.action === TacticActionType.DEFENSIVE;
    const shouldReverseOpponentTeam =
      isHome && (clip.type === 'tactic' || clip.type === 'parent-clip') && clip.action === TacticActionType.DEFENSIVE;

    return classNames(styles.clip, {
      [styles.isSelected]: isSelected !== undefined ? isSelected : clipWithSelection.isSelected,
      [styles.isSelectedForPlaying]: clipWithSelection.isSelectedForPlaying,
      [styles.isHomeTeam]: shouldReverseHomeTeam || (isHome && !shouldReverseOpponentTeam),
      [styles.isOpponentTeam]: shouldReverseOpponentTeam || (isOpponent && !shouldReverseHomeTeam),
      [styles.isFilter]: clip.rowId.includes('filters'),
      [styles.isOffensive]: clip.rowId.includes(TypeOfPlay.Offense),
      [styles.isDefensive]: clip.rowId.includes(TypeOfPlay.Defense),
      [styles.isTransition]: clip.rowId.includes(TypeOfPlay.Transition),
      [styles.isImported]: clip.rowId.includes(TypeOfPlay.imported),
      [styles.borderSolid]: borderStyle === 'solid',
      [styles.borderDotted]: borderStyle === 'dotted',
    });
  }, [
    borderStyle,
    clip.action,
    clip.rowId,
    clip.teamId,
    clip.type,
    clipWithSelection.isSelected,
    clipWithSelection.isSelectedForPlaying,
    isHomeTeam,
    isOpponentTeam,
    isSelected,
  ]);

  return (
    <div
      id={clip.id}
      data-clip-id={clip.id}
      data-clip-start-time={clip.startTime}
      data-is-selected={clipWithSelection.isSelectedForPlaying}
      className={className}
      style={clipStyle}
    />
  );
};
