import { IconButton, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Keypad, KeypadSimple, KeypadTag } from 'api/tagging-tool/types';
import { Dot } from 'shared/components/dot';
import IconChevronDown from 'shared/components/icons/icon-chevron-down';
import IconClose from 'shared/components/icons/icon-close';
import { IconSizes } from 'shared/components/icons/svg-icon';
import KebabMenu from 'shared/components/kebab-menu';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

import { Section } from './components/keypad-section';
import {
  KeypadPanelContainer,
  KeypadPanelCustomButtons,
  KeypadPanelCustomButtonsColumn,
  KeypadPanelCustomButtonsContent,
  KeypadPanelSections,
  KeypadsPanelKickOffTags,
  KeypadsPanelTitleContainer,
  KeypadsPanelTitlesContainer,
  KeypadsPanelTopBar,
} from './styled';
import { KeypadIndex } from '../../../../tagging-tool/model/Keypad';
import { TagButton } from '../tag-button/TagButton';

enum KeypadTagType {
  OFFENSE = 'offense',
  DEFENSE = 'defense',
  TRANSITION = 'transition',
}

type Data = {
  index: KeypadIndex;
  [KeypadTagType.OFFENSE]: KeypadTag[];
  [KeypadTagType.TRANSITION]: KeypadTag[];
  [KeypadTagType.DEFENSE]: KeypadTag[];
};

type Props = {
  children?: React.ReactNode;
  keypads: KeypadSimple[];
  keypad: Keypad;
  onKeypadTag: (time: number) => (tag: KeypadTag) => void;
  onClose: () => void;
  onChangeKeypad: (keypad: KeypadSimple) => void;
  loadingKeypad?: boolean;
  videoStartRecordingTime?: number;
  disabled?: boolean;
};

export const KeypadsPanel = (props: Props) => {
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const { keypad, videoStartRecordingTime } = props;

  const { t } = useTranslation();

  const data = useMemo<Data>(() => {
    const { offense, transition, defense } = keypad.tags.reduce(
      (acc, item) => {
        if (item.isCustom !== true) {
          return acc;
        } else if (item.typeOfPlay === TypeOfPlay.Offense) {
          return { ...acc, offense: [...acc.offense, item] };
        } else if (item.typeOfPlay === TypeOfPlay.Transition) {
          return { ...acc, transition: [...acc.transition, item] };
        } else if (item.typeOfPlay === TypeOfPlay.Defense) {
          return { ...acc, defense: [...acc.defense, item] };
        }
        return acc;
      },
      { offense: [], transition: [], defense: [] } as Omit<Data, 'index'>,
    );

    const index = keypad.tags.reduce((acc, keypad) => {
      return { ...acc, [keypad.keypadTagId]: keypad };
    }, {} as KeypadIndex);

    return { index, offense, transition, defense };
  }, [keypad]);
  const hasCustomButtons = data.offense.length > 0 || data.transition.length > 0 || data.defense.length > 0;

  if (!keypad) return null;

  const menuOptions = useMemo(() => {
    return props.keypads.map((kp) => {
      const isCurrent = props.keypad.id === kp.id;
      return {
        selected: isCurrent,
        displayText: (
          <Typography fontWeight={isCurrent ? 'fontWeightMedium' : 'fontWeightRegular'}>{kp.name}</Typography>
        ),
        onClick: isCurrent
          ? undefined
          : () => {
              props.onChangeKeypad(kp);
            },
      };
    });
  }, [props]);

  const getCustomButtons = useMemo(
    () => (type: KeypadTagType) =>
      data[type].map((tag) => {
        return (
          <TagButton
            videoStartRecordingTime={videoStartRecordingTime}
            onPress={props.onKeypadTag}
            width='full'
            key={tag.keypadTagId}
            data={tag}
          />
        );
      }),
    [data, props.onKeypadTag, videoStartRecordingTime],
  );

  return (
    <KeypadPanelContainer disabled={props.disabled}>
      <KeypadsPanelTopBar>
        <KebabMenu
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
          triggerComponent={
            <>
              <Typography
                color='text.primary'
                textTransform='uppercase'
                fontWeight='fontWeightMedium'
                fontSize={fontSizes.small}
                marginRight={1}
              >
                {keypad.name}
              </Typography>
              <IconChevronDown size={IconSizes.xsmall} />
            </>
          }
          ref={kebabRef}
          options={menuOptions}
          id={'keypads-list'}
        />
        <IconButton onClick={props.onClose}>
          <IconClose size={IconSizes.small} />
        </IconButton>
      </KeypadsPanelTopBar>
      {/* kick off buttons */}
      <KeypadsPanelKickOffTags>
        <TagButton
          videoStartRecordingTime={videoStartRecordingTime}
          onPress={props.onKeypadTag}
          data={data.index['EVENT_KICK_OFF']}
        />
        <TagButton
          videoStartRecordingTime={videoStartRecordingTime}
          onPress={props.onKeypadTag}
          data={data.index['EVENT_SECOND_HALF']}
        />
      </KeypadsPanelKickOffTags>
      {/* offense / defense */}
      <KeypadsPanelTitlesContainer>
        <KeypadsPanelTitleContainer>
          <Dot color={Colors.pastel} />
          <Typography fontWeight='fontWeightMedium'>{t('tagging-tool:keypad.offense')}</Typography>
        </KeypadsPanelTitleContainer>
        <KeypadsPanelTitleContainer>
          <Dot color={Colors.fountain} />
          <Typography fontWeight='fontWeightMedium'>{t('tagging-tool:keypad.defense')}</Typography>
        </KeypadsPanelTitleContainer>
      </KeypadsPanelTitlesContainer>
      {/* sections */}
      <KeypadPanelSections>
        <Section title={t('tagging-tool:recording-keypad.corners')}>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_L_CORNER']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_R_CORNER']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_L_CORNER']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_R_CORNER']}
              />
            </Section.Col>
          </Section.Row>
        </Section>
        <Section title={t('tagging-tool:recording-keypad.faults-and-penalties')}>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_DIRECT_FAULT']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_INDIRECT_FAULT']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_DIRECT_FAULT']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_INDIRECT_FAULT']}
              />
            </Section.Col>
          </Section.Row>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_PENALTY']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_PENALTY']}
              />
            </Section.Col>
          </Section.Row>
        </Section>
        <Section title={t('tagging-tool:recording-keypad.crosses')}>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_L_CROSS']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_R_CROSS']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_L_CROSS']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_R_CROSS']}
              />
            </Section.Col>
          </Section.Row>
        </Section>
        <Section title={t('tagging-tool:recording-keypad.plays')}>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_GOAL_FAVOR']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_GOAL_CHANCE']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_GOAL_AGAINST']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_GOAL_CHANCE']}
              />
            </Section.Col>
          </Section.Row>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_FINALIZATION']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_FINALIZATION']}
              />
            </Section.Col>
          </Section.Row>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_OFFENSE']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_DEFENSE']}
              />
            </Section.Col>
          </Section.Row>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_GK_SHORT']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_GK_LONG']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_HIGH_PRESS']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_GK_LONG']}
              />
            </Section.Col>
          </Section.Row>
        </Section>
        <Section title={t('tagging-tool:recording-keypad.transitions')}>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['TRANSITION_BALL_RECOVERY']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['TRANSITION_LOST_BALL']}
              />
            </Section.Col>
          </Section.Row>
        </Section>
        <Section title={t('tagging-tool:recording-keypad.throwIn')}>
          <Section.Row>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_THROW_IN_L']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['OFFENSE_THROW_IN_R']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_THROW_IN_L']}
              />
            </Section.Col>
            <Section.Col>
              <TagButton
                videoStartRecordingTime={videoStartRecordingTime}
                onPress={props.onKeypadTag}
                data={data.index['DEFENSE_THROW_IN_R']}
              />
            </Section.Col>
          </Section.Row>
        </Section>
      </KeypadPanelSections>
      {/* custom buttons */}
      {hasCustomButtons && (
        <KeypadPanelCustomButtons>
          <Section.Title title={t('tagging-tool:recording-keypad.custom-buttons')} />
          <KeypadPanelCustomButtonsContent>
            <KeypadPanelCustomButtonsColumn>{getCustomButtons(KeypadTagType.OFFENSE)}</KeypadPanelCustomButtonsColumn>
            <KeypadPanelCustomButtonsColumn>
              {getCustomButtons(KeypadTagType.TRANSITION)}
            </KeypadPanelCustomButtonsColumn>
            <KeypadPanelCustomButtonsColumn>{getCustomButtons(KeypadTagType.DEFENSE)}</KeypadPanelCustomButtonsColumn>
          </KeypadPanelCustomButtonsContent>
        </KeypadPanelCustomButtons>
      )}
    </KeypadPanelContainer>
  );
};
