import { UseQueryResult } from '@tanstack/react-query';
import forEach from 'lodash/forEach';

import { MetadataGame, MetadataGamePlayer } from '../../../../../../../types/games';
import { MetadataPlayerSummary } from '../../../../../../../types/players';
import { TeamWithPlayers } from '../../../../../../../types/teams';
import { transformGamePlayer } from '../../../../utils';
import { getJerseyNumber } from '../../../selected-players';

interface GetLatestPlayersFromGames {
  team?: TeamWithPlayers;
  games?: UseQueryResult<MetadataGame>[];
}

export const getLatestPlayersFromGames = ({
  team,
  games,
}: GetLatestPlayersFromGames): MetadataPlayerSummary[] | undefined => {
  if (!team || !games) return;

  const latestUniquePlayersFromGames: Record<string, MetadataPlayerSummary> = games.reduce<
    Record<string, MetadataPlayerSummary>
  >((acc, game) => {
    if (!game.data) return acc;

    const gamePlayers: MetadataPlayerSummary[] = game.data.players
      .filter((p: MetadataGamePlayer) => p.teamId === team.id)
      .map((p: MetadataGamePlayer) => transformGamePlayer(p, team, game.data));

    forEach(gamePlayers, (player) => {
      const playerFixturesDateInAcc = acc[player.id]?.fixtures?.find((item) => item.date);
      const playerFixturesDateInPlayer = player.fixtures?.find((item) => item.date);
      if (
        !acc[player.id] ||
        (acc[player.id] &&
          playerFixturesDateInAcc &&
          playerFixturesDateInPlayer &&
          Date.parse(playerFixturesDateInAcc.date) < Date.parse(playerFixturesDateInPlayer.date))
      ) {
        acc[player.id] = player;
      }
    });

    return acc;
  }, {});
  
  const sortByJerseyNumber = (a: MetadataPlayerSummary, b: MetadataPlayerSummary) => getJerseyNumber(a) - getJerseyNumber(b);
  return Object.values(latestUniquePlayersFromGames).sort(sortByJerseyNumber);
};
