import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

import { BASIC_ROW_HEIGHT } from '../TimelineTable.styled';

export const RowContentContainerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted' && prop !== 'isBorderTop',
})<{ isHighlighted?: boolean; isBorderTop: boolean }>(({ theme, isHighlighted, isBorderTop }) => ({
  display: 'flex',
  width: '100%',
  height: BASIC_ROW_HEIGHT,
  padding: theme.spacing(0, 3),
  background: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeOut,
    duration: '0.1s',
  }),
  userSelect: 'none',

  ...(isHighlighted && {
    // TODO use from theme
    background: Colors.background,
  }),

  ...(isBorderTop && {
    borderBottom: 0,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  }),
}));
