import { Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import { DraggableProvided, DraggableRubric, DraggableStateSnapshot, DroppableProvided } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { useDeletePlaylistItems } from 'api/playlist/useDeletePlaylistItems';
import { useDuplicatePlaylistItems } from 'api/playlist/useDuplicatePlaylistItems';
import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { useUpdatePlaylistItemOrder } from 'api/playlist/useUpdatePlaylistItemOrder';
import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { DownloadPlaylistItemsModal } from 'domain/playlist/components/download-modal/DownloadPlaylistItemsModal';
import { ItemsListBulk } from 'domain/playlist/components/ItemsListBulk';
import { ItemsListContainer } from 'domain/playlist/components/ItemsListContainer';
import { playlistItemHeight } from 'domain/playlist/components/ItemWrapper';
import { PlaylistItemsEmpty } from 'domain/playlist/components/PlaylistItemsEmpty';
import { PlaylistBulkButton } from 'features/editable-playlist/components/PlaylistBulkButton';
import { TrimModal } from 'features/editable-playlist/components/trim-modal/TrimModal';
import { useMapVideos } from 'pages/playlist-detail/util/map-videos';
import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';
import IconClose from 'shared/components/icons/icon-close';
import IconCopy from 'shared/components/icons/icon-copy';
import { IconCopyTo } from 'shared/components/icons/icon-copy-to';
import IconDelete from 'shared/components/icons/icon-delete';
import { IconDownload } from 'shared/components/icons/icon-download';
import IconTrim from 'shared/components/icons/icon-trim';
import SortableItemList from 'shared/components/sortable-item-list';
import { SortableItem } from 'shared/components/sortable-item-list/sortable-item';
import { usePlaylistItems, useVideoPlayerActions } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { useFeatureFlag } from 'shared/contexts/app-state';
import { Playlist } from 'shared/types';

import { CopyTo } from './playlist-item/components/CopyTo';
import { PlaylistItem } from './playlist-item/PlaylistItem';
import { useBulkSelectedItems, useSetBulkSelectedItems } from '../hooks/useBulkSelectedItems';

const PLAYLIST_ITEM_WIDTH = 152;
export const PLAYLIST_ITEM_GAP = 4;
export const PLAYLIST_ITEM_SIZE = PLAYLIST_ITEM_WIDTH + PLAYLIST_ITEM_GAP * 2;

interface Props {
  onPlaylistOrderChange?: (currentVideoIndex: number, newVideoIndex: number) => void;
  playlist: Playlist;
  setTrimmingPlaylistItem: (videoId: string) => void;
  trimmingPlaylistItemId?: string;
  enabledBulkMode?: boolean;
  setEnabledBulkMode?: (value: boolean) => void;
}

interface RowProps {
  data: PlaylistItemType[];
  index: number;
  style: CSSProperties;
}

export const EditablePlaylistItemsList = ({
  playlist,
  setTrimmingPlaylistItem,
  trimmingPlaylistItemId,
  enabledBulkMode = false,
  setEnabledBulkMode = () => {},
}: Props) => {
  const { t } = useTranslation();
  const mapVideos = useMapVideos();
  const selectedItems = useBulkSelectedItems(playlist.id);
  const setSelectedItems = useSetBulkSelectedItems(playlist.id);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [trimModalOpen, setTrimModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [copyToModalOpen, setCopyToModalOpen] = useState(false);
  const playlistItems = usePlaylistItems();
  const downloadPlaylistFeatureFlag = useFeatureFlag(FEATURE_FLAG.DOWNLOAD_PLAYLIST);
  const { updatePlaylistItemOrder } = useUpdatePlaylistItemOrder(playlist.id);
  const { duplicatePlaylistItems } = useDuplicatePlaylistItems(playlist.id);
  const { deletePlaylistItems } = useDeletePlaylistItems(playlist.id);
  const downloadRef = useRef<HTMLDivElement | null>(null);
  const copyToRef = useRef<HTMLDivElement | null>(null);
  const deleteRef = useRef<HTMLDivElement | null>(null);
  const actions = useVideoPlayerActions();
  const onOrderChangeStart = useCallback(() => actions.handleStandBy(), [actions]);
  const onOrderChangeEnd = useCallback(() => actions.resumeStandBy(), [actions]);
  const onPlaylistOrderChange = useCallback(
    (currentVideoIndex: number, newVideoIndex: number) => {
      actions.reorder(currentVideoIndex, newVideoIndex);

      actions.resumeStandBy();
    },
    [actions],
  );

  useEffect(() => {
    if (selectedItems.length > 0 && !enabledBulkMode) {
      setSelectedItems([]);
    }
  }, [enabledBulkMode, setSelectedItems, selectedItems.length]);

  const playlistItemsIds = playlistItems.map((item) => item.id);

  const handleOrderChange = useCallback(
    (playlistItemId: string, currentIndex: number, newIndex: number) => {
      updatePlaylistItemOrder(playlistItemId, newIndex);
      if (onPlaylistOrderChange) onPlaylistOrderChange(currentIndex, newIndex);
    },
    [onPlaylistOrderChange, updatePlaylistItemOrder],
  );

  const handleSelect = useCallback(
    (value: string) => {
      if (!selectedItems.includes(value)) {
        return setSelectedItems([...selectedItems, value]);
      }

      const filteredItems = selectedItems.filter((item) => item !== value);

      return setSelectedItems(filteredItems);
    },
    [setSelectedItems, selectedItems],
  );

  const allItemsSelected = selectedItems.length === playlistItems.length;
  const selectAllIndeterminate = selectedItems.length > 0 && !allItemsSelected;

  const handleSelectAll = useCallback(() => {
    if (selectAllIndeterminate || allItemsSelected) {
      return setSelectedItems([]);
    }

    return setSelectedItems(playlistItemsIds);
  }, [allItemsSelected, playlistItemsIds, selectAllIndeterminate, setSelectedItems]);

  const handlePlaylistItemDuplicate = useCallback(() => {
    invalidatePlaylistQuery();
  }, []);

  const handlePlaylistItemDelete = useCallback(
    (playlistId: string) => {
      setSelectedItems(selectedItems.filter((item) => item !== playlistId));
    },
    [selectedItems, setSelectedItems],
  );

  const handleTrimModalOpen = useCallback(() => setTrimModalOpen(true), []);
  const handleTrimModalClose = useCallback(() => setTrimModalOpen(false), []);

  const handleTrimClick = useCallback(() => handleTrimModalOpen(), [handleTrimModalOpen]);

  const handleDuplicateAll = useCallback(() => {
    duplicatePlaylistItems({
      playlistItemIds: selectedItems,
      options: {
        onSuccess: (playlist: Playlist) => {
          invalidatePlaylistQuery();
          actions.replacePlaylistItems(mapVideos(playlist));
          setEnabledBulkMode(false);
          setSelectedItems([]);
        },
      },
    });
  }, [mapVideos, setEnabledBulkMode, actions, duplicatePlaylistItems, selectedItems, setSelectedItems]);

  const handleDeleteAll = useCallback(() => {
    deletePlaylistItems(selectedItems, () => {
      invalidatePlaylistQuery();
      actions.removePlaylistItems(selectedItems);
      setEnabledBulkMode(false);
      setSelectedItems([]);
    });
  }, [setEnabledBulkMode, actions, deletePlaylistItems, selectedItems, setSelectedItems]);

  const PlaylistItemElement = useCallback(
    (
      draggableProvided: DraggableProvided,
      currentPlaylistItem: PlaylistItemType,
      index: number,
      style?: CSSProperties,
    ) => (
      <div
        ref={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        {...draggableProvided.dragHandleProps}
        style={{
          ...style,
          ...draggableProvided.draggableProps.style,
          padding: `0 ${PLAYLIST_ITEM_GAP}px`,
          height: 'auto',
        }}
      >
        <PlaylistItem
          isEditing={trimmingPlaylistItemId !== '' && trimmingPlaylistItemId === currentPlaylistItem.id}
          isDisabled={trimmingPlaylistItemId !== '' && trimmingPlaylistItemId !== currentPlaylistItem.id}
          key={`playlist-video-${currentPlaylistItem.id}`}
          playlist={playlist}
          playlistItem={currentPlaylistItem}
          setTrimmingPlaylistItem={setTrimmingPlaylistItem}
          videoIndex={index}
          onSelect={handleSelect}
          enabledBulkMode={enabledBulkMode}
          isChecked={selectedItems.includes(currentPlaylistItem.id)}
          onPlaylistItemDelete={handlePlaylistItemDelete}
          onPlaylistItemDuplicate={handlePlaylistItemDuplicate}
        />
      </div>
    ),
    [
      enabledBulkMode,
      handlePlaylistItemDelete,
      handlePlaylistItemDuplicate,
      handleSelect,
      playlist,
      selectedItems,
      setTrimmingPlaylistItem,
      trimmingPlaylistItemId,
    ],
  );

  const Row = ({ data, index, style }: RowProps) => {
    const currentPlaylistItem = data[index];
    return (
      <SortableItem
        isDragDisabled={enabledBulkMode}
        index={index}
        id={currentPlaylistItem.id}
        renderChildren={(draggableProvided) =>
          PlaylistItemElement(draggableProvided, currentPlaylistItem, index, style)
        }
        key={currentPlaylistItem.id}
      />
    );
  };

  const VirtualizedList = (droppableProvided: DroppableProvided) => (
    <AutoSizer defaultHeight={49} defaultWidth={500}>
      {({ height, width }) => (
        <List
          height={height}
          itemCount={playlistItems.length}
          width={width}
          itemSize={PLAYLIST_ITEM_SIZE}
          itemData={playlistItems}
          outerRef={droppableProvided.innerRef}
          layout='horizontal'
          style={{ paddingBottom: height - playlistItemHeight }}
          itemKey={(index, data) => data[index].id}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );

  const handleSetDisabledBulkMode = useCallback(() => {
    setEnabledBulkMode(false);
  }, [setEnabledBulkMode]);

  const handleSetDownloadModalOpen = useCallback(() => {
    setIsDownloadModalOpen(true);
  }, [setIsDownloadModalOpen]);

  const handleCloseDeleteModal = useCallback(() => {
    setDeleteModalOpen(true);
  }, [setDeleteModalOpen]);

  const handleCloseCopyToModal = useCallback(() => {
    setCopyToModalOpen(true);
  }, [setCopyToModalOpen]);

  const handleOpenCopyToModal = useCallback(() => {
    setCopyToModalOpen(false);
    setEnabledBulkMode(false);
    setSelectedItems([]);
  }, [setEnabledBulkMode, setCopyToModalOpen, setSelectedItems]);

  const handleOpenDeleteModal = useCallback(() => {
    setIsDownloadModalOpen(false);
  }, [setIsDownloadModalOpen]);

  const handleRenderClone = useCallback(
    (draggableProvided: DraggableProvided, snapshot: DraggableStateSnapshot, rubric: DraggableRubric) =>
      PlaylistItemElement(draggableProvided, playlistItems[rubric.source.index], rubric.source.index),
    [PlaylistItemElement, playlistItems],
  );

  return (
    <>
      {enabledBulkMode ? (
        <div>
          <ItemsListBulk>
            <Stack direction='row' alignItems='center'>
              <PlaylistBulkButton onClick={handleSetDisabledBulkMode} IconComponent={IconClose} />
              <Stack direction='row' alignItems='center'>
                <CheckboxWithCustomColor
                  customColor='white'
                  onClick={handleSelectAll}
                  checked={allItemsSelected}
                  indeterminate={selectAllIndeterminate}
                />
                {t('playlist-detail:all-clips')} ({selectedItems.length}/{playlistItems.length})
              </Stack>
            </Stack>

            <Stack direction='row' spacing={1}>
              {downloadPlaylistFeatureFlag ? (
                <div ref={downloadRef}>
                  <PlaylistBulkButton
                    onClick={handleSetDownloadModalOpen}
                    IconComponent={IconDownload}
                    label={t('common:actions.download')}
                    disabled={isEmpty(selectedItems)}
                  />
                </div>
              ) : null}

              <PlaylistBulkButton
                onClick={handleTrimClick}
                IconComponent={IconTrim}
                label={t('common:actions.trim')}
                disabled={isEmpty(selectedItems)}
              />

              <PlaylistBulkButton
                onClick={handleDuplicateAll}
                IconComponent={IconCopy}
                label={t('common:actions.duplicate')}
                disabled={isEmpty(selectedItems)}
              />

              <div ref={copyToRef}>
                <PlaylistBulkButton
                  onClick={handleCloseCopyToModal}
                  IconComponent={IconCopyTo}
                  label={t('common:actions.copy-to')}
                  disabled={isEmpty(selectedItems)}
                />
              </div>

              <div ref={deleteRef}>
                <PlaylistBulkButton
                  onClick={handleCloseDeleteModal}
                  IconComponent={IconDelete}
                  label={t('common:actions.delete')}
                  disabled={isEmpty(selectedItems)}
                />
              </div>

              {isDownloadModalOpen ? (
                <DownloadPlaylistItemsModal
                  isPlaylistDownload={false}
                  itemsToDownload={selectedItems}
                  onClose={handleOpenDeleteModal}
                  playlistId={playlist.id}
                />
              ) : null}

              {trimModalOpen ? (
                <TrimModal playlistId={playlist.id} itemsToTrim={selectedItems} onClose={handleTrimModalClose} />
              ) : null}
            </Stack>
          </ItemsListBulk>
          {deleteModalOpen ? (
            <ConfirmPopoverDialog
              anchorEl={deleteRef.current}
              cancelLabel={t('common:actions.cancel')}
              confirmLabel={t('common:actions.remove')}
              description={t('playlist-detail:confirm-permanently-remove-clips')}
              isOpen={deleteModalOpen}
              onConfirm={handleDeleteAll}
              setIsOpen={setDeleteModalOpen}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            />
          ) : null}

          {copyToModalOpen ? (
            <CopyTo
              playlistItems={selectedItems.map((id) => {
                const playlistItem = playlist.playlistItems.find((item) => item.id === id);

                return {
                  startTime: playlistItem?.startTime ?? 0,
                  endTime: playlistItem?.endTime ?? 0,
                  name: playlistItem?.name ?? '',
                  recordingId: playlistItem?.recordingId ?? '',
                  fundamentalsSelected: playlistItem?.fundamentalsSelected ?? {
                    tacticalAnalysisId: undefined,
                    fundamentalsSelected: [],
                  },
                };
              })}
              onClose={handleOpenCopyToModal}
              anchorEl={copyToRef.current}
            />
          ) : null}
        </div>
      ) : null}
      <ItemsListContainer>
        {isEmpty(playlistItems) ? (
          <PlaylistItemsEmpty />
        ) : (
          <SortableItemList
            isDropDisabled={enabledBulkMode}
            onDragStart={onOrderChangeStart}
            onDragEnd={onOrderChangeEnd}
            onOrderChange={handleOrderChange}
            id={'playlist-items-list'}
            items={playlistItems}
            mode='virtual'
            renderClone={handleRenderClone}
            renderChildren={VirtualizedList}
            direction={'horizontal'}
          />
        )}
      </ItemsListContainer>
    </>
  );
};
