import { PlayBackTypes, Recording, RecordingTypes, VideoSourceStates, ViewTypes } from 'shared/types/recording/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

import { FetchTaggingRecordingsResponse, TaggingRecordingsRecording } from './types';

export const transformLiveSessionsRecordings = (recording: TaggingRecordingsRecording): Recording => {
  return {
    competitionName: recording.competitionName,
    duration: recording.duration,
    id: recording.id,
    matchDay: recording.matchday ?? '',
    name: recording.name,
    type: recording.type as RecordingTypes,
    isLive: recording.isLive,
    isProcessingVideo: false,
    date: new Date(recording.date),
    extraTime: recording.extraTime,
    teams: recording.teams,
    hasTaggingEvents: recording.hasTaggingEvents,
    tacticalAnalysis: recording.tacticalAnalysis,
    videoSourcesStates: recording?.videoSourcesStates
      ? recording.videoSourcesStates.map((videoSourceState) => ({
          playBackType: videoSourceState.playBackType as PlayBackTypes,
          viewType: videoSourceState.viewType as ViewTypes,
          state: videoSourceState.state as VideoSourceStates,
        }))
      : [],
    matchReportDownloadUrl: null,
  };
};

export type LiveSessionsResponse = {
  data: {
    liveSessions: Recording[];
  };
};

export const transformLiveSessions = (response: FetchTaggingRecordingsResponse) => {
  return {
    data: {
      liveSessions: response.content.map(transformLiveSessionsRecordings),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
