import styles from './NotEffectiveTimeClipsList.module.scss';
import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { SelectableClip } from '../clip';

type Props = {
  clip: Clip;
  recordingId: string;
};
export const NotEffectiveTimeClipsList = ({ clip, recordingId }: Props) => {
  return (
    <div className={styles.content}>
      {clip.clips?.map((innerClip) => (
        <SelectableClip recordingId={recordingId} clip={innerClip} parentClip={clip} key={innerClip.id} />
      ))}
    </div>
  );
};
