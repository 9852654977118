import { Box, Button, Container, Paper, Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import React, { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { IconChevronRight } from 'shared/components/icons/icon-chevron-right';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { useRedirectTo } from 'shared/components/sidebar-layout/sidebar-menu/hooks/use-redirect-to';

import { useClients } from '../../../../api/backoffice/client/hooks/use-clients/useClients';
import { useRemoveBranding } from '../../../../api/backoffice/client/hooks/use-remove-branding/useRemoveBranding';
import { BackofficeContentContainer } from '../../components/backoffice-content-container';

export const EnvironmentDetail = () => {
  const { id } = useParams<{ id: string }>();
  const redirectTo = useRedirectTo();
  const { remove } = useRemoveBranding();
  const { items, isLoading: isLoadingClient } = useClients({ initialFilters: { clientIds: [id] } });
  const environment = items?.[0];

  const handleRemoveBranding = useCallback(() => {
    remove({ clientId: id });
  }, [id, remove]);

  if (!environment) {
    return <div>No data</div>;
  }

  return (
    <BackofficeContentContainer>
      <Container fixed maxWidth='sm'>
        <Typography variant={'h6'} sx={{ fontWeight: fontWeight['400'] }} display='flex' alignItems='center' gap={0.5}>
          <span>Environments</span>
          <IconChevronRight size={IconSizes.small} color={IconColors.storm} />
          <span>{environment.name}</span>
        </Typography>
        <Stack direction='row' mt={2} spacing={2} justifyContent='flex-end'>
          <Button
            variant='outlined'
            color='error'
            onClick={handleRemoveBranding}
            disabled={Boolean(!environment.branding)}
          >
            Remove branding
          </Button>
          <Button
            variant='outlined'
            onClick={() =>
              redirectTo(
                generatePath(
                  environment.branding
                    ? routes.BACKOFFICE_EDIT_ENVIRONMENT_BRANDING
                    : routes.BACKOFFICE_CREATE_ENVIRONMENT_BRANDING,
                  { id: environment.id },
                ),
              )
            }
          >
            {environment.branding ? 'Edit branding' : 'Add branding'}
          </Button>
          <Button
            variant='outlined'
            onClick={() => redirectTo(generatePath(routes.BACKOFFICE_EDIT_ENVIRONMENT, { id: environment.id }))}
          >
            Edit client
          </Button>
        </Stack>
        <Stack p={2} mt={2} component={Paper}>
          {isLoadingClient ? (
            <div>Loading...</div>
          ) : (
            <Stack spacing={2}>
              <Stack>
                <Typography variant='h5'>Client</Typography>
                <Stack spacing={1}>
                  <Typography>
                    <strong>ID: </strong>
                    {environment.id}
                  </Typography>
                  <Typography>
                    <strong>Name: </strong>
                    {environment.name}
                  </Typography>
                  <Typography>
                    <strong>City: </strong>
                    {environment.city}
                  </Typography>
                  <Typography>
                    <strong>Country: </strong>
                    {environment.country}
                  </Typography>
                </Stack>
              </Stack>
              <Stack>
                <Typography variant='h5'>Branding</Typography>
                {environment.branding ? (
                  <Stack spacing={1}>
                    <Typography>
                      <strong>Display name: </strong>
                      {environment.branding.displayName}
                    </Typography>
                    <Stack>
                      <Typography>
                        <strong>Favicon: </strong>
                      </Typography>
                      <Box component='img' src={environment.branding.faviconUrl} />
                    </Stack>
                    <Stack>
                      <Typography>
                        <strong>Navbar logo: </strong>
                      </Typography>
                      <Box component='img' src={environment.branding.navbarLogoUrl} />
                    </Stack>
                    <Stack>
                      <Typography>
                        <strong>Navbar logo collapsed: </strong>
                      </Typography>
                      <Box component='img' src={environment.branding.navbarCollapsedLogoUrl} />
                    </Stack>
                    <Stack>
                      <Typography>
                        <strong>Email logo: </strong>
                      </Typography>
                      <Box component='img' src={environment.branding.emailLogoUrl} />
                    </Stack>
                  </Stack>
                ) : (
                  <div>No branding</div>
                )}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Container>
    </BackofficeContentContainer>
  );
};
