import { arrayMoveImmutable } from 'array-move';
import isEmpty from 'lodash/isEmpty';
import { assign } from 'xstate';

import {
  AddPlaylistItemsAction,
  ChangeAutoplayPlaylistItemAction,
  PlayingModes,
  RemovePlaylistItemsAction,
  UpdatePlaylistItemAction,
  VideoSourceWithTimes,
} from 'shared/components/video-player/types';

import { getNextPlaylistItem } from './get-next-playlist-item';
import { getPreviousPlaylistItem } from './get-previous-playlist-item';
import {
  getVideoSourceIndexAndTimeFromMatchTime,
  getVideoSourceIndexAndTimeFromPercentTime,
} from './get-video-source-index';
import { isFirstPlaylistItem, isLastPlaylistItem } from './guards';
import { jumpToPlaylistItem } from './jump-to-playlist-item';
import { jumpToVideoSource } from './jump-to-video-source';
import { playerPlayingControlQueue } from './playing-control-queue';
import { replacePlayListItemByIndex } from './replace-playlist-item-by-index';
import { SKIP_STEP_SIZE, skipTime } from './skip-time';
import {
  ChangePlayingModeAction,
  JumpToMatchTimeAction,
  JumpToPercentTimeAction,
  PlayerStateMachineContext,
  PlayerStateMachineEvent,
  PlaylistItemType,
  RemovePlaylistAction,
  ReorderPlaylistItemAction,
  SetPlaylistAction,
  SetPlaylistItemAction,
  VideoSourceType,
} from '../../types';
import {
  getCurrentTimeAndVideoSourceIndex,
  getCurrentVideoSource,
  getVideoByVideoType,
  getVideoSourceByIndex,
} from '../../util';

export const nextVideoSource = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  const { videoSources } = getVideoByVideoType(
    context.playlist.playingItem.playlistItem,
    context.playlist.currentSelectedPlayingMode,
  );

  const nextVideoSourceIndex = context.playlist.playingItem.videoSourceIndex + 1;
  const isLastVideoSource = nextVideoSourceIndex >= videoSources.length;

  if (isLastVideoSource) {
    return !isLastPlaylistItem(context) && context.autoPlayNextPlaylistItem
      ? jumpToPlaylistItem(
          context,
          getNextPlaylistItem(context.playlist.currentPlaylistItemId, context.playlist.playlistItems),
        )
      : {
          isPlaying: false,
          ...(context?.videoRef?.current && {
            currentTime: context.videoRef.current.currentTime,
          }),
        };
  }

  return jumpToVideoSource(nextVideoSourceIndex, context);
});

export const previousVideoSource = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  const previousVideoSourceIndex = context.playlist.playingItem.videoSourceIndex - 1;

  if (context.playlist.playingItem.videoSourceIndex === 0 && !isFirstPlaylistItem(context)) {
    return jumpToPlaylistItem(
      context,
      getPreviousPlaylistItem(context.playlist.currentPlaylistItemId, context.playlist.playlistItems),
    );
  }

  return jumpToVideoSource(previousVideoSourceIndex >= 0 ? previousVideoSourceIndex : 0, context);
});

export const restart = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  const videoSource = getVideoSourceByIndex(
    context.playlist.playingItem.playlistItem,
    context.playlist.currentSelectedPlayingMode,
    0,
  );

  return {
    currentTime: videoSource.startTime,
    playlist: {
      ...context.playlist,
      playingItem: {
        ...context.playlist.playingItem,
        videoSourceIndex: 0,
      },
    },
  };
});

export const seekNewTime = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as JumpToPercentTimeAction;

  const { videoSourceIndex, currentSourceTime } = getVideoSourceIndexAndTimeFromPercentTime(
    context.playlist.currentSelectedPlayingMode,
    context.playlist.playingItem.playlistItem,
    event.percent,
  );

  return {
    currentTime: currentSourceTime,
    playlist: {
      ...context.playlist,
      playingItem: {
        ...context.playlist.playingItem,
        videoSourceIndex,
      },
    },
  };
});

export const jumpToMatchTime = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as JumpToMatchTimeAction;

  const { videoSourceIndex, currentSourceTime } = getVideoSourceIndexAndTimeFromMatchTime(
    context.playlist.currentSelectedPlayingMode,
    context.playlist.playingItem.playlistItem,
    event.time,
  );

  return {
    currentTime: currentSourceTime,
    playlist: {
      ...context.playlist,
      playingItem: {
        ...context.playlist.playingItem,
        videoSourceIndex,
      },
    },
  };
});

export const setVideoRef = assign<PlayerStateMachineContext, PlayerStateMachineEvent>({
  videoRef: (context: PlayerStateMachineContext, _event: PlayerStateMachineEvent) => {
    const event = _event as SetPlaylistAction;
    return event.playerRef;
  },
});

const getValidCurrentTime = (newTime: number | undefined, videoSource: VideoSourceWithTimes) => {
  if (!newTime) return videoSource.startTime;
  if (newTime < videoSource.startTime || newTime > videoSource.endTime) return videoSource.startTime;

  return newTime;
};

export const addPlaylistItems = assign<PlayerStateMachineContext, PlayerStateMachineEvent>(
  (context: PlayerStateMachineContext, _event: PlayerStateMachineEvent) => {
    const event = _event as AddPlaylistItemsAction;

    if (event.playlistItems.length === 0) return context;
    const isCurrentPlaylistEmpty = context.playlist.playlistItems.length === 0;

    if (isCurrentPlaylistEmpty) {
      const { videoSources: currentVideoSource } = getVideoByVideoType(
        event.playlistItems[0],
        context.playlist.currentSelectedPlayingMode,
      );
      context.currentTime = currentVideoSource[0].startTime;

      const playingItem = isCurrentPlaylistEmpty
        ? {
            ...context.playlist.playingItem,
            currentSourceTime: currentVideoSource[0].startTime,
            videoSourceIndex: 0,
            playlistItem: event.playlistItems[0],
          }
        : context.playlist.playingItem;

      const currentPlaylistItemId = isCurrentPlaylistEmpty
        ? event.playlistItems[0].id
        : context.playlist.currentPlaylistItemId;

      return {
        playlist: {
          ...context.playlist,
          currentPlaylistItemId,
          playlistItems: event.playlistItems,
          playingItem,
        },
      };
    }

    const updatedPlaylistItem =
      event.playlistItems.find((item) => item.id === context.playlist.playingItem.playlistItem.id) ||
      context.playlist.playingItem.playlistItem;

    const { videoSources } = getVideoByVideoType(updatedPlaylistItem, context.playlist.currentSelectedPlayingMode);
    const currentTime = getValidCurrentTime(
      context.videoRef?.current?.currentTime,
      videoSources[context.playlist.playingItem.currentSourceTime],
    );

    return {
      ...context,
      currentTime,
      playlist: {
        ...context.playlist,
        playlistItems: event.playlistItems,
        playingItem: {
          ...context.playlist.playingItem,
          playlistItem: updatedPlaylistItem,
        },
      },
    };
  },
);

export const updatePlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as UpdatePlaylistItemAction;

  let currentTime = context.currentTime;

  const playlistItemIndex = context.playlist.playlistItems.findIndex(
    (playlistItem: PlaylistItemType) => playlistItem.id === event.playlistItem.id,
  );

  const isCurrentPlaylistItem = context.playlist.currentPlaylistItemId === context.playlist.playingItem.playlistItem.id;

  if (isCurrentPlaylistItem) {
    const { videoSources } = getVideoByVideoType(event.playlistItem, context.playlist.currentSelectedPlayingMode);
    currentTime = event.currentTime
      ? event.currentTime
      : getValidCurrentTime(context.videoRef?.current?.currentTime, videoSources[0]) ?? videoSources[0].startTime;
  }

  return {
    currentTime,
    playlist: {
      ...context.playlist,
      playingItem: {
        ...context.playlist.playingItem,
        playlistItem: isCurrentPlaylistItem ? event.playlistItem : context.playlist.playingItem.playlistItem,
      },
      playlistItems: replacePlayListItemByIndex(context.playlist.playlistItems, playlistItemIndex, event.playlistItem),
    },
  };
});

const EMPTY_PLAYLIST = {
  playlistItems: [],
  currentPlaylistItemId: '',
  playingItem: {
    currentSourceTime: 0,
    playlistItem: {
      videoTypes: [],
      id: '',

      duration: 0,
      recordingId: '',
      fundamentalsSelected: {
        tacticalAnalysisId: undefined,
        fundamentalsSelected: [],
      },
      hasHomographies: false,
    },
    videoSourceIndex: 0,
  },
};

export const updatePlaylistItems = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as SetPlaylistAction;

  if (!event.playlistItems || event.playlistItems.length === 0) {
    return {
      currentTime: 0,
      playlist: {
        ...context.playlist,
        ...EMPTY_PLAYLIST,
      },
    };
  }

  return {
    playlist: {
      ...context.playlist,
      playlistItems: event.playlistItems,
    },
  };
});

export const setPlaylistItems = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as SetPlaylistAction;
  const currentVideoSource = getCurrentVideoSource(context);

  if (!event.playlistItems || event.playlistItems.length === 0) {
    return {
      isPlaying: !!event.autoplay,
      playlist: {
        ...context.playlist,
        ...EMPTY_PLAYLIST,
        preferredPlayingMode: event.playingMode ?? context.playlist.preferredPlayingMode,
      },
    };
  }

  const playingMode = event.playingMode ? event.playingMode : context.playlist.preferredPlayingMode;
  const { id } = event.playlistItems[0];
  const currentPlaylistItem = event.playlistItems[0];

  const { videoSources, playingMode: currentPlayingMode } = getVideoByVideoType(currentPlaylistItem, playingMode);
  const currentMatchTime =
    currentPlayingMode.mode === PlayingModes.EPISODES
      ? (context.videoRef?.current?.currentTime ?? 0) + (currentVideoSource?.startTimeInMatch ?? 0)
      : context.videoRef?.current?.currentTime ?? 0;

  const initialTime = event.tryToKeepCurrentTime ? currentMatchTime : event.initialStartTime ?? 0;

  // TODO: when setting new playlist items there is a case when videoSources are empty for a split of second.
  // This is a temporary fix.
  // it's happening when we play the row with episodes videos.
  if (videoSources.length === 0) return context;

  const { videoIndex, currentTime } = getCurrentTimeAndVideoSourceIndex(initialTime, videoSources, playingMode);
  // TODO: return it instead of modifying the context. Return doesn't work with trim player.
  context.isPlaying = event.autoplay ? context.isPlaying : false;
  context.playlist = {
    ...context.playlist,
    currentPlaylistItemId: id,
    currentSelectedPlayingMode: currentPlayingMode,
    playlistItems: event.playlistItems,
    playingItem: {
      currentSourceTime: 0,
      playlistItem: currentPlaylistItem,
      videoSourceIndex: videoIndex,
    },
  };

  context.currentTime = currentTime;

  return context;
});

export const setPlayerToPlay = assign<PlayerStateMachineContext, PlayerStateMachineEvent>({
  isPlaying: true,
});

export const setResumeStandBy = assign<PlayerStateMachineContext, PlayerStateMachineEvent>({
  isInStandBy: false,
});

export const setStandBy = assign<PlayerStateMachineContext, PlayerStateMachineEvent>({
  isInStandBy: true,
});

export const toggleFullScreen = assign<PlayerStateMachineContext, PlayerStateMachineEvent>({
  isFullScreen: (context: PlayerStateMachineContext) => !context.isFullScreen,
});

export const setPlayerToPause = assign<PlayerStateMachineContext, PlayerStateMachineEvent>({
  isPlaying: false,
});

export const reorderPlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>({
  playlist: (context: PlayerStateMachineContext, _event: PlayerStateMachineEvent) => {
    const event = _event as ReorderPlaylistItemAction;

    const reorderedPlaylistItems = arrayMoveImmutable(
      context.playlist.playlistItems,
      event.currentVideoIndex,
      event.newVideoIndex,
    );

    return {
      ...context.playlist,
      playlistItems: reorderedPlaylistItems,
    };
  },
});

export const changeAutoplayNextPlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>(
  (context: PlayerStateMachineContext, _event: PlayerStateMachineEvent) => {
    const event = _event as ChangeAutoplayPlaylistItemAction;

    return {
      autoPlayNextPlaylistItem: event.autoplayNextPlaylistItem,
    };
  },
);

const findNearestPlaylistItem = (playlistItems: PlaylistItemType[], playlistItemIndex: number) => {
  return playlistItemIndex <= playlistItems.length - 1 ? playlistItemIndex + 1 : playlistItems.length - 1;
};

export const changePlayingMode = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as ChangePlayingModeAction;

  const playlistItemIndex = context.playlist.playlistItems.findIndex(
    (playlistItem: PlaylistItemType) => playlistItem.id === context.playlist.currentPlaylistItemId,
  );
  const videoSource = getVideoSourceByIndex(context.playlist.playlistItems[playlistItemIndex], event.playingMode, 0);
  const oldVideoSource = getVideoSourceByIndex(
    context.playlist.playlistItems[playlistItemIndex],
    context.playlist.currentSelectedPlayingMode,
    0,
  );

  context.currentTime =
    videoSource.src === oldVideoSource.src
      ? context.videoRef?.current?.currentTime ?? videoSource.startTime
      : videoSource.startTime + context.currentTime - oldVideoSource.startTime;

  context.playlist = {
    ...context.playlist,
    currentSelectedPlayingMode: event.playingMode,
    playingItem: {
      currentSourceTime: 0,
      videoSourceIndex: 0,
      playlistItem: context.playlist.playlistItems[playlistItemIndex],
    },
  };

  return context;
});

export const removePlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as RemovePlaylistAction;

  const playlistItemIndex = context.playlist.playlistItems.findIndex(
    (playlistItem: PlaylistItemType) => playlistItem.id === event.playlistItemId,
  );

  const isCurrentPlaylistItem = event.playlistItemId === context.playlist.currentPlaylistItemId;
  const modifiedPlaylistItems = context.playlist.playlistItems.filter((item, index) => index !== playlistItemIndex);

  if (isCurrentPlaylistItem) {
    const newCurrentVideoIndex = modifiedPlaylistItems[playlistItemIndex]
      ? playlistItemIndex
      : findNearestPlaylistItem(modifiedPlaylistItems, playlistItemIndex);

    const videoSource = isEmpty(modifiedPlaylistItems)
      ? { currentTime: 0, endTime: 0, startTime: 0, duration: 0 }
      : getVideoSourceByIndex(
          modifiedPlaylistItems[newCurrentVideoIndex],
          context.playlist.currentSelectedPlayingMode,
          0,
        );

    return {
      currentTime: videoSource.startTime,
      playlist: {
        ...context.playlist,
        playlistItems: modifiedPlaylistItems,
        currentPlaylistItemId: isEmpty(modifiedPlaylistItems) ? '' : modifiedPlaylistItems[newCurrentVideoIndex].id,
        playingItem: {
          currentSourceTime: 0,
          videoSourceIndex: 0,
          playlistItem: isEmpty(modifiedPlaylistItems)
            ? {
                videoTypes: [],
                id: '',

                duration: 0,
                recordingId: '',
                fundamentalsSelected: {
                  tacticalAnalysisId: undefined,
                  fundamentalsSelected: [],
                },
                hasHomographies: false,
              }
            : modifiedPlaylistItems[newCurrentVideoIndex],
        },
      },
    };
  }

  return {
    playlist: {
      ...context.playlist,
      playlistItems: modifiedPlaylistItems,
    },
  };
});

export const removePlaylistItems = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as RemovePlaylistItemsAction;

  let currentTime = context.currentTime;

  const isCurrentPlaylistItem = event.playlistItemsIds.includes(context.playlist.currentPlaylistItemId);

  const modifiedPlaylistItems = context.playlist.playlistItems.filter(
    (item) => !event.playlistItemsIds.includes(item.id),
  );

  if (isEmpty(modifiedPlaylistItems)) {
    return {
      currentTime: 0,
      playlist: {
        ...context.playlist,
        playlistItems: [],
        currentPlaylistItemId: '',
        playingItem: {
          currentSourceTime: 0,
          playlistItem: {
            videoTypes: [],
            id: '',

            duration: 0,
            recordingId: '',
            fundamentalsSelected: {
              tacticalAnalysisId: undefined,
              fundamentalsSelected: [],
            },
            hasHomographies: false,
          },
          videoSourceIndex: 0,
        },
      },
    };
  }

  if (isCurrentPlaylistItem && !isEmpty(modifiedPlaylistItems)) {
    const videoSource = getVideoSourceByIndex(modifiedPlaylistItems[0], context.playlist.currentSelectedPlayingMode, 0);

    currentTime = videoSource.startTime;
  }

  return {
    currentTime,
    playlist: {
      ...context.playlist,
      playlistItems: modifiedPlaylistItems,
      ...(isCurrentPlaylistItem && {
        currentPlaylistItemId: isEmpty(modifiedPlaylistItems) ? '' : modifiedPlaylistItems[0]?.id,
        playingItem: {
          currentSourceTime: 0,
          videoSourceIndex: 0,
          playlistItem: isEmpty(modifiedPlaylistItems)
            ? {
                videoTypes: [],
                id: '',

                duration: 0,
                recordingId: '',
                fundamentalsSelected: {
                  tacticalAnalysisId: undefined,
                  fundamentalsSelected: [],
                },
                hasHomographies: false,
              }
            : modifiedPlaylistItems[0],
        },
      }),
    },
  };
});

export const setPlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context, _event) => {
  const event = _event as SetPlaylistItemAction;

  const playlistItemIndex = context.playlist.playlistItems.findIndex(
    (playlistItem: PlaylistItemType) => playlistItem.id === event.playlistItemId,
  );

  const currentPlaylistItem = context.playlist.playlistItems[playlistItemIndex];

  const selectedVideoType = getVideoByVideoType(currentPlaylistItem, context.playlist.preferredPlayingMode);
  const videoSource = selectedVideoType.videoSources[0];

  return {
    currentTime: videoSource.startTime,
    isPlaying: event.autoPlay,
    playlist: {
      ...context.playlist,
      currentPlaylistItemId: currentPlaylistItem.id,
      currentSelectedPlayingMode: selectedVideoType.playingMode,
      playingItem: {
        currentSourceTime: 0,
        playlistItem: currentPlaylistItem,
        videoSourceIndex: 0,
      },
    },
  };
});

export const play = async (context: PlayerStateMachineContext) => {
  if (!context.videoRef?.current) return;

  if ((!context.videoRef.current.playing && !context.isInStandBy) || context.videoRef.current.playing) {
    playerPlayingControlQueue.enqueue(async () => context.videoRef?.current?.play());
  } else if (context.isInStandBy) {
    playerPlayingControlQueue.enqueue(async () => context.videoRef?.current?.pause());
  }
};

export const pause = async (context: PlayerStateMachineContext) => {
  if (!context.videoRef?.current) return;

  if (context.videoRef.current.playing) {
    playerPlayingControlQueue.enqueue(async () => context.videoRef?.current?.pause());
  }
};

export const skipForward = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  return skipTime(context, SKIP_STEP_SIZE);
});

export const skipBackward = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  return skipTime(context, -SKIP_STEP_SIZE);
});

export const nextPlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  const nextPlaylistItem = getNextPlaylistItem(context.playlist.currentPlaylistItemId, context.playlist.playlistItems);

  return jumpToPlaylistItem(context, nextPlaylistItem);
});

export const firstPlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  const firstPlaylistItem = context.playlist.playlistItems[0];

  const selectedVideoType = getVideoByVideoType(firstPlaylistItem, context.playlist.preferredPlayingMode);
  const videoSource = selectedVideoType.videoSources[0];

  return {
    currentTime: videoSource.startTime,
    playlist: {
      ...context.playlist,
      currentPlaylistItemId: firstPlaylistItem.id,
      currentSelectedPlayingMode: selectedVideoType.playingMode,
      playingItem: {
        currentSourceTime: 0,
        playlistItem: firstPlaylistItem,
        videoSourceIndex: 0,
      },
    },
  };
});

export const previousPlaylistItem = assign<PlayerStateMachineContext, PlayerStateMachineEvent>((context) => {
  const previousPlaylistItem = getPreviousPlaylistItem(
    context.playlist.currentPlaylistItemId,
    context.playlist.playlistItems,
  );

  const selectedVideoType = getVideoByVideoType(previousPlaylistItem, context.playlist.preferredPlayingMode);
  const videoSource = selectedVideoType.videoSources[0];

  return {
    currentTime: videoSource.startTime,
    playlist: {
      ...context.playlist,
      currentPlaylistItemId: previousPlaylistItem.id,
      currentSelectedPlayingMode: selectedVideoType.playingMode,
      playingItem: {
        currentSourceTime: 0,
        playlistItem: previousPlaylistItem,
        videoSourceIndex: 0,
      },
    },
  };
});

const canDurationToPlaylistItemBeChanged = (videoTypes: VideoSourceType[], hasHomographies: boolean) => {
  if (!hasHomographies && videoTypes.some((videoType) => videoType.videoSources.length > 1)) {
    console.error('Not possible to add duration to VideoSourceType with more the one VideoSource');
    return false;
  }

  return true;
};

const addDurationToPlaylistItem = (videoTypes: VideoSourceType[], duration: number): VideoSourceType[] => {
  return videoTypes.map((videoType) => {
    return {
      ...videoType,
      videoSources: videoType.videoSources.map((videoSource) => ({
        ...videoSource,
        ...(!videoSource?.startTimeInMatch && {
          startTimeInMatch: videoSource.startTime,
        }),
        endTime: duration,
        endTimeInMatch: duration,
      })),
    };
  });
};

export const fixPlaylistItemWithoutNoDuration = assign<PlayerStateMachineContext, PlayerStateMachineEvent>(
  (context) => {
    const playlistItem = context.playlist.playingItem.playlistItem;

    if (!canDurationToPlaylistItemBeChanged(playlistItem.videoTypes, playlistItem.hasHomographies)) return context;

    const duration = context.videoRef?.current?.duration ?? 0;

    const videoTypes = addDurationToPlaylistItem(playlistItem.videoTypes, duration);

    const adjustedPlaylistItem = {
      ...playlistItem,
      duration,
      videoTypes,
    };

    return {
      playlist: {
        ...context.playlist,
        playingItem: {
          ...context.playlist.playingItem,
          playlistItem: adjustedPlaylistItem,
        },
      },
    };
  },
);
