import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { AccountUrl } from 'api/routes';
import { Client, User, UserAuthority, UserRole } from 'shared/types';

import { queryClient } from '../../config';

export interface AccountAPIResponse {
  id: string;
  clients: Client[];
  email: string;
  firstName: string;
  lastName: string;
  phoneAreaCode?: string | null;
  phoneNumber?: string | null;
  role: UserRole;
  avatarUrl?: string;
  locale: string;
  authorities: string[];
}

interface useFetchAccountInterface {
  (onError: () => void, onSuccess: (user: User) => void): UseQueryResult<User> & { setQueryData: (user: User) => void };
}

export const transformUser = (user: AccountAPIResponse): User => ({
  id: user.id,
  clients: user.clients,
  email: user.email,
  firstName: user.firstName,
  lastName: user.lastName,
  phoneAreaCode: user.phoneAreaCode ?? undefined,
  phoneNumber: user.phoneNumber ?? undefined,
  role: user.role as UserRole,
  avatarUrl: user.avatarUrl,
  locale: user.locale,
  authorities: user.authorities.map((authority) => authority as UserAuthority),
});

export const useFetchAccount: useFetchAccountInterface = (onError, onSuccess) => {
  const queryRef = useMemo(() => ['fetchAccount'], []);
  const fetchRequest = useFetchRequest<User>({
    onError,
    queryRef: queryRef,
    url: AccountUrl,
    transformer: transformUser,
    onSuccess,
    options: {
      retry: false,
      refetchOnWindowFocus: true,
      refetchInterval: 900000,
    },
  });

  const setQueryData = useCallback((user: User) => queryClient.setQueryData(queryRef, user), [queryRef]);
  return { ...fetchRequest, setQueryData };
};
