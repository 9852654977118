import { Button } from '@mui/material';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import startCase from 'lodash/startCase';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useFetchRecording } from 'pages/recording-edit/hooks/useGetRecording';
import { useUpdateRecording } from 'pages/recording-edit/hooks/useUpdateRecording';
import DateSelector from 'shared/components/date-selector';
import Input from 'shared/components/input';
import RadioButton from 'shared/components/radio-button';
import Spinner from 'shared/components/spinner';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';
import { EditFormRecording, RecordingTypes } from 'shared/types/recording/types';

import styles from './RecordingEditForm.module.scss';

type RecordingEditFormProps = {
  initialData: EditFormRecording;
  recordingId: string;
};

const RecordingEditForm = ({ initialData, recordingId }: RecordingEditFormProps) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { invalidateQuery } = useFetchRecording(recordingId);
  const [recordingData, setRecordingData] = useState(initialData);

  const redirectToRecordingListPage = () => {
    const recordingListPath = generatePath(routes.RECORDING_LIST);
    history.push(recordingListPath);
  };

  const onCancel = () => {
    redirectToRecordingListPage();
  };

  const triggerNotification = useNotifications();
  const onUpdateSuccess = () => {
    triggerNotification({ type: NotificationType.INFO, message: t('api:use-update-recording.success') });
    redirectToRecordingListPage();
  };

  const onUpdateError = () => {
    triggerNotification({ type: NotificationType.ERROR, message: t('api:use-update-recording.error') });
  };

  const onUpdateSettled = () => {
    invalidateQuery && invalidateQuery();
  };

  const { sendUpdate, isUpdating } = useUpdateRecording(recordingId, onUpdateSuccess, onUpdateError, onUpdateSettled);

  const onSubmit = (params: EditFormRecording) => {
    sendUpdate(params);
  };

  const sanitiseUpdate = (recordingUpdate: EditFormRecording): EditFormRecording => {
    if (recordingUpdate.type !== RecordingTypes.TRAINING) return recordingUpdate;

    return { ...recordingUpdate, ...{ competitionName: '', matchDay: '', homeTeamScore: 0, awayTeamScore: 0 } };
  };

  const submitForm = () => {
    const recordingUpdate = pick(recordingData, [
      'name',
      'competitionName',
      'matchDay',
      'date',
      'type',
      'homeTeamScore',
      'awayTeamScore',
    ]) as EditFormRecording;
    const sanitisedUpdate = sanitiseUpdate(recordingUpdate);
    onSubmit(sanitisedUpdate);
  };

  const onChangeType = (type: RecordingTypes) => {
    setRecordingData({ ...recordingData, ...{ type: type } });
  };

  const onChangeDate = (date: Date) => {
    setRecordingData({ ...recordingData, ...{ date } });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRecordingData({ ...recordingData, ...{ [event.target.id]: event.target.value } });
  };

  const isChecked = (value: RecordingTypes) => recordingData.type === value;

  const RadioButtons = () => {
    return (
      <>
        {Object.values(RecordingTypes).map((recordingType) => {
          return (
            <RadioButton
              key={`type-${recordingType}`}
              id={`type-${recordingType}`}
              value={t(`recording-edit:form.recording-types.${recordingType}`)}
              checked={isChecked(recordingType)}
              onSelect={() => onChangeType(recordingType)}
              displayValue={startCase(recordingType)}
            />
          );
        })}
      </>
    );
  };

  return (
    <>
      <h3 className={styles.pageHeader}>{t('recording-edit:form.title')}</h3>
      <div className={styles.formContainer}>
        <Input
          id='name'
          label={t('recording-edit:form.labels.recording-name')}
          placeholder='Input Text'
          onChange={onChange}
          value={recordingData.name}
        />
        <div className={styles.formBreaker} />
        <div className={styles.radioButtonContainer}>
          <RadioButtons />
        </div>
        <DateSelector onChangeDate={onChangeDate} date={recordingData.date} />
        {recordingData.type === RecordingTypes.GAME && (
          <>
            <div className={styles.formBreaker} />
            <Input
              id='competitionName'
              label={t('recording-edit:form.labels.competition-name')}
              placeholder='Input Text'
              onChange={onChange}
              value={recordingData.competitionName || ''}
            />
            <Input
              id='matchDay'
              label={t('recording-edit:form.labels.match-day')}
              placeholder='Input Text'
              onChange={onChange}
              value={recordingData.matchDay || ''}
            />
            <Input
              id='homeTeamScore'
              label={t('recording-edit:form.labels.home-team-score')}
              placeholder='Input Score'
              onChange={onChange}
              value={recordingData.homeTeamScore >= 0 ? recordingData.homeTeamScore : ''}
              type='number'
              min='0'
            />
            <Input
              id='awayTeamScore'
              label={t('recording-edit:form.labels.away-team-score')}
              placeholder='Input Score'
              onChange={onChange}
              value={recordingData.awayTeamScore >= 0 ? recordingData.awayTeamScore : ''}
              type='number'
              min='0'
            />
          </>
        )}
      </div>
      <div className={styles.buttonContainer}>
        <Button variant='outlined' color='secondary' id='cancel' onClick={onCancel}>
          {t('common:actions.cancel')}
        </Button>
        {isUpdating ? (
          <Spinner />
        ) : (
          <Button variant='contained' id='submit' disabled={isEqual(initialData, recordingData)} onClick={submitForm}>
            {t('common:actions.update')}
          </Button>
        )}
      </div>
    </>
  );
};

export default RecordingEditForm;
