import { getTypeOptions } from 'shared/components/form/get-type-options';

import { EnvironmentFormFieldsNames } from './environmentFormFieldsNames';
import { Countries } from '../../../utils/countries';
import { EnvironmentFormField } from '../types/environmentFormField';

export const generateEnvironmentFormFields = (): EnvironmentFormField[] => [
  {
    id: EnvironmentFormFieldsNames.ID,
    component: 'input',
    fullWidth: true,
  },
  {
    id: EnvironmentFormFieldsNames.NAME,
    component: 'input',
    fullWidth: true,
  },
  {
    id: EnvironmentFormFieldsNames.CITY,
    component: 'input',
    fullWidth: true,
  },
  {
    id: EnvironmentFormFieldsNames.COUNTRY,
    component: 'select',
    options: getTypeOptions({
      options: Countries.map((country) => ({
        value: country.code,
        label: country.name,
      })),
    }),
  },
];
