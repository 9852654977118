export enum BackofficeTaskType {
  TRAJECTORIES = 'trajectories',
  EVENTS = 'events',
}

export enum BackofficePipelineType {
  THIRD_PARTY_TACTICAL = 'third-party-tactical',
  THIRD_PARTY_TRACKING = 'third-party-tactical-tracking',
  KOGNIA = 'kognia',
  KOGNIA_REAL_TIME = 'kognia-real-time',
  KOGNIA_4K = 'kognia-4k',
}

export enum BackofficeAnnotationToolType {
  CVAT = 'cvat',
  SUPER_ANNOTATE = 'super-annotate',
}

enum TaskApiFields {
  RECORDING_ID = 'recordingId',
  TASK_TYPE = 'taskType',
  START_IX = 'startIx',
  END_IX = 'endIx',
  LOAD_ALL = 'load',
  PIPELINE_TYPE = 'pipelineType',
  ANNOTATION_TOOL = 'annotationTool',
  DRY_RUN = 'dryRun',
  EPISODE_INDEX = 'episodeIx',
  TACTICAL_ANALYSIS_ID = 'tacticalAnalysisId',
  GIT_COMMIT = 'gitCommit',
}

export interface TaskApiFieldsTypes {
  [TaskApiFields.RECORDING_ID]: string;
  [TaskApiFields.TASK_TYPE]: BackofficeTaskType;
  [TaskApiFields.START_IX]: string;
  [TaskApiFields.END_IX]: string;
  [TaskApiFields.LOAD_ALL]: boolean;
  [TaskApiFields.PIPELINE_TYPE]: BackofficePipelineType;
  [TaskApiFields.ANNOTATION_TOOL]: BackofficeAnnotationToolType;
  [TaskApiFields.DRY_RUN]: boolean;
  [TaskApiFields.EPISODE_INDEX]: string;
  [TaskApiFields.TACTICAL_ANALYSIS_ID]: string;
  [TaskApiFields.GIT_COMMIT]: string;
}

export interface CreateTaskApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
  [TaskApiFields.TASK_TYPE]: TaskApiFieldsTypes[TaskApiFields.TASK_TYPE];
  [TaskApiFields.LOAD_ALL]: TaskApiFieldsTypes[TaskApiFields.LOAD_ALL];
  [TaskApiFields.START_IX]?: TaskApiFieldsTypes[TaskApiFields.START_IX];
  [TaskApiFields.END_IX]?: TaskApiFieldsTypes[TaskApiFields.END_IX];
  [TaskApiFields.PIPELINE_TYPE]?: TaskApiFieldsTypes[TaskApiFields.PIPELINE_TYPE];
  [TaskApiFields.ANNOTATION_TOOL]: TaskApiFieldsTypes[TaskApiFields.ANNOTATION_TOOL];
}

export interface DeleteTaskApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
  [TaskApiFields.TASK_TYPE]: TaskApiFieldsTypes[TaskApiFields.TASK_TYPE];
  [TaskApiFields.START_IX]?: TaskApiFieldsTypes[TaskApiFields.START_IX];
  [TaskApiFields.END_IX]?: TaskApiFieldsTypes[TaskApiFields.END_IX];
  [TaskApiFields.ANNOTATION_TOOL]: TaskApiFieldsTypes[TaskApiFields.ANNOTATION_TOOL];
}

export interface ValidateTaskApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
  [TaskApiFields.DRY_RUN]: boolean;
  [TaskApiFields.PIPELINE_TYPE]?: TaskApiFieldsTypes[TaskApiFields.PIPELINE_TYPE];
  [TaskApiFields.ANNOTATION_TOOL]: TaskApiFieldsTypes[TaskApiFields.ANNOTATION_TOOL];
}

export interface AnnotationsApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
  [TaskApiFields.TASK_TYPE]: TaskApiFieldsTypes[TaskApiFields.TASK_TYPE];
  [TaskApiFields.START_IX]?: TaskApiFieldsTypes[TaskApiFields.START_IX];
  [TaskApiFields.END_IX]?: TaskApiFieldsTypes[TaskApiFields.END_IX];
  [TaskApiFields.PIPELINE_TYPE]?: TaskApiFieldsTypes[TaskApiFields.PIPELINE_TYPE];
  [TaskApiFields.ANNOTATION_TOOL]: TaskApiFieldsTypes[TaskApiFields.ANNOTATION_TOOL];
}

export interface LabelsApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
  [TaskApiFields.TASK_TYPE]: TaskApiFieldsTypes[TaskApiFields.TASK_TYPE];
  [TaskApiFields.START_IX]?: TaskApiFieldsTypes[TaskApiFields.START_IX];
  [TaskApiFields.END_IX]?: TaskApiFieldsTypes[TaskApiFields.END_IX];
  [TaskApiFields.ANNOTATION_TOOL]: TaskApiFieldsTypes[TaskApiFields.ANNOTATION_TOOL];
}

export interface ProcessEpisodeApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
  [TaskApiFields.EPISODE_INDEX]?: TaskApiFieldsTypes[TaskApiFields.EPISODE_INDEX];
  [TaskApiFields.TACTICAL_ANALYSIS_ID]?: TaskApiFieldsTypes[TaskApiFields.TACTICAL_ANALYSIS_ID];
  [TaskApiFields.GIT_COMMIT]?: TaskApiFieldsTypes[TaskApiFields.GIT_COMMIT];
}

export interface DeleteEpisodeOverlaysApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
  [TaskApiFields.EPISODE_INDEX]: TaskApiFieldsTypes[TaskApiFields.EPISODE_INDEX];
}

export interface ReprocessSyncApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
}

export interface ReprocessThirdPartyEventsApiParams {
  [TaskApiFields.RECORDING_ID]: TaskApiFieldsTypes[TaskApiFields.RECORDING_ID];
}
