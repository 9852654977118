import find from 'lodash/find';
import reduce from 'lodash/reduce';

import { Episode } from 'shared/types/index';

import { generateBlockContainerClip } from './generateBlockContainerClip';
import { getClipsFromTacticalFundamental } from './getClipsFromTacticalFundamental';
import { sortByTitle } from './sortByTitle';
import { defensiveTactics, offensiveTactics } from '../../../../config';
import { Row, RowGroup, RowList } from '../types/row';

export const generateTacticsRows = (
  episodes: Episode[],
  recordingId: string,
): {
  tacticsRowGroup: RowGroup;
  tacticsIdsList: Set<string>;
} => {
  const tacticsIdsList: Set<string> = new Set();

  const rows: RowList = {};
  episodes.forEach((episode) => {
    const tacticsClips = getClipsFromTacticalFundamental({
      clips: episode.tacticalFundamentals,
      recordingId: recordingId,
    });

    tacticsClips.forEach((tactic) => {
      const { rowId } = tactic;
      if (!rows[rowId]) {
        rows[rowId] = generateBlockContainerClip({
          id: rowId,
          rowType: 'tactics',
          timelineTableBlocks: episodes,
          title: tactic.title,
          rowId: rowId,
          teamId: tactic.teamId,
          clipIdPrefix: rowId,
          entityId: tactic.elementId,
        });
      }

      const episodeClip = find(rows[rowId].clips, (row) => row.id === `${rowId}-${episode.id}`);

      tacticsIdsList.add(tactic.elementId);
      episodeClip?.clips?.push(tactic);
    });
  });

  const offensiveRows = reduce(
    rows,
    (acc, row) => {
      if (row.entityId && offensiveTactics.includes(row.entityId)) {
        acc.push(row);
      }
      return acc;
    },
    [] as Row[],
  );

  const offensiveRowGroup: RowGroup = {
    id: 'offensive-tactics',
    isOpen: true,
    isSelected: false,
    title: 'timeline:offensive',
    rows: offensiveRows.sort(sortByTitle),
    totalClips: reduce(offensiveRows, (acc, row) => acc + row.clips.length, 0),
    type: 'tactics',
  };

  const defensiveRows = reduce(
    rows,
    (acc, row) => {
      if (row.entityId && defensiveTactics.includes(row.entityId)) {
        acc.push(row);
      }
      return acc;
    },
    [] as Row[],
  );

  const defensiveRowGroup: RowGroup = {
    id: 'defensive-tactics',
    isOpen: true,
    isSelected: false,
    title: 'timeline:defensive',
    totalClips: reduce(defensiveRows, (acc, row) => acc + row.clips.length, 0),
    rows: defensiveRows.sort(sortByTitle),
    type: 'tactics',
  };

  const tacticsRowGroup: RowGroup = {
    id: 'tactics',
    isOpen: true,
    isSelected: false,
    title: 'timeline:tactics',
    rows: [],
    totalClips: offensiveRowGroup.totalClips + defensiveRowGroup.totalClips,
    rowGroups: [offensiveRowGroup, defensiveRowGroup],
    type: 'tactics',
  };

  return { tacticsRowGroup: tacticsRowGroup, tacticsIdsList };
};
