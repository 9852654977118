import { Box } from '@mui/material';
import React, { forwardRef, PropsWithChildren } from 'react';

import { CSS_TIMELINE_INNER_WIDTH } from '../timeline-css-variables';

export const RowsContentInner = forwardRef(
  ({ children, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>, ref: any) => (
    <Box
      ref={ref}
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        minWidth: `var(${CSS_TIMELINE_INNER_WIDTH})`,

        '&:after': {
          content: '""',
          position: 'absolute',
          top: 0,
          right: '-1px',
          width: '1px',
          height: '100%',
          background: ({ palette }) => palette.secondary.main,
        },
      }}
      style={{ width: `${CSS_TIMELINE_INNER_WIDTH}` }}
      {...rest}
    >
      {children}
    </Box>
  ),
);

RowsContentInner.displayName = 'RowsContentInner';
