import { Episode } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';
import { GroupedTags } from 'shared/types/tagging-events/types';

import { RowGroup } from './types/row';
import { Timeline, TimelineTableBlock } from './types/timeline';
import { generateEpisodesRows } from './utils/generateEpisodesRows';
import { generateEventsRows } from './utils/generateEventsRows';
import { generateFiltersRow } from './utils/generateFiltersRow';
import { generateFullMatchTimelineBlockFromDuration } from './utils/generateFullMatchTimelineBlockFromDuration';
import { generateManualTagsRows } from './utils/generateManualTagsRows';
import { generateScenariosRows } from './utils/generateScenariosRows';
import { generateTacticsRows } from './utils/generateTacticsRows';

export const DEFAULT_TIMELINE_DATA: Timeline = {
  episodesRow: {
    id: 'episodes',
    type: 'episodes',
    isHidden: false,
    title: 'episode',
    clips: [],
  },
  filtersRow: undefined,
  rowGroups: [],
  scenariosRowGroup: undefined,
  scenariosIdsList: new Set(),
  tacticsIdsList: new Set(),
};

const transformEpisodeToTimelineTableBlock = (episode: Episode): TimelineTableBlock => ({
  id: episode.id,
  name: episode.name,
  startTime: episode.startTime,
  endTime: episode.endTime,
  matchSegment: episode.matchSegment,
});

export const generateTimelineRows = (
  episodes: Episode[],
  groupedTags: GroupedTags,
  duration: number,
  appliedFilters: RecordingsFilters,
  filteredEpisodes: Episode[],
  recordingId: string,
): Timeline => {
  const timelineBlocks: TimelineTableBlock[] =
    episodes.length > 0
      ? episodes.map(transformEpisodeToTimelineTableBlock)
      : [generateFullMatchTimelineBlockFromDuration(duration)];

  const episodesRow = generateEpisodesRows(timelineBlocks, recordingId);
  const { scenariosRowGroup, scenariosIdsList } = generateScenariosRows(episodes, recordingId);
  const eventsRowGroup = generateEventsRows(episodes, recordingId);
  const { tacticsRowGroup, tacticsIdsList } = generateTacticsRows(episodes, recordingId);
  const manualTagsRowGroup = generateManualTagsRows(timelineBlocks, groupedTags, recordingId);
  const filtersRow = generateFiltersRow(episodes, filteredEpisodes, appliedFilters, recordingId);
  const rowGroups: RowGroup[] = [tacticsRowGroup, eventsRowGroup, manualTagsRowGroup];

  return {
    filtersRow,
    scenariosRowGroup,
    scenariosIdsList,
    tacticsIdsList,
    rowGroups,
    episodesRow,
  };
};
