import { IconButton, Modal } from '@mui/material';
import { useCallback } from 'react';

import { useTacticalAnalysesFilters } from 'api/recording/use-tactical-analyses-filters';
import { TIMELINE_DEFAULT_FILTERS } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import IconClose from 'shared/components/icons/icon-close';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { RecordingByName, RecordingsFilters } from 'shared/types/recording/types';
import { useSavePlaylistMultimatchFiltersAppliedPreset } from 'shared/types/user-preset/hooks/use-save-playlist-multimatch-filters-applied-preset';

import { MultiAnalysisFiltersPlaylist } from './multi-analysis-filters-playlist';
import styles from './MultimatchFiltersModal.module.scss';
import { usePlaylistMultimatchAppliedFilters, useSetPlaylistMultimatchAppliedFilters } from '../../../store/atoms';
import { RecordingsFiltersContent } from '../recordings-filters-content';
import { RecordingsFiltersHeader } from '../recordings-filters-header';
import { SelectRecordings } from '../select-recordings';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  playlistId: string;
}

export const MultimatchFiltersModal = ({ playlistId, isOpen, onClose }: Props) => {
  const appliedFilters = usePlaylistMultimatchAppliedFilters(playlistId);
  const setAppliedFilters = useSetPlaylistMultimatchAppliedFilters(playlistId);
  const { isLoading, data } = useTacticalAnalysesFilters(appliedFilters.recordingIds);
  const saveMultimatchAppliedFiltersPreset = useSavePlaylistMultimatchFiltersAppliedPreset();

  const handleUpdateMultimatchAppliedFilters = useCallback(
    (filters: RecordingsFilters) => {
      setAppliedFilters(filters);
      saveMultimatchAppliedFiltersPreset(playlistId, filters);
    },
    [setAppliedFilters, saveMultimatchAppliedFiltersPreset, playlistId],
  );

  const handleSetRecordings = useCallback(
    (currentRecordingsSelection: RecordingByName[], recordingsSelection: RecordingByName[]) => {
      const filters = currentRecordingsSelection.some((r) => !recordingsSelection.includes(r)) ? {} : appliedFilters;

      handleUpdateMultimatchAppliedFilters({ ...filters, recordingIds: recordingsSelection.map((r) => r.id) });
    },
    [appliedFilters, handleUpdateMultimatchAppliedFilters],
  );

  return (
    <Modal disableEnforceFocus open={isOpen} onClose={onClose} className={styles.container}>
      <div className={styles.content}>
        <div className={styles.closeButton}>
          <IconButton size={IconSizes.small} onClick={onClose}>
            <IconClose color={IconColors.night} size={IconSizes.small} />
          </IconButton>
        </div>
        <RecordingsFiltersHeader>
          <SelectRecordings recordingIds={appliedFilters.recordingIds} onChange={handleSetRecordings} />
        </RecordingsFiltersHeader>
        <RecordingsFiltersContent>
          <MultiAnalysisFiltersPlaylist
            filtersData={data ?? TIMELINE_DEFAULT_FILTERS}
            isLoadingRecordings={isLoading && appliedFilters.recordingIds.length > 0}
            key={appliedFilters.recordingIds.join(',')}
            onClose={onClose}
            playlistId={playlistId}
            appliedFilters={appliedFilters}
            onUpdate={handleUpdateMultimatchAppliedFilters}
          />
        </RecordingsFiltersContent>
      </div>
    </Modal>
  );
};
