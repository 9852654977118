import { Box } from '@mui/material';

import { useBranding } from 'shared/hooks/use-branding/useBranding';

import { LogoImg, LogoImgSmall } from './styled/LogoImg';

interface Props {
  isSmall: boolean;
}

export const SidebarLogo = ({ isSmall }: Props) => {
  const branding = useBranding();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      {isSmall ? (
        <LogoImgSmall src={branding.navbarCollapsedLogoUrl} alt={branding.displayName} />
      ) : (
        <LogoImg src={branding.navbarLogoUrl} alt={branding.displayName} />
      )}
    </Box>
  );
};
