import { Button, MenuProps, styled, Menu, MenuItem, Tooltip } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import IconChevronDown from 'shared/components/icons/icon-chevron-down';
import IconDone from 'shared/components/icons/icon-done';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { useClientId, useUser } from 'shared/contexts/app-state';

import { openSidebarWidth } from '../SideBarLayout.styled';

type Props = {
  isSidebarOpen: boolean;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(1),
    minWidth: `calc(${openSidebarWidth}px - ${theme.spacing(4)})`,
    border: `solid 1px ${Colors.ghost}`,
    boxShadow: '0 32px 40px -2px rgba(10, 22, 70, 0.12), 0 0 1px 0 rgba(10, 22, 70, 0.06)',
    '& .MuiMenu-list': {
      padding: theme.spacing(0.5, 0),
    },
    '& .MuiMenuItem-root': {
      fontSize: fontSizes.default,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
}));

export const ClientDropdown = ({ isSidebarOpen }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const user = useUser();
  const { clientId, setClientId } = useClientId();
  const open = Boolean(anchorEl);

  const getCurrentClient = () => user.clients.find((client) => client.id === clientId);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const currentClientName = getCurrentClient()?.name || '';
  const hideText = !isSidebarOpen;

  const handleClientChange = useCallback(
    (id: string) => {
      setClientId(id);
      history.push(routes.HOME_PAGE);
    },
    [history, setClientId],
  );

  const clientsItems = user.clients.map((client) => {
    const isCurrentClient = client.id === clientId;

    return (
      <MenuItem
        key={client.id}
        onClick={() => {
          handleClientChange(client.id);
          handleClose();
        }}
        disableRipple
        sx={{
          justifyContent: 'space-between',
          py: 1,
        }}
      >
        {client.name}
        {isCurrentClient ? <IconDone size={IconSizes.small} /> : null}
      </MenuItem>
    );
  });

  return (
    <Tooltip title={!isSidebarOpen && !open ? currentClientName : ''} placement={'right'}>
      <div>
        <Button
          id='clients-menu-button'
          data-testid='clients-menu-button'
          aria-controls={open ? 'clients-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          color={open ? 'primary' : 'inherit'}
          variant='outlined'
          disableElevation
          onClick={handleClick}
          endIcon={<IconChevronDown color={open ? IconColors.primary : IconColors.default} size={IconSizes.small} />}
          fullWidth
          sx={{
            justifyContent: hideText ? 'center' : 'space-between',
            paddingX: hideText ? 0 : 1,
            borderRadius: 1,
            // TODO use color from theme
            borderColor: Colors.iron,
            minWidth: 'auto',
            '& .MuiButton-endIcon': {
              marginLeft: 0,
              marginRight: 0,
            },
          }}
          size={'large'}
        >
          {hideText ? null : currentClientName}
        </Button>
        <StyledMenu
          id='clients-menu'
          MenuListProps={{
            'aria-labelledby': 'clients-menu-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {clientsItems}
        </StyledMenu>
      </div>
    </Tooltip>
  );
};
