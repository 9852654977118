import { atom, atomFamily } from 'recoil';

import { TacticalAnalysesFilters } from 'api/recording/useEpisodesWithFilters/types';
import { PlayingMode, VideoSource } from 'shared/components/video-player/types';
import { ZOOM_LEVELS } from 'shared/constants/zoom-range/zoomLevelsValues';
import { Episode, Match, TacticIdOrAll } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';
import { SegmentConfig } from 'shared/types/segment/types';
import { MatchTeam } from 'shared/types/teams/types';
import { ZoomLevelsType } from 'shared/types/zoom-range/zoomLevels';

import { DEFAULT_TIMELINE_DATA } from '../api/use-tactical-analysis-data/generate-timeline-rows/generateTimelineRows';
import { Timeline } from '../api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { TacticalAnalysisPlayingMode } from '../types/tactical-analysis-playing-mode';

const isTimelineReadyToRender = atomFamily<boolean, string>({
  key: 'timeline-is-ready-to-render',
  default: false,
});

const timelineMultiselectActive = atomFamily<boolean, string>({
  key: 'tactical-analysis-multiselect-active',
  default: false,
});

const showFilteredClips = atomFamily<boolean, string>({
  key: 'tactical-analysis-show-filtered-clips',
  default: false,
});

const shouldResetPlayHead = atomFamily<boolean, string>({
  key: 'tactical-analysis-should-reset-play-head',
  default: true,
});

const zoomLevel = atom<ZoomLevelsType>({
  key: 'tactical-analysis-zoom-level',
  default: ZOOM_LEVELS.large,
});

export const MINIMUM_TIMELINE_TRACKS_HEIGHT = 380;
const height = atom<number>({
  key: 'tactical-analysis-height',
  default: MINIMUM_TIMELINE_TRACKS_HEIGHT,
});

export const HEADER_MIN_WIDTH = 264;
const headersWidth = atom<number>({
  key: 'tactical-analysis-headers-width',
  default: HEADER_MIN_WIDTH,
});

const matchVideoSource = atomFamily<VideoSource, string>({
  key: 'tactical-analysis-match-video-source',
  default: { id: '', src: '' },
});

const teamIdFocus = atomFamily<string, string>({
  key: 'tactical-analysis-team-id-focus',
  default: '',
});

const teams = atomFamily<
  {
    homeTeam?: MatchTeam;
    opponentTeam?: MatchTeam;
  },
  string
>({
  key: 'tactical-analysis-teams',
  default: {
    homeTeam: undefined,
    opponentTeam: undefined,
  },
});

const match = atomFamily<Match, string>({
  key: 'tactical-analysis-match',
  default: undefined,
});

const episodes = atomFamily<Episode[], string>({
  key: 'tactical-analysis-episodes',
  default: [],
});

const selectedTactics = atomFamily<TacticIdOrAll[], string>({
  key: 'tactical-analysis-selected-tactics',
  default: ['all'],
});

const playingMode = atomFamily<PlayingMode, string>({
  key: 'tactical-analysis-playing-mode',
  default: undefined,
});

const timelineTableData = atomFamily<Timeline, string>({
  key: 'tactical-analysis-timeline-table-data',
  default: DEFAULT_TIMELINE_DATA,
});

const showBallPossession = atomFamily<boolean, string>({
  key: 'tactical-analysis-show-ball-possession',
  default: true,
});

const showNoBallPossession = atomFamily<boolean, string>({
  key: 'tactical-analysis-show-no-ball-possession',
  default: true,
});

const hasHomographies = atomFamily<boolean, string>({
  key: 'tactical-analysis-has-homographies',
  default: true,
});

const matchSegments = atomFamily<SegmentConfig[], string>({
  key: 'tactical-analysis-match-segments',
  default: [],
});

const filters = atomFamily<TacticalAnalysesFilters, string>({
  key: 'tactical-analysis-filters',
  default: undefined,
});

const appliedFilters = atomFamily<RecordingsFilters, string>({
  key: 'tactical-analysis-applied-filters',
  default: { recordingIds: [] },
});

const tacticalAnalysisId = atomFamily<string, string>({
  key: 'tactical-analysis-current-tactical-analysis-id',
  default: undefined,
});

const filteredEpisodes = atomFamily<Episode[], string>({
  key: 'tactical-analysis-filters-results',
  default: [],
});

const mode = atomFamily<TacticalAnalysisPlayingMode, string>({
  key: 'tactical-analysis-mode',
  default: TacticalAnalysisPlayingMode.default,
});

export const timelineMatchAtoms = {
  appliedFilters,
  episodes,
  filteredEpisodes,
  filters,
  hasHomographies,
  isTimelineReadyToRender,
  match,
  matchSegments,
  matchVideoSource,
  playingMode,
  selectedTactics,
  showBallPossession,
  showNoBallPossession,
  tacticalAnalysisId,
  mode,
  teamIdFocus,
  teams,
  timelineTableData,
};

export const timelineAtoms = {
  headersWidth,
  height,
  shouldResetPlayHead,
  showFilteredClips,
  timelineMultiselectActive,
  zoomLevel,
};
