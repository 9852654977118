import { Box, Button, Link, styled, Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

import IconCircleBackground from 'shared/assets/icon-circle-background.svg';

export const ErrorContainerWrapper = styled(Box)(() => ({
  minHeight: '100%',
  display: 'flex',
  width: '100%',
}));

export const ErrorContainerContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingBottom: '15%',
}));

export const ErrorContainerTitle = styled(Typography)(({ theme }) => ({
  fontSize: fontSizes.xxLarge,
  color: Colors.primary,
  fontWeight: fontWeight['700'],
  textAlign: 'center',
  marginBottom: theme.spacing(1),
}));

export const ErrorContainerText = styled(Typography)(() => ({
  fontSize: fontSizes.large,
  textAlign: 'center',
}));

export const ErrorContainerButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
  minWidth: '288px',
}));

export const ErrorContainerLink = styled(Link)(() => ({
  '&.MuiLink-root': {
    color: Colors.primary,
    textDecoration: 'none',
    '&::after': {
      display: 'none !important',
    },
    '&:hover': {
      color: Colors.linkHover,
    },
  },
}));

export const ErrorContainerImage = styled(Box)(({ theme }) => ({
  width: '236px',
  height: '236px',
  background: `url(${IconCircleBackground})`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(3.5),
}));
