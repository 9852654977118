import { AutocompleteChangeReason } from '@mui/base';
import { Grid } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { UpdateAutocompleteValue } from 'shared/components/autocomplete';
import { Playlist } from 'shared/types';

import { SearchPlaylistAutocompleteInput } from './index.styled';

interface Props {
  onUpdate: (name: string) => void;
}

export const SearchPlaylist = ({ onUpdate }: Props) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const handleOnFocus = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleOnUpdate = useCallback(
    (playlist: Playlist | null, name: string, reason: AutocompleteChangeReason) => {
      if (['clear', 'blur'].includes(reason)) {
        onUpdate && onUpdate(name);
      }
    },
    [onUpdate],
  );

  const handleBlur = useCallback(
    (name?: string) => {
      onUpdate(name ? name : '');
      setIsOpen(false);
    },
    [onUpdate],
  );

  const handleOnKeyUp = useCallback(() => {
    setIsOpen(true);
  }, []);

  const handleSearchTermChange = useCallback(
    (name: string) => {
      onUpdate && onUpdate(name && name.length >= 3 ? name : '');
    },
    [onUpdate],
  );

  const handleOnClick: UpdateAutocompleteValue<Playlist> = useCallback(
    (value) => {
      history.push(generatePath(routes.RECORDING_PLAYLIST_DETAIL, { playlistId: value.id }));
    },
    [history],
  );

  return (
    <Grid item alignSelf={'flex-end'}>
      <SearchPlaylistAutocompleteInput
        playlistId={routes.PLAYLISTS}
        onBlur={handleBlur}
        onClickElement={handleOnClick}
        onFocus={handleOnFocus}
        onKeyDown={handleOnKeyUp}
        onSearchTermChange={handleSearchTermChange}
        onSubmit={handleBlur}
        onUpdateValue={handleOnUpdate}
        open={isOpen}
        showResultsInPopOver
        title={'Playlists'}
      />
    </Grid>
  );
};
