import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { useUser } from 'shared/contexts/app-state';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

import Solutions from './components/solutions/Solutions';
import styles from './HomeContainer.module.scss';

export const HomeContainer = () => {
  const user = useUser();
  const { t } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.home', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <SidebarLayout>
      <Container>
        <div className={styles.homePage}>
          <h1 data-testid='page-title'>{t('home:welcome', { firstName: user.firstName })}</h1>
          <p>{t('home:what-would-you-like-to-do')}</p>
          <Solutions />
        </div>
      </Container>
    </SidebarLayout>
  );
};

export default HomeContainer;
