import { Button, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { fontSizes } from 'kognia-ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import IconExport from 'shared/components/icons/icon-export';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';
import { VideoSource } from 'shared/types/recording/types';
import { getVODTacticalCameraVideo } from 'shared/utils/get-video-sources';

import ExportVideoModal from './export-video-modal';
import ExportXMLModal from './export-xml-modal';

type ExportButtonProps = {
  recordingId: string;
  tacticalAnalysisId?: string;
  videoSources: VideoSource[];
};

export enum ExportModals {
  XML,
  VIDEO,
}

const ExportButton = ({ recordingId, tacticalAnalysisId, videoSources }: ExportButtonProps) => {
  const { t } = useTranslation();
  const fullMatchTacticalCamera = getVODTacticalCameraVideo(videoSources);
  const effectiveTimeTacticalCamera = getVODTacticalCameraVideo(videoSources, true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [currentModal, setCurrentModal] = useState<ExportModals | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    setCurrentModal(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenModal = (modal: ExportModals) => {
    handleCloseMenu();
    if (modal === ExportModals.XML)
      publishEvent({
        type: ActionTypes.OPEN_TACTICAL_ANALYSIS_EXPORT_XML,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_XML },
      });
    if (modal === ExportModals.VIDEO)
      publishEvent({
        type: ActionTypes.OPEN_TACTICAL_ANALYSIS_EXPORT_VIDEO,
        payload: { name: MetricsNames.TACTICAL_ANALYSIS_OPEN_EXPORT_VIDEO },
      });
    setCurrentModal(modal);
  };

  const fullMatchDownload = fullMatchTacticalCamera?.srcDownload;
  const effectiveTimeDownload = effectiveTimeTacticalCamera?.srcDownload;

  return (
    <>
      <Button
        color='inherit'
        id='export-button'
        aria-controls='export-menu'
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        startIcon={<IconExport size={IconSizes.small} color={IconColors.night} />}
        sx={{ fontWeight: 'fontWeightRegular' }}
      >
        {t('timeline:actions.export.title')}
      </Button>
      <Menu
        id='export-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{ 'aria-labelledby': 'export-button' }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={() => handleOpenModal(ExportModals.XML)}>
          <Typography fontSize={fontSizes.default}>{t('timeline:actions.export.xml.export-xml')}</Typography>
        </MenuItem>
        <MenuItem onClick={() => handleOpenModal(ExportModals.VIDEO)}>
          <Typography fontSize={fontSizes.default}>{t('timeline:actions.export.video.export-video')}</Typography>
        </MenuItem>
      </Menu>
      {currentModal === ExportModals.XML && (
        <ExportXMLModal
          recordingId={recordingId}
          tacticalAnalysisId={tacticalAnalysisId}
          onClose={handleCloseModal}
          enableFullMatch={!!fullMatchDownload}
          enableEffectiveTime={!!effectiveTimeDownload}
        />
      )}
      {currentModal === ExportModals.VIDEO && (
        <ExportVideoModal
          onClose={handleCloseModal}
          fullMatchDownloadUrl={fullMatchDownload}
          effectiveTimeDownloadUrl={effectiveTimeDownload}
        />
      )}
    </>
  );
};

export default ExportButton;
