import { RowsContentGroup } from '../row-content-group';
import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';

type Props = {
  rowGroup?: RowGroup;
  recordingId: string;
};
export const ScenariosRowGroupContent = ({ rowGroup, recordingId }: Props) => {
  if (!rowGroup) return null;

  return <RowsContentGroup key={rowGroup.type} level={0} rowGroup={rowGroup} recordingId={recordingId} />;
};
