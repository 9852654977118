import { useCallback } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { useVideoPlayerActions, useVideoPlayerPlayingMode, useVideoPlayerState } from 'shared/components/video-player';
import { PlayingMode } from 'shared/components/video-player/types';
import { TimelinePlayingModePreset } from 'shared/constants/user-presets/userPresetsClasses';

import { useGenerateTimelinePlaylist } from '../use-generate-timeline-playlist';
import { useSetPresetPlayingMode } from '../use-preset-playing-mode';

export const useTimelinePlayingMode = (recordingId: string) => {
  const actions = useVideoPlayerActions();
  const videoPlayerState = useVideoPlayerState();
  const setPresetPlayingMode = useSetPresetPlayingMode(recordingId);
  const videoPlayerPlayingMode = useVideoPlayerPlayingMode();
  const generateTimelinePlaylist = useGenerateTimelinePlaylist(recordingId);

  const { savePreset } = useSaveUserPreset();

  const handleSetPlayingMode = useCallback(
    (playingMode: PlayingMode) => {
      if (!videoPlayerState.readyState) return;

      const playingModePreset = new TimelinePlayingModePreset(playingMode, recordingId);
      savePreset({ data: playingModePreset });

      const playlistItems = generateTimelinePlaylist(playingMode);

      setPresetPlayingMode(playingMode);
      actions.setPlaylist(playlistItems, playingMode, true, true);
    },
    [setPresetPlayingMode, generateTimelinePlaylist, videoPlayerState.readyState, actions, recordingId, savePreset],
  );

  return {
    playingMode: videoPlayerPlayingMode,
    setPlayingMode: handleSetPlayingMode,
  };
};
