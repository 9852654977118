import { generateEpisodeClips } from './generateEpisodeClips';
import { Row } from '../types/row';
import { TimelineTableBlock } from '../types/timeline';

export const generateEpisodesRows = (timelineTableBlocks: TimelineTableBlock[], recordingId: string): Row => {
  return {
    id: `${recordingId}-episodes`,
    type: 'episodes',
    title: 'episode',
    isHidden: false,
    clips: generateEpisodeClips({ timelineTableBlocks, clipType: 'episode', rowId: 'episodes' }),
  };
};
