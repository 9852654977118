import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { clientBaseUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Client } from 'shared/types';

import { transformClientWithLinks } from '../../transformer';
import { ClientApiResponse } from '../../types';
import { ClientRequestData } from '../../types/clientRequestData';
import { invalidateClients } from '../use-clients/useClients';

export const useCreateClient = () => {
  const { mutate, isLoading, isError, isSuccess } = useMutationRequest<ClientApiResponse, Client>({
    type: HTTPMethod.POST,
    errorMessage: 'Error creating a new client',
    successMessage: 'New client created',
    transformer: transformClientWithLinks,
  });

  const createClient = ({ data, onSuccess }: { data: ClientRequestData; onSuccess?: (res: Client) => void }) => {
    mutate(
      { url: clientBaseUrl, data },
      {
        onSuccess: async (res) => {
          await invalidateClients();
          onSuccess && onSuccess(res);
        },
      },
    );
  };

  return { createClient, isLoading, isError, isSuccess };
};
