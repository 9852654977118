import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useMetrics } from 'kognia/metrics/hooks/use-metrics';
import IconMinus from 'shared/components/icons/icon-minus';
import IconPlus from 'shared/components/icons/icon-plus';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import RangeSlider from 'shared/components/range-slider';
import { ZOOM_LEVELS, ZOOM_LEVELS_VALUES } from 'shared/constants/zoom-range/zoomLevelsValues';
import { MetricsData, MetricsNames } from 'shared/types/metrics';
import { ZoomLevelsType } from 'shared/types/zoom-range/zoomLevels';
import { getIsFormTag } from 'shared/utils/is-form-tag';

import { Separator, ZoomContainer } from './ZoomRage.styled';
import { useTimelineZoomLevel } from '../../../hooks/use-timeline-zoom-level';

const isZoomLevel = (value: number): value is ZoomLevelsType => {
  return ZOOM_LEVELS_VALUES.includes(value as ZoomLevelsType);
};

export const isNextStepEnabled = (value: number): value is ZoomLevelsType => {
  return isZoomLevel(value) && value <= ZOOM_LEVELS.extraSmall;
};

export const isPreviousStepEnabled = (value: number): value is ZoomLevelsType => {
  return isZoomLevel(value) && value >= ZOOM_LEVELS.extraLarge;
};

export const STEP_ZOOM = 1;
const MIN_ZOOM = ZOOM_LEVELS_VALUES[ZOOM_LEVELS.extraLarge];
const MAX_ZOOM = ZOOM_LEVELS_VALUES[ZOOM_LEVELS.extraSmall];

const SeparatorsList = () => {
  return (
    <>
      {Array(MAX_ZOOM - STEP_ZOOM)
        .fill('')
        .map((_, idx) => (
          <Separator key={idx} style={{ left: `${(idx + 1) * (100 / ZOOM_LEVELS_VALUES.length + 2)}%` }} />
        ))}
    </>
  );
};

const ZoomRange = (): JSX.Element => {
  const { t } = useTranslation();
  const { zoomLevel, setZoomLevel } = useTimelineZoomLevel();
  const { pushEvent } = useMetrics();

  const handleOnChange = useCallback(
    (value: ZoomLevelsType) => {
      pushEvent<MetricsData[MetricsNames.TACTICAL_ANALYSIS_CHANGE_ZOOM]>(MetricsNames.TACTICAL_ANALYSIS_CHANGE_ZOOM, {
        zoom_level: value.toString(),
      });
      setZoomLevel(value);
    },
    [pushEvent, setZoomLevel],
  );

  const getPreviousStep = useCallback(() => {
    const newValue = zoomLevel - STEP_ZOOM;
    if (isPreviousStepEnabled(newValue)) {
      handleOnChange(newValue);
    }
  }, [zoomLevel, handleOnChange]);

  const getNextStep = useCallback(() => {
    const newValue = zoomLevel + STEP_ZOOM;
    if (isNextStepEnabled(newValue)) {
      handleOnChange(newValue);
    }
  }, [zoomLevel, handleOnChange]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isFormTag = getIsFormTag((event.target as HTMLElement).tagName);
      if (isFormTag || event.ctrlKey || event.metaKey) return;
      if (event.key === '+') {
        getNextStep();
      } else if (event.key === '-') {
        getPreviousStep();
      }
    },
    [getNextStep, getPreviousStep],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <ZoomContainer>
      <label>{t('timeline:zoom-range.title')}</label>
      <div role='button' onClick={getPreviousStep}>
        <IconMinus
          color={IconColors.default}
          disabled={!isPreviousStepEnabled(zoomLevel)}
          isButton
          size={IconSizes.small}
        />
      </div>
      <Box sx={{ position: 'relative', alignItems: 'center', display: 'flex', width: '142px' }}>
        <SeparatorsList />
        <RangeSlider max={MAX_ZOOM} min={MIN_ZOOM} onChange={handleOnChange} step={STEP_ZOOM} value={zoomLevel} />
      </Box>
      <div role='button' onClick={getNextStep}>
        <IconPlus color={IconColors.default} disabled={!isNextStepEnabled(zoomLevel)} isButton size={IconSizes.small} />
      </div>
    </ZoomContainer>
  );
};

export default ZoomRange;
