import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

import { MetadataPlayerApiResponse, PlayerApiResponse } from './types';
import { MetadataPlayerSummary } from '../../../types/players';

export const transformPlayer = (player: MetadataPlayerApiResponse): MetadataPlayerSummary => {
  return {
    id: player.uuid,
    name: player.name,
    photoUrl: player.photo_url,
    fixtures: player.fixtures.map((fixture) => ({
      jerseyNumber: fixture.jersey_number,
      side: fixture.side,
      name: fixture.name,
      date: fixture.date,
      position: fixture.position,
      id: fixture.uuid,
      homeTeamScore: fixture.home_team_score,
      awayTeamScore: fixture.away_team_score,
      team: {
        name: fixture.team.name,
        abbreviation: fixture.team.abbreviation,
        logoUrl: fixture.team.logo_url,
        teamType: fixture.team.team_type,
        country: fixture.team.country_code,
        id: fixture.team.uuid,
      },
    })),
  };
};

export const transformPlayers = (players: MetadataPlayerApiResponse[]): MetadataPlayerSummary[] => {
  return players.map(transformPlayer);
};

export const transformPlayersResponse = (
  response: PlayerApiResponse,
): InfinityQueryDataResult<MetadataPlayerSummary> => {
  return {
    data: {
      items: transformPlayers(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
