import React from 'react';

import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { ClipBlockContainer } from '../clip-block-container';
import { ClipEpisode } from '../clip-episode';
import { ClipNotEffectiveTime } from '../clip-not-effective-time';

type Props = {
  clip: Clip;
  recordingId: string;
};

export const ClipTimeline = React.memo(({ clip, recordingId }: Props) => {
  if (clip.type === 'episode') {
    return <ClipEpisode clip={clip} recordingId={recordingId} />;
  }

  if (clip.type === 'block-container') {
    return <ClipBlockContainer recordingId={recordingId} clip={clip} />;
  }

  if (clip.type === 'not-effective-time') {
    return <ClipNotEffectiveTime clip={clip} recordingId={recordingId} />;
  }

  return null;
});

ClipTimeline.displayName = 'ClipTimeline';
