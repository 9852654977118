import { Grid, Skeleton } from '@mui/material';

import {
  playlistHeaderHeight,
  playlistTimelineHeight,
  playlistVideoPlayerHeight,
} from 'domain/playlist/config/Playlist.config';

const PlaylistDetailSkeleton = () => {
  return (
    <Grid container height='100%' paddingX={2}>
      <Grid item xs={12} paddingTop={2} height={playlistHeaderHeight}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
      <Grid item xs={12} paddingY={2} height={playlistVideoPlayerHeight}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
      <Grid item xs={12} paddingBottom={2} height={playlistTimelineHeight}>
        <Skeleton variant='rectangular' width='100%' height='100%' />
      </Grid>
    </Grid>
  );
};

export default PlaylistDetailSkeleton;
