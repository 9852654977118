import { useRecoilValue, useSetRecoilState } from 'recoil';

import { bulkSelectedItems } from '../state/EditablePlaylist.state';

export const useBulkSelectedItems = (id: string) => {
  return useRecoilValue(bulkSelectedItems(id));
};

export const useSetBulkSelectedItems = (id: string) => {
  return useSetRecoilState(bulkSelectedItems(id));
};
