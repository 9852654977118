import { MatchSegmentTypes } from 'shared/types/segment/types';

import { TimelineTableBlock } from '../types/timeline';

export const generateFullMatchTimelineBlockFromDuration = (duration: number): TimelineTableBlock => ({
  id: 'full-match',
  name: 'full-match-block',
  startTime: 0,
  endTime: duration,
  matchSegment: MatchSegmentTypes.FIRST,
});
